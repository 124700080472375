'use client';

import { FC } from 'react';

import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { Notifications } from './notifications';
import Iconify from '../../../../components/iconify';
import { useAuthContext } from '../../../../auth/hooks';
import { useBoolean } from '../../../../hooks/use-boolean';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useRouter } from '../../../../routes/hooks';

export const WelcomeBack: FC<ComponentTypeProps> = ({ block }) => {
  const { user } = useAuthContext();
  const notificationModal = useBoolean();
  const router = useRouter();
  const redirectLink: string = block?.redirectLink as string || '';
  return (
    <Stack
      p={1}
      direction="row"
      justifyContent="space-between"
      sx={{ bgcolor: '#7551DC', mt: '-16px' }}
      alignItems="center"

    >
      <Stack>
        <Stack direction="row" gap={1}>
          <Avatar
            sx={{ width: '50px', height: '50px' }}
            src="/assets/images/specialists/specialists2.jpg"
            onClick={()=> router.push(`/${redirectLink}`)}
          />
          <Stack>
            <Typography sx={{ color: 'white', fontSize: '12px' }}>
              Welcome back
            </Typography>
            <Typography sx={{ color: 'white', fontSize: '20px' }}>
              {user?.firstName || 'Cameron S.'}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Iconify
        icon="mingcute:notification-fill"
        sx={{ color: 'white', width: '30px', height: '30px' }}
        onClick={notificationModal.onTrue}
      />
      <Modal open={notificationModal.value} onClose={notificationModal.onFalse}>
        <Notifications close={notificationModal.onFalse} />
      </Modal>
    </Stack>
  );
};
