import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { Box, Button, Typography, TextField, Avatar } from '@mui/material';
import { useResponsive } from '../../../../../hooks/use-responsive';
import Iconify from '../../../../../components/iconify';
import Image from '../../../../../components/image';

type Props = {
  close: () => void;
};

const TransferSuccess = ({ close }: Props) => {
  const isMobile = useResponsive('down', 'sm');

  const [selectedCard, setSelectedCard] = useState('7895');
  const [amount, setAmount] = useState('');
  const [comment, setComment] = useState('');

  const cards = [
    { id: '7895', type: 'Visa', balance: 4863.27 },
    { id: '5378', type: 'MasterCard', balance: 2435.12 },
  ];

  const handleSave = () => {
    close();
  };

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      <Stack alignItems="center">
        <Image src="/assets/crypto/done.svg" />
      </Stack>
      <Stack gap={2}>
        <Typography sx={{ fontSize: '20px', fontWeight: 1000 }}>
          Your payment has been processed!
        </Typography>
        <Typography>364.00 USD</Typography>
        <Typography sx={{ color: 'grey' }}>
          Labore sunt culpa excepteur culpa ipsum. Labore occaecat ex nisi mollit.
        </Typography>
      </Stack>
      <Stack direction="row" gap={1}>
        <Button
          fullWidth
          sx={{ mt: 2, bgcolor: '#ffd9c3', color: 'white', borderRadius: '10px' }}
          onClick={handleSave}
        >
          Send Receipt
        </Button>
        <Button
          fullWidth
          sx={{ mt: 2, bgcolor: 'black', color: 'white', borderRadius: '10px' }}
          onClick={handleSave}
        >
          Done
        </Button>
      </Stack>
    </Box>
  );
};

export default TransferSuccess;
