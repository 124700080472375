import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Box, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';

// eslint-disable-next-line import/no-cycle
import { Loan } from './view-more';
import Iconify from '../../../../components/iconify';
import { useResponsive } from '../../../../hooks/use-responsive';

type Props = {
  close: () => void;
  loan: Loan | undefined;
};

export const LoanInfo = ({ close, loan }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const [selectedValue, setSelectedValue] = useState('submit');
  const steps = [
    {
      id: 'fieldset',
      icon: 'solar:dollar-outline',
      title: 'Submit loan order',
      subtitle: 'Your loan order has been submitted, please wait..',
    },
    {
      id: 'approved',
      icon: 'ant-design:file-done-outlined',
      title: 'Loan approved',
      subtitle: 'Your loan order has been approved and the loan will be distributed to you',
    },
    {
      id: 'transfering',
      icon: 'bx:transfer',
      title: 'Transfering',
      subtitle: 'Your funds are being transfered to your bank account, please check after sometime',
    },
  ];

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid',
            borderColor: '#7551DC',
          }}
        >
          <Iconify
            icon="solar:arrow-left-outline"
            sx={{ color: '#7551DC', cursor: 'pointer' }}
            onClick={close}
          />
        </Box>
        <Typography>Card info</Typography>
      </Stack>
      <Grid container mt={2}>
        <Grid item xs={8} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
          <Stack direction="row" gap={1} alignItems="center">
            <Box
              sx={{
                width: '50px',
                height: '50px',
                background: '#E8DDFD',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Iconify
                icon={loan?.icon || ''}
                sx={{
                  width: '40px',
                  height: '40px',
                  color: '#7551DC',
                }}
              />
            </Box>
            <Stack>
              <Typography sx={{ fontSize: '14px', fontWeight: 1000 }}>{loan?.name}</Typography>
              <Typography sx={{ fontSize: '12px', color: 'grey' }}>Active</Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Divider sx={{ width: '100%', my: 1 }} />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography sx={{ color: 'grey' }}>Reference number</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: 'end', fontWeight: 'bold' }}>AE1223LI</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: 'grey' }}>Payment Date</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: 'end', fontWeight: 'bold' }}>{loan?.date}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: 'grey' }}>Period</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: 'end', fontWeight: 'bold' }}>{loan?.period}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: 'grey' }}>Payment Amount</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: 'end', fontWeight: 'bold' }}>${loan?.sum}</Typography>
        </Grid>
      </Grid>
      <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mt: 3 }}>Activity</Typography>
      <FormControl component="fieldset" sx={{ p: 2 }}>
        <RadioGroup
          value={selectedValue}
          onChange={handleChangeRadio}
          name="loan-progress"
          sx={{ flexDirection: 'column' }}
        >
          <Stack gap={2}>
            {steps.map((step, idx) => (
              <Box key={idx}>
                <FormControlLabel
                  value={step.id}
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#7551DC',
                        },
                      }}
                    />
                  }
                  label={
                    <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
                      <Grid container spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={2} md={2}>
                          <Box
                            sx={{
                              width: '40px',
                              height: '40px',
                              borderRadius: '10px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: '1px solid',
                              borderColor: 'lightgrey',
                              bgcolor: 'background.paper',
                            }}
                          >
                            <Iconify icon={step.icon} sx={{ color: '#7551DC' }} />
                          </Box>
                        </Grid>
                        <Grid item xs={10} md={10}>
                          <Stack>
                            <Typography sx={{ fontWeight: 1000 }}>{step.title}</Typography>
                            <Typography sx={{ fontSize: '12px', color: 'grey' }}>
                              {step.subtitle}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  }
                  sx={{ margin: 0 }}
                />
                {idx + 1 !== steps.length && <Divider sx={{ width: '100%', color: '#7551DC' }} />}
              </Box>
            ))}

            {/* Кнопка возврата */}
            <Button
              sx={{
                bgcolor: '#7551DC',
                color: 'white',
                borderRadius: '10px',
                '&:hover': {
                  bgcolor: '#7551DC',
                },
              }}
              onClick={close}
            >
              Payment
            </Button>
          </Stack>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
