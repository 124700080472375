import { useState } from 'react';

import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  Box,
  Radio,
  MenuItem,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
} from '@mui/material';

import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';
import Image from '../../../../components/image';

type Props = {
  close: () => void;
};

export const AddCard = ({ close }: Props) => {
  const recentCards = useBoolean();
  const addCard = useBoolean();
  const isMobile = useResponsive('down', 'sm');
  const addresses = [''];
  const [activePeriod, setActivePeriod] = useState();
  const [value, setValue] = useState<string>('');
  const marks = [
    {
      value: 345,
      label: '$ 345',
    },
    {
      value: 10000,
      label: '$ 10.000',
    },
  ];
  const [selectedValue, setSelectedValue] = useState('mastercard');

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleChangePeriod = (event: React.ChangeEvent<{ value: unknown }>) => {
    // @ts-ignore
    setActivePeriod(event.target.value as string);
  };

  const formatNumber = (v: string) => {
    // Удаляем все символы, кроме чисел
    const numericValue = v.replace(/\D/g, '');
    // Форматируем число, добавляя разделители тысяч
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setValue(formattedValue);
  };

  const formatValue = (v: number) =>
    // Форматируем значение с разделителем тысяч
    v.toLocaleString();

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid',
            borderColor: '#7551DC',
          }}
        >
          <Iconify icon="solar:arrow-left-outline" sx={{ color: '#7551DC',cursor: 'pointer' }} onClick={close} />
        </Box>
        <Typography>Card Attach</Typography>
      </Stack>
      <Stack
        gap={3}
        p={2}
        mt={2}
        sx={
          isMobile
            ? {
                maxHeight: 'calc(100vh - 70px)', // Ограничение высоты (регулируйте по своему дизайну)
                overflowY: 'auto', // Включаем вертикальную прокрутку
              }
            : {}
        }
      >
        <Stack>
          <Typography sx={{ fontSize: '12px', color: 'grey', textAlign: 'justify' }}>
            Add the following documents so that we can easily verify yourself to avoid abuse
          </Typography>
        </Stack>
        <Stack>
          <Typography>Credit Card</Typography>
          <TextField
            select
            fullWidth
            value={activePeriod}
            onChange={handleChangePeriod}
            sx={{ border: '1px solid black', borderRadius: '10px' }}
          >
            {addresses.map((address, id) => (
              <MenuItem
                key={id}
                value={address}
                sx={{
                  mx: 1,
                  borderRadius: 0.75,
                }}
              >
                {address}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack>
          <Typography >Name Cardholder</Typography>
          <TextField fullWidth placeholder='Enter Name' sx={{ border: '1px solid black', borderRadius: '10px' }} />
        </Stack>
        <Stack>
          <Typography>Card Number</Typography>
          <TextField fullWidth placeholder='Enter Name' sx={{ border: '1px solid black', borderRadius: '10px' }} />
        </Stack>
        <Stack direction='row' gap={1} width={1}>
          <Stack width={1}>
            <Typography>Expiration Date</Typography>
            <TextField fullWidth placeholder='mm/yy' sx={{ border: '1px solid black', borderRadius: '10px' }} />
          </Stack>
          <Stack width={1}>
            <Typography>CVT</Typography>
            <TextField fullWidth placeholder='CVT number' sx={{ border: '1px solid black', borderRadius: '10px' }} />
          </Stack>
        </Stack>
        <Button
          sx={{
            bgcolor: '#7551DC',
            color: 'white',
            borderRadius: '10px',
            '&:hover': {
              bgcolor: '#7551DC',
              color: 'white',
              borderRadius: '10px',
            },
          }}
          onClick={close}
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
};
