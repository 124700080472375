
import { useState } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Email, LocationOn, AttachMoney, AccountCircle } from '@mui/icons-material';
import { Box, Button, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';

import Iconify from '../../../../../components/iconify';
import { useResponsive } from '../../../../../hooks/use-responsive';

type Props = {
  close: () => void;
  card:
    | {
        id: number;
        title: string;
        description: string;
        amount: string;
        percent: string;
        payOfDate: string;
        loanActive: string;
        cardType: string; // Тип карты
        merchant: string; // Продавец
        activity: string; // Активность
        images: { file: File | string; preview: string }[];
      }
    | any;
};

export const ReceivePayment = ({ close, card }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const [currency, setCurrency] = useState('USD');
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: !isMobile ? 400 : '100%',
        bgcolor: 'background.paper',
        border: '2px solid gray.500',
        borderRadius: !isMobile ? '10px' : '0px',
        boxShadow: 24,
        p: 4,
        pt: 1,
        height: !isMobile ? 'auto' : '100%',
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // border: '1px solid',
            // borderColor: 'grey',
          }}
        >
          <Iconify icon="fluent:ios-arrow-24-regular" sx={{ cursor: 'pointer' }} onClick={close} />

        </Box>
        <Typography>Create invoice</Typography>
      </Stack>
      <Box sx={{
        maxWidth: 500,
        mx: 'auto',
        // p: 3,
        // bgcolor: '#fef6f2',
        borderRadius: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Company name"
          sx={{ mb: 2 }}
          InputProps={{ startAdornment: <AccountCircle sx={{ color: '#ff926b', mr: 1 }} /> }}
        />
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Country"
          sx={{ mb: 2 }}
          InputProps={{ startAdornment: <LocationOn sx={{ color: '#ff926b', mr: 1 }} /> }}
        />
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Company email"
          sx={{ mb: 2 }}
          InputProps={{ startAdornment: <Email sx={{ color: '#ff926b', mr: 1 }} /> }}
        />
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Amount"
          sx={{ mb: 2 }}
          InputProps={{ startAdornment: <AttachMoney sx={{ color: '#ff926b', mr: 1 }} /> }}
        />
        <Typography sx={{ mb: 1 }}>Choose currency</Typography>
        <ToggleButtonGroup
          value={currency}
          exclusive
          onChange={(e, newCurrency) => newCurrency && setCurrency(newCurrency)}
          sx={{ mb: 2 }}
        >
          <ToggleButton value="USD" sx={{ flex: 1, bgcolor: currency === 'USD' ? '#08001e' : 'white', color: currency === 'USD' ? 'white' : 'black' }}>USD</ToggleButton>
          <ToggleButton value="EUR" sx={{ flex: 1, bgcolor: currency === 'EUR' ? '#08001e' : 'white', color: currency === 'EUR' ? 'white' : 'black' }}>EUR</ToggleButton>
        </ToggleButtonGroup>
        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder="Goods or services you provided"
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <Typography sx={{ fontSize: '12px', color: 'grey', mb: 2 }}>Bank fee is charged from the payer.</Typography>
        <Button fullWidth sx={{ bgcolor: '#08001e', color: 'white', p: 1.5, borderRadius: '10px' }}>Send Invoice</Button>
      </Box>
    </Box>
  );
};
