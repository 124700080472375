const history = [
  {
    id: 'operation-1',
    type: 'Deposit',
    amount: 0.1832,
    currency: 'eth',
    currencyLabel: 'Ethereum (ETH)',
    date: new Date(2022, 5, 15),
  },
  {
    id: 'operation-2',
    type: 'Withdraw',
    amount: 0.0003,
    currency: 'btc',
    currencyLabel: 'Bitcoin (BTC)',
    date: new Date(2022, 5, 15),
  },
  {
    id: 'operation-3',
    type: 'Deposit',
    amount: 0.9999,
    currency: 'busd',
    currencyLabel: 'Binance USD (BUSD)',
    date: new Date(2022, 5, 12),
  },
  {
    id: 'operation-4',
    type: 'Deposit',
    amount: 0.3911,
    currency: 'btc',
    currencyLabel: 'Bitcoin (BTC)',
    date: new Date(2022, 5, 12),
  },
  {
    id: 'operation-5',
    type: 'Withdraw',
    amount: 0.0504,
    currency: 'eth',
    currencyLabel: 'Ethereum (ETH)',
    date: new Date(2022, 5, 12),
  },
  {
    id: 'operation-6',
    type: 'Deposit',
    amount: 0.0001,
    currency: 'eth',
    currencyLabel: 'Ethereum (ETH)',
    date: new Date(2023, 1, 14),
  },
  {
    id: 'operation-7',
    type: 'Withdraw',
    amount: 0.0027,
    currency: 'eth',
    currencyLabel: 'Ethereum (ETH)',
    date: new Date(2023, 1, 14),
  },
  {
    id: 'operation-8',
    type: 'Deposit',
    amount: 0.0051,
    currency: 'btc',
    currencyLabel: 'Bitcoin (BTC)',
    date: new Date(2023, 1, 7),
  },
  {
    id: 'operation-9',
    type: 'Withdraw',
    amount: 0.0001,
    currency: 'btc',
    currencyLabel: 'Bitcoin (BTC)',
    date: new Date(2023, 1, 7),
  },
  {
    id: 'operation-10',
    type: 'Deposit',
    amount: 0.0302,
    currency: 'eth',
    currencyLabel: 'Ethereum (ETH)',
    date: new Date(2023, 1, 7),
  },
];

export default history;
