'use client';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useState, useEffect, useCallback } from 'react';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import { Box, Stack, Modal, Button, TextField, Typography, IconButton } from '@mui/material';

import ViewCard from './view-card';
import { useLocales } from '../../../../locales';
import { uploadFile } from '../../../../api/upload-file';
import { UploadBox } from '../../../../components/upload';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';
import ViewCardWallet2 from './view-card-wallet2';
import { BlockType } from '../../../../types/page-generator';

type Card = {
  id: number;
  title: string;
  description: string;
  amount: string;
  percent: string;
  payOfDate: string;
  loanActive: string;
  cardType: string; // Тип карты
  merchant: string; // Продавец
  activity: string; // Активность
  images: { file: File | string; preview: string }[];
};

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '30px',
};

type UploadFile = File & { preview: any };

const getFileWithPreview = (file: File): UploadFile =>
  Object.assign(file, { preview: URL.createObjectURL(file), path: file.name });

type Props = {
  block: BlockType | undefined;
};

const CardSliderWallet2 = ({ block }: Props) => {
  const { currentLang } = useLocales();
  const isMobile = useResponsive('down', 'sm');
  const addNewCard = useBoolean();
  const editCardModal = useBoolean();
  const LOCAL_STORAGE_KEY = 'cards';

  const [cards, setCards] = useState<Card[]>([
    {
      id: 1,
      title: 'Card 1',
      description: 'Description for Card 1',
      amount: '123,18',
      percent: '60%',
      payOfDate: '21 Nov, 2023',
      loanActive: '2',
      cardType: 'Credit Card',
      merchant: 'Merchant 1',
      activity: 'Shopping',
      images: [
        {
          file: '/assets/crypto/02.png',
          preview: '/assets/crypto/02.png',
        },
      ],
    },
    {
      id: 2,
      title: 'Card 2',
      description: 'Description for Card 2',
      amount: '123,18',
      percent: '60%',
      payOfDate: '21 Nov, 2023',
      loanActive: '2',
      cardType: 'Credit Card',
      merchant: 'Merchant 2',
      activity: 'Shopping',
      images: [
        {
          file: '/assets/crypto/02.png',
          preview: '/assets/crypto/02.png',
        },
      ],
    },
  ]);

  const [editedCard, setEditedCard] = useState<Card>(cards[0] || ({} as Card));
  const [newCard, setNewCard] = useState<Card>(createNewCard(cards.length + 1));

  // Сохраняем данные в localStorage при каждом изменении карточек
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(cards));
  }, [cards]);

  function createNewCard(id: number): Card {
    return {
      id,
      title: '',
      description: '',
      amount: '0',
      percent: '0%',
      payOfDate: new Date().toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      }),
      loanActive: '0',
      cardType: '',
      merchant: '',
      activity: '',
      images: [],
    };
  }

  const handleInputChange = (event: { target: { name: string; value: string } }) => {
    const { name, value } = event.target;
    setEditedCard((prev) => ({ ...prev, [name]: value }));
  };

  const handleDrop = useCallback(async (acceptedFiles: File[]) => {
    try {
      // Создаём массив файлов с предпросмотром
      const filesWithPreview = acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      // Загружаем файлы и получаем ссылки
      const filesLinks = await Promise.all(filesWithPreview.map((file) => uploadFile(file)));

      // Освобождаем временные `preview` (избегаем утечек памяти)
      filesWithPreview.forEach((file) => URL.revokeObjectURL(file.preview));

      // Объединяем загруженные ссылки с предпросмотром
      const uploadedImages = filesLinks.map((link) => ({
        file: link, // URL загруженного файла
        preview: link, // Используем URL как preview
      }));

      // Обновляем `images` без потери старых данных
      setEditedCard((prev) => ({
        ...prev,
        images: [...prev.images, ...uploadedImages],
      }));
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  }, []);

  const handleDropNew = useCallback(async (acceptedFiles: File[]) => {
    try {
      // Создаём массив файлов с предпросмотром
      const filesWithPreview = acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      // Загружаем файлы и получаем ссылки
      const filesLinks = await Promise.all(filesWithPreview.map((modFile) => uploadFile(modFile)));

      // Очищаем ненужные `preview`, чтобы избежать утечек памяти
      filesWithPreview.forEach((file) => URL.revokeObjectURL(file.preview));

      // Формируем объекты с загруженными ссылками
      const uploadedImages = filesLinks.map((link) => ({
        file: link, // Ссылка на загруженный файл
        preview: link, // Используем ссылку как preview
      }));

      setNewCard((prev) => ({
        ...prev,
        images: [...prev.images, ...uploadedImages], // Добавляем объекты в images
      }));
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  }, []);

  const handleRemoveImage = (image: { file: File | string; preview: string }) => {
    if (typeof image.file !== 'string') {
      URL.revokeObjectURL(image.preview);
    }

    setEditedCard((prev) => ({
      ...prev,
      images: prev.images.filter((img) => img !== image),
    }));
  };

  const handleSave = () => {
    setCards((prevCards) =>
      prevCards.map((card) => (card.id === editedCard.id ? editedCard : card))
    );
    editCardModal.onFalse();
  };

  const openEditModal = (card: Card) => {
    setEditedCard(card);
    editCardModal.onTrue();
  };

  const handleAddCard = () => {
    addNewCard.onTrue();
  };

  const handleSaveNewCard = () => {
    setCards((prevCards) => [...prevCards, { ...newCard, id: prevCards.length + 1 }]);
    setNewCard(createNewCard(cards.length + 2));
    addNewCard.onFalse();
  };

  const addNewCardComponent = () => (
    <Box
      sx={{
        width: '270px',
        height: '150px',
        bgcolor: '#7551DC',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={handleAddCard}
    >
      <ViewCard
        card={{
          title: 'New card',
          description: 'Add new card',
          amount: '',
          percent: '',
          payOfDate: '',
          loanActive: '',
          cardType: '',
          merchant: '',
          activity: '',
          images: [],
        }}
        edit={() => {}}
        block={block}
      />
    </Box>
  );

  const component = () => (
    <>
      <Slider {...sliderSettings}>
        {currentLang.value === 'he' && addNewCardComponent()}

        {cards.map((card) => (
          <Box key={card.id}>
            <ViewCardWallet2 card={card} edit={openEditModal} block={block}/>
          </Box>
        ))}

        {currentLang.value === 'en' && addNewCardComponent()}
      </Slider>

      {/* <AnimatedCard /> */}

      {/* Модалка редактирования */}
      <Modal open={editCardModal.value} onClose={editCardModal.onFalse}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '100%' : '50%',
            bgcolor: 'background.paper',
            p: 3,
            borderRadius: '10px',
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h6">Edit Card</Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Select
                displayEmpty
                value={editedCard.cardType}
                onChange={(e) => setEditedCard((prev) => ({ ...prev, cardType: e.target.value }))}
                inputProps={{ 'aria-label': 'Select Card Type' }}
              >
                <MenuItem value="" disabled>
                  Select Card Type
                </MenuItem>
                <MenuItem value="Credit Card">Credit Card</MenuItem>
                <MenuItem value="Debit Card">Debit Card</MenuItem>
                <MenuItem value="Gift Card">Gift Card</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <Select
                displayEmpty
                value={editedCard.merchant}
                onChange={(e) => setEditedCard((prev) => ({ ...prev, merchant: e.target.value }))}
                inputProps={{ 'aria-label': 'Select Merchant' }}
                disabled={!editedCard.cardType}
              >
                <MenuItem value="" disabled>
                  Select Merchant
                </MenuItem>
                <MenuItem value="Merchant 1">Merchant 1</MenuItem>
                <MenuItem value="Merchant 2">Merchant 2</MenuItem>
                <MenuItem value="Merchant 3">Merchant 3</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <Select
                displayEmpty
                value={editedCard.activity}
                onChange={(e) => setEditedCard((prev) => ({ ...prev, activity: e.target.value }))}
                inputProps={{ 'aria-label': 'Select Activity' }}
                disabled={!editedCard.merchant}
              >
                <MenuItem value="" disabled>
                  Select Activity
                </MenuItem>
                <MenuItem value="Shopping">Shopping</MenuItem>
                <MenuItem value="Travel">Travel</MenuItem>
                <MenuItem value="Dining">Dining</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Title"
              name="title"
              value={editedCard.title}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Description"
              name="description"
              value={editedCard.description}
              onChange={handleInputChange}
              fullWidth
            />
            <Stack gap={1}>
              <Typography>Background image</Typography>
              {editedCard?.images?.length ? (
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                  {editedCard.images.map((image, index) => (
                    <Box key={index} sx={{ position: 'relative', width: 64, height: 64 }}>
                      <img
                        src={image.preview}
                        alt="Preview"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: '8px',
                        }}
                      />
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: -8,
                          right: -8,
                          bgcolor: 'white',
                          zIndex: 1,
                        }}
                        onClick={() => handleRemoveImage(image)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              ) : (
                <UploadBox onDrop={handleDrop} />
              )}
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Button variant="outlined" color="error" onClick={editCardModal.onFalse}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>

      {/* Модалка добавления */}
      <Modal open={addNewCard.value} onClose={addNewCard.onFalse}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '100%' : '50%',
            bgcolor: 'background.paper',
            p: 3,
            borderRadius: '10px',
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h6">Add New Card</Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Select
                displayEmpty
                value={newCard.cardType}
                onChange={(e) => setNewCard((prev) => ({ ...prev, cardType: e.target.value }))}
                inputProps={{ 'aria-label': 'Select Card Type' }}
              >
                <MenuItem value="" disabled>
                  Select Card Type
                </MenuItem>
                <MenuItem value="Credit Card">Credit Card</MenuItem>
                <MenuItem value="Debit Card">Debit Card</MenuItem>
                <MenuItem value="Gift Card">Gift Card</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <Select
                displayEmpty
                value={newCard.merchant}
                onChange={(e) => setNewCard((prev) => ({ ...prev, merchant: e.target.value }))}
                inputProps={{ 'aria-label': 'Select Merchant' }}
                disabled={!newCard.cardType}
              >
                <MenuItem value="" disabled>
                  Select Merchant
                </MenuItem>
                <MenuItem value="Merchant 1">Merchant 1</MenuItem>
                <MenuItem value="Merchant 2">Merchant 2</MenuItem>
                <MenuItem value="Merchant 3">Merchant 3</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <Select
                displayEmpty
                value={newCard.activity}
                onChange={(e) => setNewCard((prev) => ({ ...prev, activity: e.target.value }))}
                inputProps={{ 'aria-label': 'Select Activity' }}
                disabled={!newCard.merchant}
              >
                <MenuItem value="" disabled>
                  Select Activity
                </MenuItem>
                <MenuItem value="Shopping">Shopping</MenuItem>
                <MenuItem value="Travel">Travel</MenuItem>
                <MenuItem value="Dining">Dining</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Title"
              name="title"
              value={newCard.title}
              onChange={(e) => setNewCard({ ...newCard, title: e.target.value })}
              fullWidth
            />
            <TextField
              label="Description"
              name="description"
              value={newCard.description}
              onChange={(e) => setNewCard({ ...newCard, description: e.target.value })}
              fullWidth
            />
            <Stack>
              <Typography>Background image</Typography>
              {newCard?.images?.length ? (
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                  {newCard.images.map((image, index) => (
                    <Box key={index} sx={{ position: 'relative', width: 64, height: 64 }}>
                      <img
                        src={image.preview}
                        alt="Preview"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: '8px',
                        }}
                      />
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: -8,
                          right: -8,
                          bgcolor: 'white',
                          zIndex: 1,
                        }}
                        onClick={() =>
                          setNewCard((prev) => ({
                            ...prev,
                            images: prev.images.filter((img) => img !== image),
                          }))
                        }
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              ) : (
                <UploadBox onDrop={handleDropNew} />
              )}
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Button variant="outlined" color="error" onClick={addNewCard.onFalse}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleSaveNewCard}>
                Save
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );

  return (
    <>
      {isMobile ? (
        <Box sx={{ maxWidth: '100%', mb: 2 }}>{component()}</Box>
      ) : (
        <Container maxWidth="xs" sx={{ mt: 2, mb: 2 }}>
          {component()}
        </Container>
      )}
    </>
  );
};

export default CardSliderWallet2;
