import React from 'react';

import { Box, Stack, Button, TextField, Typography, InputAdornment } from '@mui/material';

import Iconify from '../../../../../components/iconify';

type Props = {
  close: () => void;
};

const MobilePayment = ({ close }: Props) => (
    <Box sx={{ borderRadius: '10px', maxWidth: 500 }}>
      <Stack direction="row" alignItems="center" gap={2} mb={2}>
        <Iconify icon="fluent:ios-arrow-24-regular" sx={{ cursor: 'pointer' }} onClick={close} />
        <Typography>Mobile payment</Typography>
      </Stack>
      <Stack spacing={2}>
        <TextField
          fullWidth
          value="+171234567890"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="solar:phone-outline" color="#ff8a65" />
              </InputAdornment>
            ),
            readOnly: true,
          }}
        />

        <TextField
          fullWidth
          placeholder="Amount"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="solar:dollar-outline" color="#ff8a65" />
              </InputAdornment>
            ),
          }}
        />

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" color="text.secondary">
            Your balance: 4 863.27 USD
          </Typography>
          <Typography variant="body2" color="text.secondary">
            No fees
          </Typography>
        </Stack>

        <Button
          fullWidth
          variant="contained"
          sx={{ bgcolor: '#080016', color: 'white', py: 1.5, borderRadius: '8px' }}
        >
          Confirm
        </Button>
      </Stack>
    </Box>
  );

export default MobilePayment;
