'use client';

import { useParams } from 'next/navigation';
import { useMemo, useState, useEffect, useCallback } from 'react';

import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import BackspaceIcon from '@mui/icons-material/Backspace';

import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import SanitizeHTML from 'src/utils/sanitize';

import { useTranslate } from 'src/locales';
import { addToBasket } from 'src/api/basket';
import { useAuthContext } from 'src/auth/hooks';
import { extractPrices } from 'src/helpers/extractPrices';
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';
import Scrollbar from 'src/extra-components/bazaar/Scrollbar';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { uploadProductsImage } from 'src/helpers/uploadProductsImage';
import { checkIfProductInCart } from 'src/helpers/checkIfProductInCart';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import { IMerchant } from 'src/types/merchant';
import { BlockType } from 'src/types/page-generator';

import BasicModal from '../modals/basic-modal';
import { useContentContext } from '../content';
import { IProduct } from '../../types/product';
import { IAsterisk } from '../../types/asterisk';
import { getMerchant } from '../../api/merchant';
import { IBranche, INetwork } from '../../types/networks';
import { getProduct, getBonusRuleByProduct } from '../../api/product';
import { getContentValueFromProps } from '../../sections/generate-page/utils';

type Props = {
  merchant: IMerchant | IBranche | any;
  extraButton?: React.ReactNode;
  product?: any;
  block?: BlockType;
  network?: INetwork | null;
  productId?: string | string[];
};

const MerchantCard = ({ merchant, extraButton, product, block, network, productId }: Props) => {
  const localParams = useParams();
  const getFieldValue = getContentValueFromProps(block);
  const payHere = useBoolean();
  const { t } = useTranslate();
  const isMobile = useResponsive('down', 'sm');
  const router = useRouter();
  const { user } = useAuthContext();
  const { openSite } = useContentContext();
  const { dispatch, state } = useAppContext();
  const { cart } = state;
  const smbAccountId = state.smbAccount.id || '';
  const activityId = state.smbActivityId || '';
  const smUp = useResponsive('up', 'sm');
  const branches = useBoolean();
  const details = useBoolean();
  const imageSrc = merchant.mainImageURL ? `${BASIC_URL_FOR_IMAGES}${merchant.mainImageURL}` : '';
  const cleanDescription = merchant.description?.replace(/<\/?[^>]+(>|$)/g, '') || '';
  const { price, basePrice: discountPrice } = extractPrices(product?.prices);
  const path = product ? `/vendor/${merchant?.id}` : `/merchant/${merchant?.id}`;
  const [localMerchant, setMerchant] = useState<any>([]);
  const isInCart = checkIfProductInCart(cart, product?.id);
  const [searchText, setSearchText] = useState('');
  const [amount, setAmount] = useState('0'); // Хранит введенную сумму
  const keyboardNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '', '0'];
  const [currentModalMerchant, setCurrentModalMerchant] = useState<any>([]);
  const [error, setError] = useState('');
  const [localProduct, setProduct] = useState<any>({});
  const [bonusRule, setBonusRule] = useState<any>({});
  const [matchingTags, setMatchingTags] = useState<any>([]);

  const amountType =
    bonusRule && bonusRule?.amountType !== 'PERCENTAGE' ? bonusRule?.amountType
      : 'PERCENTAGE';
  const bonusRules =
    bonusRule ? bonusRule?.amount
      : 0;
  const totalPrice =
    amountType === 'PERCENTAGE'
      ? Number(amount) - Number(amount) * (bonusRules / 100)
      : Number(amount) - bonusRules;

  const imageLink =
    currentModalMerchant &&
    currentModalMerchant?.products
      ? `${uploadProductsImage(`${currentModalMerchant &&
      currentModalMerchant?.products &&
      currentModalMerchant?.products[0]?.mainImageURL}`)}`
      : '';

  const currentProductInCart = useMemo(() => (
    checkIfProductInCart(cart, currentModalMerchant &&
      currentModalMerchant?.products &&
      currentModalMerchant?.products[0]?.id)
  ), [currentModalMerchant]);

  const tagsImages = useMemo(
    () => getFieldValue('tagsImages'),
    [block?.tagsImages],
  );

  const merchantsTags = useMemo(() => {
    const tagsValue = getFieldValue('merchantsTags');
    if (Array.isArray(tagsValue)) {
      return tagsValue.map((tag: any) => ({
        ...tag,
        image: tagsImages[tag.value] ?? tag?.image,
      }));
    }
    return [];
  }, [block, tagsImages]);

  useEffect(() => {
    if (!merchant?.tags || !block?.merchantsTags) return;
    if (block?.settings?.enableSmallInfo) {
      const tags = merchantsTags.filter((tag: any) =>
        merchant?.tags.some(
          (productsTag: any) => productsTag.name === tag.title,
        ),
      );
      setMatchingTags(tags);
    }
  }, [merchant?.tags, block?.merchantsTags, block?.settings?.enableSmallInfo]);

  useEffect(() => {
    if (payHere.value) {
      getMerchant(merchant.id, dispatch).then((it) => {
        setCurrentModalMerchant(it);
      });
    }
  }, [payHere.value, merchant]);

  useEffect(() => {
    if (payHere.value) {
      const idx = currentModalMerchant &&
        currentModalMerchant?.products &&
        currentModalMerchant?.products[0]?.id;
      if (!idx) return;
      getProduct(idx, dispatch).then((item) => {
        setProduct(item);
      });
      getBonusRuleByProduct(idx, smbAccountId, activityId).then((item) => {
        setBonusRule(item);
      });
      // getProductBonusRule(id, smbAccountId, activityId).then((it) => {
      //   console.log('it',it);
      //   setProduct(it);
      // });
    }
  }, [payHere.value, currentModalMerchant, smbAccountId, activityId]);

  const minPrice = currentModalMerchant &&
  currentModalMerchant?.products &&
  currentModalMerchant?.products[0]?.id ?
    currentModalMerchant &&
    currentModalMerchant?.products &&
    currentModalMerchant?.products[0].prices.find((i: { type: string }) => i.type === 'min_price')?.value : '';

  const maxPrice = currentModalMerchant &&
  currentModalMerchant?.products &&
  currentModalMerchant?.products[0]?.id ?
    currentModalMerchant &&
    currentModalMerchant?.products &&
    currentModalMerchant?.products[0].prices.find((i: { type: string }) => i.type === 'max_price')?.value : '';

  const basePrice = currentModalMerchant &&
  currentModalMerchant?.products &&
  currentModalMerchant?.products[0]?.id ?
    currentModalMerchant &&
    currentModalMerchant?.products &&
    currentModalMerchant?.products[0].prices.find((i: { type: string }) => i.type === 'base_price')?.value : '';

  useEffect(() => {
    const newPrice = minPrice ? `${minPrice ?? ''}` : `${basePrice ?? ''}`;
    setAmount(newPrice);
  }, [basePrice, minPrice, payHere.value]);

  const handleNumberClick = (num: string) => {
    // Формируем новое значение
    const newAmount = amount === '0' ? num : amount + num;

    // Преобразуем новое значение в число
    const newValue = parseInt(newAmount, 10);
    // Проверяем, находится ли новое значение в диапазоне от minPrice до maxPrice
    // if (newValue >= minPrice && newValue <= maxPrice) {
    if (maxPrice && newValue <= maxPrice) {
      setAmount(newAmount);
    } else if (!maxPrice) {
      setAmount(newAmount);
    }
  };

// Функция удаления последнего символа
  const handleBackspace = useCallback(() => {
    if (amount.length === 1) {
      setAmount('0'); // Если одно число, сбрасываем в "0"
    } else {
      setAmount((prev) => prev.slice(0, -1)); // Удаляем последний символ
    }
  }, [amount]);

  useEffect(() => {
    setMerchant(merchant);
  });
  //
  // useEffect(() => {
  //   getMerchant(merchant.id, dispatch).then((value: IMerchant[]) => setMerchant(value));
  // }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const getBranchAsterisk = useCallback(
    (branch: IBranche): IAsterisk | null => {
      const merchantAsterisks = branch.asterisks;

      if (Array.isArray(merchantAsterisks) && merchantAsterisks.length) {
        return merchantAsterisks[0];
      }

      const merchantCategories = branch.categories;

      if (Array.isArray(merchantCategories) && merchantCategories.length) {
        const merchantCategoryAsterisks = merchantCategories[0].asterisks;

        if (Array.isArray(merchantCategoryAsterisks) && merchantCategoryAsterisks.length) {
          return merchantCategoryAsterisks[0];
        }
      }

      if (Array.isArray(network?.asterisks) && network?.asterisks.length) {
        return network?.asterisks[0];
      }

      return null;
    },
    [network],
  );

  let merchantProductAsterisks: string = '';
  if (merchant && merchant.products && productId) {
    const current: IProduct | undefined = merchant.products.find((item: any) => item.id === productId);

    if (current && current.asterisks) {
      current.asterisks.forEach((asterisk) => {
        if (asterisk.merchants) {
          const currentMerchant: IMerchant | undefined = asterisk.merchants.find(
            (item) => item.id === merchant.id,
          );
          if (currentMerchant) {
            merchantProductAsterisks = asterisk.description || '';
          }
        }
      });
    }
  }

  const open = () => {
    payHere.onTrue();
  };

  const notOpen = () => {};

  const extra = useMemo(() => (
    <Button
      variant="outlined"
      color="secondary"
      sx={{
        mt: 3,
        borderRadius: '20px',
        color: 'primary.main',
        px: { xs: 1, md: 1 },
      }}
      onClick={() => {
        if (
          merchant &&
          merchant.products &&
          merchant.products[0]?.id
        ) {
          open(); // Выполняем функцию open
        } else {
          notOpen(); // Выполняем функцию notOpen
        }
      }}
    >
      {t('Pay here')}
    </Button>
  ), [merchant]);

  return (
    <Card
      sx={{
        // p: 1,
        width: '250px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: 5,
        position: 'relative',
      }}
    >
      {block?.settings?.enableSmallInfo && (
        <Stack
          sx={{
            position: 'absolute',
            left: '0px',
            top: '0px',
            p: 1,
            zIndex: 100,
          }}
          gap={0.5}
        >
          {matchingTags.map((tag: any, index: number) => (
            <Stack key={index} direction="row" alignItems="center">
              {(block?.settings?.smallInfoViewSettings === 'all' ||
                block?.settings?.smallInfoViewSettings === 'icon') && (
                <Image
                  src={tag.image}
                  sx={{ width: '25px', height: '25px', mr: 1 }}
                />
              )}
              {(block?.settings?.smallInfoViewSettings === 'all' ||
                block?.settings?.smallInfoViewSettings === 'text') && (
                <Typography
                  sx={{
                    color: block?.settings?.smallInfoTextColor,
                    fontSize: block?.settings?.smallInfoFontSize,
                    fontWeight: block?.settings?.smallInfoFontWeight,
                    fontStyle: block?.settings?.smallInfoFontStyle,
                    textDecoration: block?.settings?.smallInfoTextDecoration,
                  }}
                >
                  {tag.title}
                </Typography>
              )}
            </Stack>
          ))}
        </Stack>
      )}
      <Image
        src={imageSrc}
        sx={{
          // borderRadius: '15px',
          height: 140,
        }}
        onClick={(event) => {
          localStorage.setItem('productId', product?.id);
          if (block?.settings?.enableLink) {
            router.push(path);
          }
        }}
      />
      {/* <Box
        sx={{
          borderRadius: '15px',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'secondary.contrastText',
          m: 1,
        }}
      >
        <Image src={merchant.mainImageURL} sx={{ borderRadius: '15px' }} />
      </Box> */}
      <Typography
        sx={{
          px: 1,
          width: '100%',
          height: { xs: '30px', md: '20px' },
          color: block?.settings?.contentTextColor || block?.contentTextColor,
          fontSize: block?.settings?.contentFontSize || block?.contentFontSize,
          fontWeight: block?.settings?.contentFontWeight || block?.contentFontWeight,
          textAlign: block?.settings?.contentAlign || block?.contentAlign,
          mt: product ? 6 : 1,
          mb: 1,
          // textAlign: 'center',
        }}
        onClick={(event) => {
          if (block?.settings?.enableLink) {
            router.push(path);
          }
        }}
      >
        {merchant.title}
      </Typography>
      {!product ? (
        <Stack
          direction="row-reverse"
          alignItems="baseline"
          justifyContent="space-around"
          width={1}
          gap={1}
          mb={1}
        >
          {!!merchant.networkId && (
            <Button
              variant="outlined"
              color="primary"
              sx={{
                mt: 3,
                borderRadius: '20px',
                color: 'primary.main',
                px: { xs: 1, md: 1 },
              }}
              onClick={branches.onTrue}
            >
              {t('Branches')}
            </Button>
          )}
          {!!merchant.url && (
            <Button
              variant="outlined"
              color="primary"
              sx={{
                mt: 3,
                borderRadius: '20px',
                color: 'primary.main',
                px: { xs: 1, md: 1 },
              }}
              onClick={() => {
                if (merchant.url) {
                  router.push(merchant.url);
                }
              }}
            >
              {t('Link')}
            </Button>
          )}
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              mt: 3,
              borderRadius: '20px',
              color: 'primary.main',
              px: { xs: 1, md: 1 },
            }}
            endIcon={<Iconify icon={block?.settings?.buttonIcon} />}
            onClick={details.onTrue}
          >
            {t('Details')}
          </Button>
          {block?.settings?.enablePayHereButton && (
            <>
              {!!extraButton && extraButton || extra}
            </>
          )}
        </Stack>
      ) : (
        <Box mt={1}>
          <Stack direction="row-reverse" justifyContent="space-between" gap={1} alignItems="center">
            {block?.settings?.enableButton && (
              <>
                {isInCart ? (
                  <Button
                    variant="contained"
                    color="success"
                    sx={{
                      height: '40px',
                      borderRadius: '25px',
                      fontWeight: 400,
                    }}
                    endIcon={<Iconify icon="ep:success-filled" />}
                  >
                    &nbsp; בעגלה&nbsp;
                  </Button>
                ) : (
                  <Button
                    variant={block?.settings?.buttonVariant || 'contained'}
                    // fullWidth
                    size={block?.settings?.buttonSize}
                    sx={{
                      borderRadius: block?.settings?.buttonBorderRadius,
                      height: '30px',
                      background:
                        block?.settings?.buttonVariant === 'contained' &&
                        block?.settings?.buttonBgColor,
                      color:
                        block?.settings?.buttonVariant === 'outlined'
                          ? block?.settings?.buttonBgColor
                          : block?.settings?.buttonColor,
                      fontWeight: 400,
                      borderColor:
                        block?.settings?.buttonVariant === 'outlined' &&
                        block?.settings?.buttonBgColor,
                      '&:hover': {
                        background:
                          block?.settings?.buttonVariant === 'contained'
                            ? block?.settings?.buttonBgColor
                            : '',
                        color:
                          block?.settings?.buttonVariant === 'outlined'
                            ? block?.settings?.buttonBgColor
                            : block?.settings?.buttonColor,
                        borderColor:
                          block?.settings?.buttonVariant === 'outlined' &&
                          block?.settings?.buttonBgColor,
                      },
                    }}
                    endIcon={<Iconify icon={block?.settings?.buttonIcon} />}
                    onClick={() => {
                      if (!user) {
                        dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: 'login' });
                      } else {
                        addToBasket(
                          state.smbAccount.id,
                          product.id,
                          (block?.settings?.enableDiscount && discountPrice) || price,
                          imageSrc,
                          dispatch,
                          cart,
                          [],
                        );
                      }
                    }}
                  >
                    {block?.settings?.buttonLabel}
                  </Button>
                )}
              </>
            )}
            {block?.settings?.enablePrice && (
              <Stack>
                {block?.settings?.enableDiscount && (
                  <Typography
                    sx={{
                      color: block?.settings?.topPriceColor,
                      textDecoration: 'line-through',
                      fontSize: block?.settings?.topPriceFontSize,
                    }}
                  >
                    ₪{price}
                  </Typography>
                )}
                {block?.settings?.enableDiscount ? (
                  <Typography
                    sx={{
                      color: block?.settings?.bottomPriceColor,
                      fontSize: block?.settings?.bottomPriceFontSize,
                    }}
                  >
                    ₪{discountPrice}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      color: block.settings.bottomPriceColor,
                      fontSize: block.settings.bottomPriceFontSize,
                    }}
                  >
                    ₪{price}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
          <Grid
            container
            sx={{
              p: 1,
              justifyContent: 'space-between',
              display:
                !block?.settings?.enableMerchantBalance && !block?.settings?.enableMerchantAvailable
                  ? 'none'
                  : '',
            }}
          >
            <Grid item md={6}>
              {block?.settings?.enableMerchantBalance && (
                <Stack direction="row">
                  <Typography>נשאר: 199</Typography>
                </Stack>
              )}
            </Grid>
            <Grid item md={6}>
              {block?.settings?.enableMerchantAvailable && (
                <Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography sx={{ mx: 1 }}>כמות</Typography>
                    <TextField
                      sx={{
                        '& .MuiNativeSelect-select': { py: 0 },
                      }}
                      select
                      fullWidth
                      SelectProps={{
                        native: true,
                        sx: { textTransform: 'capitalize' },
                      }}
                      onChange={() => {
                      }}
                    >
                      {[1, 2, 3].map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </TextField>
                  </Stack>
                  <Typography sx={{ fontSize: '10px', textAlign: 'end' }}>
                    ניתן לקנייה: 1
                  </Typography>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
      <BasicModal open={branches.value} onClose={() => branches.onFalse()}>
        <Stack alignItems="flex-start">
          <Typography id="modal-modal-title" variant="h6" component="h2" color="secondary.main">
            סניפים
          </Typography>
          <TextField
            sx={{ py: 2 }}
            fullWidth
            placeholder="חיפוש..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  {searchText !== '' && (
                    <Iconify
                      icon="solar:trash-bin-trash-bold"
                      color="red"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setSearchText('')}
                    />
                  )}
                </InputAdornment>
              ),
            }}
            value={searchText}
            onChange={handleSearchChange}
          />
          <Scrollbar sx={{ height: smUp ? '400px' : '150px', width: '100%' }}>
            {!network ? (
              <Stack gap={2} height="450px">
                <Stack>
                  <Typography>{localMerchant?.network?.name}</Typography>
                  <Image
                    src={uploadProductsImage(
                      localMerchant.network?.mainImageURL
                        ? uploadProductsImage(localMerchant.network?.mainImageURL)
                        : '/assets/images/network-default.png',
                    )}
                    sx={{ '& img': { width: '100px' } }}
                  />
                  <SanitizeHTML html={localMerchant?.network?.content} />
                  {/* <Link href={localMerchant?.url || ''} target="_blank"> */}
                  {/*  <Typography sx={{ textDecoration: 'underline' }}> */}
                  {/*    לאתר בית העסק לחץ כאן */}
                  {/*  </Typography> */}
                  {/* </Link> */}
                  {localMerchant?.asterisks && (
                    <Typography
                      sx={{
                        textAlign: 'start',
                        width: '100%',
                        fontSize: '10px',
                        mt: 1,
                        height: '10px',
                        color: 'red',
                      }}
                    >
                      {localMerchant?.asterisks && localMerchant?.asterisks[0] && '*'}
                      {localMerchant?.asterisks && localMerchant?.asterisks[0]?.description}
                    </Typography>
                  )}
                  {!localMerchant?.asterisks && product?.asterisks && (
                    <Typography
                      sx={{
                        textAlign: 'start',
                        width: '100%',
                        fontSize: '10px',
                        mt: 1,
                        height: '10px',
                        color: 'red',
                      }}
                    >
                      {product?.asterisks && product?.asterisks[0] && '*'}
                      {product?.asterisks && product?.asterisks[0]?.description}
                    </Typography>
                  )}
                </Stack>
                {/*  ); */}
                {/* })} */}
                {/* TO-DO: add real branches */}
                {/* {branchItems.map((branch, id) => (
                  <Stack key={id}>
                    <Typography>{branch.title}</Typography>
                    <Typography sx={{ color: 'grey.600' }}>{branch.subTitle}</Typography>
                    <Typography sx={{ color: 'grey.600' }}>{branch.phone}</Typography>
                  </Stack>
                ))} */}
              </Stack>
            ) : (
              <Stack gap={2} height="450px">
                {network?.branches?.map((branch: IBranche, id: number) => {
                  const image = branch?.mainImageURL
                    ? uploadProductsImage(branch?.mainImageURL || '')
                    : '/assets/images/network-default.png';
                  const branchAsterisk = getBranchAsterisk(branch);
                  if (branch.title.includes(searchText) || branch.content.includes(searchText)) {
                    return (
                      <Stack key={id} sx={{ my: 1 }}>
                        <Image
                          onClick={() => router.push(`/${localParams.lng}/merchant/${branch?.id}`)}
                          src={uploadProductsImage(image)}
                          sx={{ '& img': { width: '100px' } }}
                        />
                        <Typography
                          variant="h4"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => router.push(`/${localParams.lng}/merchant/${branch?.id}`)}
                        >
                          {branch.title}
                        </Typography>
                        <Typography sx={{ color: 'grey.600' }}>
                          <SanitizeHTML html={branch.content} />
                        </Typography>
                        {/* <Link href={branch?.url || ''} target="_blank"> */}
                        {/*  <Typography sx={{ textDecoration: 'underline' }}> */}
                        {/*    לאתר בית העסק לחץ כאן */}
                        {/*  </Typography> */}
                        {/* </Link> */}
                        {branchAsterisk && (
                          <Typography
                            sx={{
                              textAlign: 'start',
                              width: '100%',
                              fontSize: '10px',
                              mt: 1,
                              height: '10px',
                              color: 'red',
                            }}
                          >
                            {!!branchAsterisk && '*'}
                            {branchAsterisk.description}
                          </Typography>
                        )}
                      </Stack>
                    );
                  }
                  return null;
                })}
              </Stack>
            )}
          </Scrollbar>
        </Stack>
      </BasicModal>
      <BasicModal open={details.value} onClose={() => details.onFalse()}>
        <Stack alignItems="flex-start">
          <Typography id="modal-modal-title" variant="h6" component="h2" color="secondary.main">
            {merchant.title || ''}
          </Typography>
          <Stack
            direction="row-reverse"
            justifyContent="space-between"
            width={1}
            alignItems="center"
            gap={1}
          >
            {/* {merchant.url && ( */}
            {/*  <Stack direction="row-reverse" gap={2} sx={{ color: 'secondary.main' }}> */}
            {/*    <Link href={merchant.url} target="_blank"> */}
            {/*      <Typography sx={{ textDecoration: 'underline' }}> */}
            {/*        לאתר בית העסק לחץ כאן */}
            {/*      </Typography> */}
            {/*      /!* <Iconify icon="fa-solid:globe" /> *!/ */}
            {/*    </Link> */}
            {/*  </Stack> */}
            {/* )} */}
            {imageSrc && (
              <Image src={imageSrc}
                     sx={{ '& img': { width: { md: '150px', xs: '100%' }, height: { md: '150px', xs: '100%' } } }} />
            )}
            {!isMobile && (
              <Typography sx={{ flex: 1, fontSize: '14px' }}>{cleanDescription}</Typography>
            )}
          </Stack>
          {isMobile && <Typography>{cleanDescription}</Typography>}
          {merchant.content && (
            <Typography>
              <SanitizeHTML html={merchant.content as string} />
            </Typography>
          )}
          <Stack>
            {/* {merchant && */}
            {/*  merchant.asterisks && */}
            {/*  merchant.asterisks.map((asterisks) => ( */}
            {merchantProductAsterisks && (
              <Typography
                // key={asterisks.id}
                color={block?.settings?.asterisksColor}
              >{`${merchantProductAsterisks}*`}</Typography>
            )}
            {/* ))} */}
          </Stack>
        </Stack>
      </BasicModal>
      <BasicModal open={payHere.value} onClose={() => {
        payHere.onFalse();
        setAmount('0');
      }}>
        <Stack spacing={2} sx={{ padding: 2 }}>
          {/* Заголовок с кнопкой возврата */}
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={{ textAlign: 'center', width: 1 }} variant="h5" fontWeight="bold">
              תשלום בבית עסק
            </Typography>
          </Stack>
          {minPrice && maxPrice && (
            <Typography sx={{ textAlign: 'center', width: 1 }} variant="body1">
              מינימום לתשלום - ₪{minPrice}, מקסימום לתשלום - ₪{maxPrice}
            </Typography>
          )}


          {/* Карточка для суммы оплаты */}
          <Box
            sx={{
              border: '1px solid #ccc',
              borderRadius: 2,
              padding: 2,
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              {amount}
            </Typography>
            {error.length > 1 && (
              <Typography sx={{ color: 'red', fontSize: '10px' }}>{error}</Typography>
            )}
            <Typography variant="subtitle1">
              סכום לתשלום לאחר {bonusRules}% הנחה.
            </Typography>
            <Typography variant="h4">{totalPrice.toFixed(2) || ''}</Typography>
          </Box>

          {/* Клавиатура */}
          <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
            {keyboardNumbers.map((key, index) => (
              <Grid item xs={4} key={index}>
                {key ? (
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                      height: 50,
                      fontSize: 20,
                      borderRadius: 2,
                    }}
                    onClick={() => handleNumberClick(key)}
                  >
                    {key}
                  </Button>
                ) : (
                  <Box sx={{ height: 50 }} />
                )}
              </Grid>
            ))}
            <Grid item xs={4}>
              <IconButton
                sx={{ width: '100%', height: 50 }}
                onClick={handleBackspace}
              >
                <BackspaceIcon />
              </IconButton>
            </Grid>
          </Grid>

          {currentProductInCart ? (
            <Button variant="contained" fullWidth sx={{
              height: 50,
              bgcolor: 'green',
              '&:hover': { bgcolor: 'green' },
            }} onClick={() => router.push('/cart')}>&nbsp; בעגלה&nbsp;</Button>) : (
            <Button variant="contained" color="primary" fullWidth sx={{ height: 50 }}
                    onClick={() => {
                      if (!user) {
                        dispatch({
                          type: ActionTypes.SET_ACTIVE_DIALOG,
                          payload: 'login',
                        });
                      } else if (amount >= minPrice) {
                        const currentPrice = totalPrice;
                        addToBasket(
                          state.smbAccount.id,
                          currentModalMerchant &&
                          currentModalMerchant?.products &&
                          currentModalMerchant?.products[0]?.id,
                          currentPrice,
                          imageLink,
                          dispatch,
                          cart,
                          [],
                        ).then(() => {
                          payHere.onFalse();
                          setAmount('');
                          setError('');
                          router.push('/cart');
                        });
                      } else {
                        setError('הערך שהוזן נמוך מהמחיר המינימלי');
                      }
                    }}
            >
              שלם
            </Button>
          )}
        </Stack>
      </BasicModal>
    </Card>
  )
    ;
};

export default MerchantCard;
