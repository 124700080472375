import React, { useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, Button, TextField, Typography } from '@mui/material';

import Iconify from '../../../../../components/iconify';
import { useResponsive } from '../../../../../hooks/use-responsive';

type Props = {
  close: () => void;
};

const Moneybox = ({ close }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const [goalAmount, setGoalAmount] = useState(1200);
  const [goalName, setGoalName] = useState('');
  const [dailyAmount, setDailyAmount] = useState(10);
  const [roundingOption, setRoundingOption] = useState('$1');
  const [selectedCard, setSelectedCard] = useState('7895');

  const cards = [
    { last4: '7895', balance: 4863.27, brand: 'Visa' },
    { last4: '5378', balance: 2435.12, brand: 'MasterCard' },
  ];

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: !isMobile ? 400 : '100%',
        bgcolor: 'background.paper',
        border: '2px solid gray.500',
        borderRadius: !isMobile ? '10px' : '0px',
        boxShadow: 24,
        p: 4,
        pt: 1,
        height: !isMobile ? 'auto' : '100%',
      }}
    >
      <Box
        sx={{
          mx: 'auto',
          // p: 3,
          // bgcolor: '#fffaf5',
          borderRadius: 2,
        }}
      >
        <Stack direction="row" alignItems="center" gap={2} mb={1}>
          <Box
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // border: '1px solid',
              // borderColor: 'grey',
            }}
          >
            <Iconify
              icon="fluent:ios-arrow-24-regular"
              sx={{ cursor: 'pointer' }}
              onClick={close}
            />
          </Box>
          <Typography>Open moneybox</Typography>
        </Stack>
        <Typography sx={{ fontSize: '12px', color: 'grey' }}>
          The amount you want to achieve
        </Typography>
        <TextField
          fullWidth
          type="number"
          value={goalAmount}
          onChange={(e) => setGoalAmount(Number(e.target.value))}
          InputProps={{
            startAdornment: <Typography sx={{ pr: 1 }}>$</Typography>,
          }}
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          placeholder="Enter your goal"
          value={goalName}
          onChange={(e) => setGoalName(e.target.value)}
          sx={{ mb: 2 }}
        />

        <Typography sx={{ fontSize: '12px', color: 'grey' }}>Use card</Typography>
        {cards.map((card) => (
          <Card
            key={card.last4}
            variant="outlined"
            sx={{
              mb: 1,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              padding: 1,
              border: selectedCard === card.last4 ? '2px solid red' : '1px solid gray',
            }}
            onClick={() => setSelectedCard(card.last4)}
          >
            {/* <Image src={card.image} alt={card.brand} width={40} height={30} /> */}
            <CardContent sx={{ pt: 0, pb: '0px!important' }}>
              <Typography>**** **** **** {card.last4}</Typography>
              <Typography>${card.balance.toFixed(2)}</Typography>
            </CardContent>
          </Card>
        ))}

        <Typography sx={{ fontSize: '12px', color: 'grey' }}>Amount per day</Typography>
        <TextField
          fullWidth
          type="number"
          value={dailyAmount}
          onChange={(e) => setDailyAmount(Number(e.target.value))}
          InputProps={{
            startAdornment: <Typography sx={{ pr: 1 }}>$</Typography>,
          }}
          sx={{ mb: 2 }}
        />

        <RadioGroup value={roundingOption} onChange={(e) => setRoundingOption(e.target.value)} row>
          <FormControlLabel
            value="$1"
            control={<Radio />}
            label="Rounding up to $1 per transaction."
          />
          <FormControlLabel
            value="$10"
            control={<Radio />}
            label="Rounding up to $10 per transaction."
          />
        </RadioGroup>

        <Button
          fullWidth
          variant="contained"
          sx={{ bgcolor: '#000', color: '#fff', mt: 2, borderRadius: '10px' }}
          onClick={close}
        >
          Open Moneybox
        </Button>
      </Box>
    </Box>
  );
};

export default Moneybox;
