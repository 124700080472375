'use client';

import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';
import React, { useRef, useState, useEffect, useMemo } from 'react';

import { Box, Stack, Typography, IconButton } from '@mui/material';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import { getCategories } from '../../../../api/product';
import { ICategoryItem } from '../../../../types/product';
import { BlockType } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';
import { useLocales } from '../../../../locales';
import { getContentValueFromProps } from '../../utils';

type CategoryType = {
  value: string;
  title: string;
  image: string;
};

const CategoriesSwiper = ({
  // categories,
  activeCategory,
  changeCategory,
  block,
}: {
  // categories?: ICategoryItem[];
  activeCategory?: ICategoryItem;
  changeCategory?: (category: ICategoryItem) => void;
  block: BlockType;
}) => {
  const getFieldValue = getContentValueFromProps(block);
  const { currentLang } = useLocales();
  const swiperRef = useRef<SwiperCore | null>(null);
  const { dispatch, state } = useAppContext();
  const [active, setActive] = useState<any>([]);
  const communicationWithReverse: string = block?.settings?.communicationWithReverse;
  const communicationWithProducts: string = block?.settings?.communicationWithProducts;

  const categoriesImages = useMemo(
    () => getFieldValue('categoriesImages'),
    [block.categoriesImages],
  );

  const categoriesV2 = useMemo(() => {
    const categoriesValue = getFieldValue('customCategories');
    if (Array.isArray(categoriesValue)) {
      return categoriesValue.map((category: CategoryType) => ({
        ...category,
        image: categoriesImages[category.value] ?? category?.image,
      }));
    }
    return [];
  }, [block?.customCategories, categoriesImages]);

  const viewCategory = useMemo(() => {
    if (block?.settings?.customMode) {
      return categoriesV2.map((category) => ({
        id: category.value, // Приводим к ICategoryItem
        title: category.title,
        name: category.title,
        mainImageURL:
          category.image || '/assets/icons/activities/warehouse.png',
      }));
    }
    return state?.categories; // Уже ICategoryItem[]
  }, [block?.settings?.customMode, categoriesV2, state?.categories]);

  useEffect(() => {
    getCategories(state.smbAccount.id, dispatch);

    dispatch({
      type: ActionTypes.SET_COMMUNICATION,
      payload: { communicationWithReverse, communicationWithProducts },
    });
  }, [state.smbAccount.id, dispatch]);

  useEffect(() => {
    if (viewCategory?.length && active.length === 0) {
      setActive([viewCategory[0]]);
      dispatch({
        type: ActionTypes.SET_ACTIVE_CATEGORY,
        payload: { category: [viewCategory[0]] },
      });
    }
  }, [viewCategory, active, dispatch]);

  useEffect(() => {
    if (active.length > 0 && state?.activeCategory[0]?.id !== active[0]?.id) {
      dispatch({
        type: ActionTypes.SET_ACTIVE_CATEGORY,
        payload: { category: active },
      });
    }
  }, [active, state.activeCategory, dispatch]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{
        width: 1,
      }}
    >
      <IconButton onClick={() => swiperRef.current?.slideNext()}>
        <Iconify icon={currentLang.value === 'he' ? "eva:chevron-right-fill" : "eva:chevron-left-fill"} />
      </IconButton>
      <Box
        sx={{
          width: { xs: '70%', md: '90%' },
          div: {
            // justifyContent: 'space-between!important',
            justifyContent: 'center!important',
          },
        }}
      >
        <Swiper
          speed={1000}
          slidesPerView="auto"
          loop
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {viewCategory.map((category: any) => {
            // const image = icons.find((icon) => icon.id === category.id);
            const isActive = activeCategory?.id === category?.id;
            return (
              <SwiperSlide
                style={{ width: 'auto', flexShrink: 'inherit', marginLeft: 3 }}
                key={category?.id}
              >
                <Box
                  // @ts-ignore
                  onClick={() => setActive([category])}
                  flexDirection="column"
                  sx={{
                    width: 100,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <IconButton>
                    <Image
                      disabledEffect
                      visibleByDefault
                      alt="activity"
                      src={
                        category?.image && !block?.enableCustomImage
                          ? uploadProductsImage(category?.mainImageURL)
                          : category?.mainImageURL ||
                          '/assets/icons/activities/warehouse.png'
                      }
                      sx={{
                        width: state?.activeCategory[0]?.id === category.id ? 45 : 40,
                        height: state?.activeCategory[0]?.id === category.id ? 45 : 40,
                        objectFit: 'contain',
                        '& .wrapper': { width: 'auto', height: 'auto' },
                      }}
                    />
                  </IconButton>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      color: 'text.secondary',
                      fontWeight: state?.activeCategory[0]?.id === category.id ? 900 : 400,
                    }}
                  >
                    {category?.title}
                  </Typography>
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>

      <IconButton onClick={() => swiperRef.current?.slidePrev()}>
        <Iconify icon={currentLang.value === 'he' ? "eva:chevron-left-fill" : "eva:chevron-right-fill" } />
      </IconButton>
    </Stack>
  );
};

export default CategoriesSwiper;
