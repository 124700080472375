'use client';

import React, { useState, FC } from 'react';

import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import {
  Card,
  Stack,
  Avatar,
  Button,
  Select,
  Divider,
  MenuItem,
  Container,
  CardHeader,
  Typography,
  IconButton,
  InputLabel,
  CardContent,
  FormControl,
} from '@mui/material';

import { useLocales } from '../../../../../locales';
import { ComponentTypeProps } from '../../../../../types/page-generator';

const WalletProfile: FC<ComponentTypeProps> = ({ block }) => {
  const { currentLang } = useLocales();
  const user = {
    avatarUrl: 'https://i.pravatar.cc/150?img=3',
    fullName: 'יצחק כהן',
    email: 'itzhak.cohen@example.com',
    phone: '+972 50-123-4567',
    address: 'תל אביב, רחוב דיזנגוף 100',
    birthDate: '12 באפריל 1980',
  };

  const [cardOrder, setCardOrder] = useState('default');

  const cards = [
    { type: 'Visa', number: '**** 1234', balance: '5,000 ₪' },
    { type: 'Mastercard', number: '**** 5678', balance: '2,800 ₪' },
  ];

  const currencyRates = [
    { currency: 'USD', rate: 3.65 },
    { currency: 'EUR', rate: 4.02 },
    { currency: 'GBP', rate: 4.75 },
  ];

  const handleEdit = () => alert('עריכת פרטים');
  const handleDelete = () => alert('החשבון נמחק');

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: 5,
        direction: currentLang.value === 'he' ? 'rtl' : '',
        px: 0,
      }}
    >
      <Card sx={{ borderRadius: 3, border: '1px solid grey', width: 1 }}>
        <CardHeader
          avatar={
            <Box>
              <Avatar src={user.avatarUrl} sx={{ width: 64, height: 64 }} />
            </Box>
          }
          title={<Typography variant="h6">{user.fullName}</Typography>}
          subheader={user.email}
          action={
            <IconButton>
              <EditIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Stack spacing={1.5}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">
                <strong>Phone</strong>
              </Typography>
              <Typography>{user.phone}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">
                <strong>Address</strong>
              </Typography>
              <Typography>{user.address}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">
                <strong>Birthday</strong>
              </Typography>
              <Typography>{user.birthDate}</Typography>
            </Stack>
          </Stack>
          <Divider sx={{ my: 2 }} />

          <InputLabel>Card Order:</InputLabel>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select value={cardOrder} onChange={(e) => setCardOrder(e.target.value)}>
              <MenuItem value="default">Default</MenuItem>
              <MenuItem value="balance">By Balance</MenuItem>
              <MenuItem value="date">By Opening Date</MenuItem>
            </Select>
          </FormControl>

          {/* רשימת כרטיסים */}
          <Stack spacing={1}>
            {cards
              .sort((a, b) => (cardOrder === 'newest' ? -1 : 1))
              .map((card, index) => (
                <Card key={index} sx={{ p: 1, boxShadow: 1 }}>
                  <Typography variant="body1">
                    <strong>{card.type}</strong> {card.number}
                  </Typography>
                  <Typography variant="body2">balance: {card.balance}</Typography>
                </Card>
              ))}
          </Stack>

          <Divider sx={{ my: 2 }} />

          {/* שערי מט"ח */}
          <Typography variant="h6">Foreign exchange rates</Typography>
          <Stack spacing={1}>
            {currencyRates.map((rate) => (
              <Typography key={rate.currency} variant="body2">
                <strong>{rate.currency}:</strong> {rate.rate} ₪
              </Typography>
            ))}
          </Stack>

          <Divider sx={{ my: 2 }} />

          {/* כפתורים */}
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Button variant="outlined" color="warning" fullWidth>
              התנתקות
            </Button>
            <Button
              variant="outlined"
              color="error"
              fullWidth
              // onClick={handleDelete}
            >
              מחק חשבון
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};

export default WalletProfile;
