'use client'

// components
import { FC } from 'react';

import Stack from '@mui/material/Stack';
import { Container, Typography } from '@mui/material';

import Image from '../../../../../components/image';
import HistoryWalletItem from './history-wallet-item';
import history from '../../../../../constants/history';
import Scrollbar from '../../../../../components/scrollbar';
import { ComponentTypeProps } from '../../../../../types/page-generator';

const WalletHistory: FC<ComponentTypeProps> = ({ block }) => (
    <Container maxWidth="sm">
      <Stack width={1} direction="row" gap={1}>
        <Image
          sx={{ width: '20px' }}
          src="/assets/icons/wallet/icon-history-light.svg"
        />
        <Typography>History</Typography>
      </Stack>
      <Scrollbar sx={{ height: '550px' }}>
        <Stack gap={0}>
          {history.map((item, idx) => (
            <HistoryWalletItem
              key={item.id}
              type={item.type}
              date={item.date}
              currencyLabel={item.currencyLabel}
              amount={item.amount}
              idx={idx}
            />
          ))}
        </Stack>
      </Scrollbar>
    </Container>
  );
export default WalletHistory;
