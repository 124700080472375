import { useState } from 'react';

import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  Box,
  Radio,
  MenuItem,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
} from '@mui/material';

import { AddCard } from './add-card';
import Image from '../../../../components/image';
import { DocumentAttach } from './document-attach';
import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';

type Props = {
  close: () => void;
  loanRequestClose: () => void;
  loanCalculatorClose: () => void;
};

export const RecentCards = ({ close, loanRequestClose, loanCalculatorClose }: Props) => {
  const documentAttach = useBoolean();
  const addCard = useBoolean();
  const isMobile = useResponsive('down', 'sm');
  const addresses = ['Indonesia', 'Israel'];
  const [activePeriod, setActivePeriod] = useState('Indonesia');
  const [value, setValue] = useState<string>('');
  const marks = [
    {
      value: 345,
      label: '$ 345',
    },
    {
      value: 10000,
      label: '$ 10.000',
    },
  ];
  const [selectedValue, setSelectedValue] = useState('mastercard');

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleChangePeriod = (event: React.ChangeEvent<{ value: unknown }>) => {
    // @ts-ignore
    setActivePeriod(event.target.value as string);
  };

  const formatNumber = (v: string) => {
    // Удаляем все символы, кроме чисел
    const numericValue = v.replace(/\D/g, '');
    // Форматируем число, добавляя разделители тысяч
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setValue(formattedValue);
  };

  const formatValue = (v: number) =>
    // Форматируем значение с разделителем тысяч
    v.toLocaleString();

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid',
            borderColor: '#7551DC',
          }}
        >
          <Iconify icon="solar:arrow-left-outline" sx={{ color: '#7551DC',cursor: 'pointer' }} onClick={close} />
        </Box>
        <Typography>Loan Request</Typography>
      </Stack>
      <Stack
        gap={3}
        p={2}
        mt={2}
        sx={
          isMobile
            ? {
                maxHeight: 'calc(100vh - 70px)', // Ограничение высоты (регулируйте по своему дизайну)
                overflowY: 'auto', // Включаем вертикальную прокрутку
              }
            : {
              maxHeight: 'calc(100% - 50px)', // Ограничение высоты (регулируйте по своему дизайну)
              overflowY: 'auto', // Включаем вертикальную прокрутку
            }
        }
      >
        <Stack>
          <Typography sx={{ fontSize: '12px', color: 'grey', textAlign: 'justify' }}>
            Repayment will be automatically collected from debit card linked to your account
          </Typography>
        </Stack>
        <Stack>
          <Typography>Purpose of Loan</Typography>
          <TextField fullWidth sx={{ border: '1px solid black', borderRadius: '10px' }} />
        </Stack>
        <Stack>
          <Typography>Cardholder Name</Typography>
          <TextField fullWidth sx={{ border: '1px solid black', borderRadius: '10px' }} />
        </Stack>
        <Stack>
          <Typography>Billing address</Typography>
          <TextField
            select
            fullWidth
            value={activePeriod}
            onChange={handleChangePeriod}
            sx={{ border: '1px solid black', borderRadius: '10px' }}
          >
            {addresses.map((address, id) => (
              <MenuItem
                key={id}
                value={address}
                sx={{
                  mx: 1,
                  borderRadius: 0.75,
                }}
              >
                {address}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <FormControl component="fieldset">
          <Typography sx={{ fontWeight: 'bold', mb: 1 }}>Recents Card</Typography>
          <RadioGroup
            value={selectedValue}
            onChange={handleChangeRadio}
            name="bank-selection"
            sx={{ flexDirection: 'column' }} // Располагаем в столбец
          >
            <Stack gap={2}>
              <FormControlLabel
                value="mastercard"
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: '#7551DC', // Цвет активного Radio
                      },
                    }}
                  />
                }
                label={
                  <Stack direction="row" gap={2}>
                    <Image src="/assets/images/mastercards.png" sx={{ width: '40px' }} />
                    <Stack>
                      <Typography sx={{ fontWeight: 1000 }}>Mastercard</Typography>
                      <Typography sx={{ fontSize: '12px', color: 'grey' }}>
                        1254 **** **** 1293
                      </Typography>
                    </Stack>
                  </Stack>
                }
              />
              <FormControlLabel
                value="citybank"
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: '#7551DC', // Цвет активного Radio
                      },
                    }}
                  />
                }
                label={
                  <Stack direction="row" gap={2}>
                    <Image src="/assets/images/citi.png" sx={{ width: '40px' }} />
                    <Stack>
                      <Typography sx={{ fontWeight: 1000 }}>Citi Bank</Typography>
                      <Typography sx={{ fontSize: '12px', color: 'grey' }}>
                        4352 **** **** 6774
                      </Typography>
                    </Stack>
                  </Stack>
                }
              />
            </Stack>
          </RadioGroup>
        </FormControl>
        <Button
          variant="outlined"
          sx={{
            color: '#7551DC',
            border: '1px solid',
            borderColor: '#7551DC',
            borderRadius: '10px',
            '&:hover': {
              color: '#7551DC',
              border: '1px solid',
              borderColor: '#7551DC',
              borderRadius: '10px',
            },
          }}
          onClick={addCard.onTrue}
          startIcon={<Iconify icon="mdi:user-add-outline" />} // Добавляем иконку слева от текста
        >
          Add another card
        </Button>
        <Button
          sx={{
            bgcolor: '#7551DC',
            color: 'white',
            borderRadius: '10px',
            '&:hover': {
              bgcolor: '#7551DC',
              color: 'white',
              borderRadius: '10px',
            },
          }}
          onClick={documentAttach.onTrue}
        >
          Next
        </Button>
      </Stack>
      <Modal open={addCard.value} onClose={addCard.onFalse}>
        <AddCard close={addCard.onFalse} />
      </Modal>
      <Modal open={documentAttach.value} onClose={documentAttach.onFalse}>
        <DocumentAttach close={documentAttach.onFalse} loanRequestClose={loanRequestClose} loanCalculatorClose={loanCalculatorClose} recentCardsClose={close}/>
      </Modal>
    </Box>
  );
};
