'use client';

import { FC } from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import AvatarGroup from '@mui/material/AvatarGroup';
import CardContent from '@mui/material/CardContent';
import { Box, Badge, Typography } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { Share } from './share';
import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';
import { BlockType, ComponentTypeProps } from '../../../../types/page-generator';

type Props = {
  block?: BlockType;
  close?: () => void;
};

export const GroupInfo = ({ block, close }: Props) => {
  const router = useRouter();
  const isMobile = useResponsive('down', 'sm');
  const openShare = useBoolean();

  return (
    <Box
      sx={{
        background: 'linear-gradient(to right, #00BFFF, #8A2BE2)',
        mt: '-16px',
      }}
    >
      {/* Заголовок с кнопкой закрытия */}
      <Stack gap={1}>
        <Stack direction="row" alignItems="center" gap={2} justifyContent="space-between" p={2}>
          <Box
            sx={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'lightgrey',
            }}
            onClick={() => {
              if (close) {
                close();
              }
              // router.back();
            }}
          >
            <Iconify icon="oui:arrow-left" sx={{ color: 'white', cursor: 'pointer' }} />
          </Box>
          <Stack>
            <Typography sx={{ color: 'white', fontSize: '20px', fontWeight: 1000 }}>
              Bali Savings Group
            </Typography>
            <Typography sx={{ color: 'white', fontSize: '12px' }}>Saving for: Vacation</Typography>
          </Stack>
          <IconButton>
            <Badge
              max={99999}
              badgeContent={1}
              sx={{
                color: 'secondary.contrastText',
                '& .MuiBadge-badge': { bgcolor: 'red' },
              }}
            >
              <Iconify
                icon="jam:messages"
                sx={{ color: 'white', cursor: 'pointer', width: '30px', height: '30px' }}
              />
            </Badge>
          </IconButton>
        </Stack>
        <Stack alignItems="center">
          <Box
            sx={{
              position: 'relative',
              width: '150px',
              height: '150px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <svg width="150" height="150" viewBox="0 0 40 40">
              {/* Фон круга */}
              <circle cx="20" cy="20" r="15" fill="none" stroke="#d6d6d6" strokeWidth="2" />
              {/* Заполненный круг */}
              <circle
                cx="20"
                cy="20"
                r="15"
                fill="none"
                stroke="#7FFF00"
                strokeWidth="2"
                strokeDasharray="94.2" // Длина окружности
                strokeDashoffset={`${94.2 - (parseFloat('60%') / 100) * 94.2}`}
                strokeLinecap="round"
                transform="rotate(90 20 20)" // Поворот на 90 градусов
              />
            </svg>
            {/* Текст в центре */}
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px', // Увеличен шрифт для "$500"
                  fontWeight: 'bold',
                  color: 'white',
                }}
              >
                $500
              </Typography>
              <Typography
                sx={{
                  fontSize: '10px',
                  color: 'lightgrey',
                }}
              >
                in saving circle
              </Typography>
            </Box>
          </Box>
        </Stack>
        <Stack height={1}>
          <Box
            sx={{
              p: 2,
              // background: 'linear-gradient(to bottom, #86A8E7, #91EAE4)',
              background: 'white',
              borderTopRightRadius: '20px',
              borderTopLeftRadius: '20px',
              height: '100vh', // Установите фиксированную или относительную высоту
              overflowY: 'auto', // Вертикальный скролл
              overflowX: 'hidden', // Отключение горизонтального скролла
            }}
          >
            {/* Верхняя часть с аватарами */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 2,
              }}
            >
              <AvatarGroup
                max={6}
                sx={{
                  '.MuiAvatarGroup-avatar': {
                    '&:first-of-type': {
                      backgroundColor: 'white !important',
                      opacity: '1',
                      borderRadius: '50%',
                    },
                  },
                }}
              >
                {/* Первый аватар с бейджем "звездочка" */}
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        backgroundColor: 'gold',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 0 4px rgba(0, 0, 0, 0.3)',
                      }}
                    >
                      <Iconify icon="mdi:star" width={12} height={12} color="white" />
                    </Box>
                  }
                >
                  <Avatar src="/assets/images/managerAccount/profilePhoto.png" />
                </Badge>
                {/* Остальные аватары */}
                <Avatar src="/assets/images/specialists/specialists2.jpg" />
                <Avatar src="/assets/images/specialists/specialists3.jpg" />
                <Avatar src="/assets/images/specialists/specialists5.jpg" />
                <Avatar>
                  <IconButton
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                      background: 'lightgrey',
                    }}
                  >
                    <Iconify icon="ic:sharp-plus-one" />
                  </IconButton>
                </Avatar>
              </AvatarGroup>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#0047AB',
                  color: 'white',
                  fontSize: '12px',
                  px: 1,
                  borderRadius: '10px',
                  '&:hover': {
                    backgroundColor: '#0047AB',
                    color: 'white',
                    fontSize: '12px',
                    px: 1,
                    borderRadius: '10px',
                  },
                }}
                onClick={openShare.onTrue}
              >
                View Summary
              </Button>
            </Box>

            {/* Payments Left */}
            <Card
              sx={{
                border: '1px solid #2A68ED',
                textAlign: 'center',
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                mb: 1,
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Payments Left
                </Typography>
                <Typography variant="h3" color="primary" sx={{ fontWeight: 'bold' }}>
                  0
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  6 completed so far
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    backgroundColor: '#ADD8E6',
                    color: 'blue',
                    '&:hover': { backgroundColor: '#87CEEB' },
                  }}
                >
                  View Payment History
                </Button>
              </CardContent>
            </Card>

            {/* Информация о дате, частоте и выплате */}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card
                  sx={{
                    border: '1px solid #2A68ED',
                    backgroundColor: 'white',
                    borderRadius: 2,
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    p: 2,
                  }}
                >
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    Start Date
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'blue' }}>
                    Nov 10, 2019
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    Collection Date
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'blue' }}>
                    Nov 10, 2019
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  sx={{
                    border: '1px solid #2A68ED',
                    backgroundColor: 'white',
                    borderRadius: 2,
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    p: 2,
                  }}
                >
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    Frequency
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'blue' }}>
                    Monthly
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    Payout amount
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'blue' }}>
                    $500
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Stack>
      <Modal open={openShare.value} onClose={openShare.onFalse}>
         <Share close={openShare.onFalse} />
      </Modal>
    </Box>
  );
};
