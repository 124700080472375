'use client';

import { FC, useState, SetStateAction } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Box, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { useRouter } from 'src/routes/hooks';

import Iconify from '../../../../components/iconify';
import { useResponsive } from '../../../../hooks/use-responsive';
import { BlockType, ComponentTypeProps } from '../../../../types/page-generator';

type Props = {
  block?: BlockType;
  close?: () => void;
};

export const CreateGroup = ({ block, close }: Props) => {
  const router = useRouter();
  const isMobile = useResponsive('down', 'sm');

  const [groupType, setGroupType] = useState('private');
  const [amount, setAmount] = useState('');
  const [frequency, setFrequency] = useState('');
  const [savingFor, setSavingFor] = useState('');

  const handleGroupTypeChange = (event: any, newType: SetStateAction<string> | null) => {
    if (newType !== null) {
      setGroupType(newType);
    }
  };

  const handleIconClick = (item: SetStateAction<string>) => {
    setSavingFor(item);
  };

  const icons = [
    { label: 'Vacation', icon: '🏖️' },
    { label: 'Home', icon: '🏠' },
    { label: 'Business', icon: '💼' },
    { label: 'Education', icon: '📘' },
    { label: 'Gadget', icon: '💻' },
  ];

  return (
    <Box
      sx={{
        background: 'linear-gradient(to right, #00BFFF, #8A2BE2)',
        mt: '-16px',
      }}
    >
      <Stack gap={1} height={1}>
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          justifyContent="space-between"
          p={2}
          height="10%"
        >
          <Box
            sx={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: '#c3c1c1',
            }}
            onClick={() => {
              if (close) {
                close();
              }
              // router.back();
            }}
          >
            <Iconify icon="oui:arrow-left" sx={{ color: 'white', cursor: 'pointer' }} />
          </Box>
          <Stack sx={{p: 2}}>
            <Typography sx={{ color: 'white', fontSize: '16px', fontWeight: 1000 }}>
              New group
            </Typography>
          </Stack>
          <Box
            sx={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: '#c3c1c1',
            }}
            onClick={() => {
              if (close) {
                close();
              }
              // router.back();
            }}
          >
            <Iconify icon="bi:info" sx={{ color: 'white', cursor: 'pointer' }} />
          </Box>
        </Stack>
        <Stack
          sx={{
            background: 'white',
            borderTopRightRadius: '20px',
            borderTopLeftRadius: '20px',
            height: '90%',
          }}
        >
          <Box sx={{ py: 3, mx: 'auto' }}>
            {/* Toggle Button */}
            <ToggleButtonGroup
              value={groupType}
              exclusive
              onChange={handleGroupTypeChange}
              fullWidth
              sx={{
                mb: 3,
                '& .MuiToggleButton-root': {
                  border: '1px solid #ccc',
                  flex: 1,
                  textTransform: 'none',
                  borderRadius: '60px!important',
                },
                '& .Mui-selected': {
                  backgroundColor: '#7F7FD5!important', // Цвет активной кнопки
                  color: 'white', // Цвет текста на активной кнопке
                  border: '1px solid transparent!important', // Убираем границу
                  '&:hover': {
                    backgroundColor: '#6C6CBF!important', // Цвет при наведении на активную кнопку
                  },
                },
              }}
            >
              <ToggleButton value="private">
                <Stack direction="row" gap={1}>
                  <Iconify icon="f7:lock-fill" />
                  <Typography>Private</Typography>
                </Stack>
              </ToggleButton>
              <ToggleButton value="public">
                <Stack direction="row" gap={1}>
                  <Iconify icon="pepicons-pop:people" />
                  <Typography>Public</Typography>
                </Stack>
              </ToggleButton>
            </ToggleButtonGroup>

            {/* Title */}
            <Typography variant="body1" sx={{ mb: 1 }}>
              Title of Group
            </Typography>
            <TextField fullWidth variant="outlined" size="small" sx={{ mb: 3 }} />

            {/* Amount and Frequency */}
            <Grid
              container
              spacing={2}
              sx={{
                mb: 3,
              }}
            >
              <Grid item xs={6}>
                <Typography>Amount</Typography>
                <TextField
                  fullWidth
                  select
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '15px', // Закругляем input
                      backgroundColor: 'lightgrey', // Цвет фона
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #ccc', // Убираем стандартные стили рамки
                    },
                  }}
                >
                  <MenuItem value="$200">$200</MenuItem>
                  <MenuItem value="$500">$500</MenuItem>
                  <MenuItem value="$1000">$1000</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <Typography>Frequency</Typography>
                <TextField
                  fullWidth
                  select
                  value={frequency}
                  onChange={(e) => setFrequency(e.target.value)}
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '15px', // Закругляем input
                      backgroundColor: 'lightgrey', // Цвет фона
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #ccc', // Убираем стандартные стили рамки
                    },
                  }}
                >
                  <MenuItem value="Weekly">Weekly</MenuItem>
                  <MenuItem value="Bi-weekly">Bi-weekly</MenuItem>
                  <MenuItem value="Monthly">Monthly</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            {/* Icons */}
            <Typography variant="body1" sx={{ mb: 2, fontWeight: 1000 }}>
              What Are You Saving For?
            </Typography>
            <Stack
              width={1}
              direction="row"
              gap={1}
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                mb: 3,
                maxHeight: '250px', // Максимальная высота
                maxWidth: '90vw', // Максимальная ширина
                overflowY: 'auto', // Включаем вертикальную прокрутку
                scrollbarWidth: 'thin', // Для Firefox (узкий скролл)
                '&::-webkit-scrollbar': {
                  width: '8px', // Ширина скролла
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#ccc', // Цвет ползунка
                  borderRadius: '4px', // Закругление
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: '#999', // Цвет ползунка при наведении
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#f0f0f0', // Цвет трека
                },
              }}
            >
              {icons.map((item) => (
                <Box
                  key={item.label}
                  onClick={() => handleIconClick(item.label)}
                  sx={{
                    textAlign: 'center',
                    cursor: 'pointer',
                    // '&:hover': { transform: 'scale(1.1)' },
                  }}
                >
                  <Avatar
                    sx={{
                      // background: '#e0e0e0', // Оставляем фон для всех одинаковым
                      mb: 1,
                      width: 56,
                      height: 56,
                      background: savingFor === item.label ? '#c5e1a5' : '#e0e0e0',
                    }}
                  >
                    {item.icon}
                  </Avatar>
                  <Typography
                    variant="caption"
                    sx={{
                      color: savingFor === item.label ? 'blue' : 'black', // Меняем цвет текста
                      fontWeight: savingFor === item.label ? 'normal' : 'normal', // Дополнительно можно выделить жирным
                    }}
                  >
                    {item.label}
                  </Typography>
                </Box>
              ))}
            </Stack>

            {/* Submit Button */}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                py: 1.5,
                background: 'linear-gradient(to right, #7F7FD5, #86A8E7, #91EAE4)',
              }}
            >
              Create Group
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};
