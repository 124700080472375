'use client';

import { FC, useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import Stack from '@mui/material/Stack';
import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../components/iconify';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { Ib2bProduct } from '../../../../types/b2b';
import { AlternativeProductCarousel } from '../../../products/alternativeProductcarousel';
import { useGetProducts } from '../../../../api/product';
import { useResponsive } from '../../../../hooks/use-responsive';
import { useRouter } from '../../../../routes/hooks';
import { useLocales } from '../../../../locales';

export const ProductsCarousel: FC<ComponentTypeProps> = ({ block }) => {
  const { currentLang } = useLocales();
  const actionButtons: any = block?.actionButtons || [];
  const text: string = block?.text as string;
  const isMobile = useResponsive('down', 'sm');
  const router = useRouter();
  const sliderRef = useRef<Slider | null>(null);

  // Загрузка всех продуктов
  const { products } = useGetProducts({});

  // Состояние для локальных категорий
  const [localCategories, setLocalCategories] = useState<string[]>([]);

  // Фильтрация продуктов по категориям
  const enableCategoryFilter: boolean = block?.settings?.enableCategoryFilter;
  const { products: localProducts } = useGetProducts({ categoryIds: localCategories });
  const mapData = enableCategoryFilter ? localProducts : products || [];

  // Обновление локальных категорий при изменении настроек
  useEffect(() => {
    if (enableCategoryFilter) {
      setLocalCategories(block?.settings?.categories?.map((item: { value: string }) => item.value) || []);
    } else {
      setLocalCategories([]);
    }
  }, [enableCategoryFilter, block?.settings?.categories]);

  // Определяем количество отображаемых слайдов
  const maxSlides = block?.settings?.enableDouble ? 5 : 8;
  const slidesToShow = Math.min(mapData.length, maxSlides);

  // Кастомные стрелки
  const CustomPrevArrow = ({ onClick }: { onClick?: () => void }) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        right: '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
      }}
    >
      <Iconify icon={currentLang.value === 'he' ? "ep:arrow-left-bold" : "ep:arrow-right-bold"} color="rgba(14, 23, 50, 1)" />
    </Box>
  );

  const CustomNextArrow = ({ onClick }: { onClick?: () => void }) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        left: '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
      }}
    >
      <Iconify icon={currentLang.value === 'he' ? "ep:arrow-right-bold" : "ep:arrow-left-bold"} color="rgba(14, 23, 50, 1)" />
    </Box>
  );

  // Настройки слайдера
  const settings = {
    dots: false,
    infinite: mapData.length > slidesToShow, // Отключаем бесконечный скролл, если товаров мало
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    nextArrow: <CustomPrevArrow />,
    prevArrow: <CustomNextArrow />,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 600, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  if (!mapData.length) {
    return null; // Если нет данных, ничего не рендерим
  }

  return (
    <Box sx={{ '& .slick-track': { mt: '10px' } }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography sx={{ fontSize: '22px', fontWeight: 800 }}>{text}</Typography>
        {actionButtons.length > 0 && (
          <Stack direction="row" alignItems="center">
            <Typography sx={{ mr: 1, cursor: 'pointer' }} onClick={() => router.push(`${actionButtons[0].link}`)}>
              {actionButtons[0]?.label}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Divider sx={{ my: 2 }} />

      <Slider ref={sliderRef} {...settings}>
        {mapData.map((product: Ib2bProduct, id: number) => (
          <Box key={product.id || id} sx={{ px: 0.5 }}>
            <AlternativeProductCarousel block={block} product={product} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};
