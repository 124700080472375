import React, { useState } from 'react';

import Stack from '@mui/material/Stack';
import { Box, Button, Avatar, TextField, Typography } from '@mui/material';

import Iconify from '../../../../../components/iconify';
import { useResponsive } from '../../../../../hooks/use-responsive';

type Props = {
  close: () => void;
  amount: number;
};

const TransactionHistory = ({ close, amount }: Props) => {
  const isMobile = useResponsive('down', 'sm');

  const handleSave = () => {
    close();
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: !isMobile ? 400 : '100%',
        bgcolor: 'background.paper',
        border: '2px solid gray.500',
        borderRadius: !isMobile ? '10px' : '0px',
        boxShadow: 24,
        p: 4,
        pt: 1,
        height: !isMobile ? 'auto' : '100%',
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // border: '1px solid',
            // borderColor: 'grey',
          }}
        >
          <Iconify icon="fluent:ios-arrow-24-regular" sx={{ cursor: 'pointer' }} onClick={close} />
        </Box>
      </Stack>
      <Stack alignItems="center">
        <Typography sx={{ fontSize: '30px' }}>{amount} USD</Typography>
        <Typography sx={{ fontSize: '12px', color: 'grey' }}>Apr 10, 2023 at 11:34 AM</Typography>
        <Iconify
          icon="weui:done2-filled"
          sx={{ color: '#0dbcf7', width: '50px', height: '50px' }}
        />
      </Stack>
      <Stack>
        <Stack sx={{ justifyContent: 'space-between' }} direction="row">
          <Typography sx={{ color: 'grey' }}>Sent to</Typography>
          <Typography>Lucian Pennington</Typography>
        </Stack>
        <Stack sx={{ justifyContent: 'space-between' }} direction="row">
          <Typography sx={{ color: 'grey' }}>Card</Typography>
          <Typography>**** 4253</Typography>
        </Stack>
        <Stack sx={{ justifyContent: 'space-between' }} direction="row">
          <Typography sx={{ color: 'grey' }}>Amount</Typography>
          <Typography>136.00 USD</Typography>
        </Stack>
        <Stack sx={{ justifyContent: 'space-between' }} direction="row">
          <Typography sx={{ color: 'grey' }}>Fee</Typography>
          <Typography>1.8 USD</Typography>
        </Stack>
        <Stack sx={{ justifyContent: 'space-between' }} direction="row">
          <Typography sx={{ color: 'grey' }}>Residual balance</Typography>
          <Typography>4 863.27 USD</Typography>
        </Stack>
      </Stack>

      <Stack direction="row" gap={1}>
        <Button
          fullWidth
          sx={{ mt: 2, bgcolor: '#ffd9c3', color: 'white', borderRadius: '10px' }}
          onClick={handleSave}
        >
          Repeat transfer
        </Button>
        <Button
          fullWidth
          sx={{ mt: 2, bgcolor: 'black', color: 'white', borderRadius: '10px' }}
          onClick={handleSave}
        >
          Download PDF
        </Button>
      </Stack>
    </Box>
  );
};

export default TransactionHistory;
