'use client'

import { useSnackbar } from 'notistack';
// components
import { FC, memo, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {
  Modal,
  Divider,
  Container,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';

import Image from '../../../../../components/image';
import { useBoolean } from '../../../../../hooks/use-boolean';
import { ComponentTypeProps } from '../../../../../types/page-generator';
import {
  MERCHANTS,
  CURRENCIES,
} from '../../../../../constants/currencies';

interface Currency {
  value: string;
  label: string;
}

const Withdraw: FC<ComponentTypeProps> = ({ block }) => {
  const { enqueueSnackbar } = useSnackbar();
  const openModal = useBoolean();
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(
    CURRENCIES[0],
  );
  const [selectedCurrencyMerchant, setSelectedCurrencyMerchant] =
    useState<Currency>(MERCHANTS[0]);

  return (
    <Container maxWidth="sm">
      <Box>
        <Stack width={1} direction="row" gap={1}>
          <Image
            sx={{ width: '20px' }}
            src="/assets/icons/wallet/icon-wallet-light.svg"
          />
          <Typography>Withdraw</Typography>
        </Stack>
        <Box
          sx={{
            border: '1px solid',
            borderColor: '#80808066',
            borderRadius: '10px',
            p: 2,
          }}
        >
          <Stack width={1}>
            <Typography sx={{ color: 'grey', width: 1, fontSize: '12px' }}>
              ADDRESS
            </Typography>
            <TextField
              placeholder="Input or press and hold to paste the address"
              onChange={(e) => {
                const newMerchant = MERCHANTS.find(
                  (m) => m.value === e.target.value,
                );
                if (newMerchant) setSelectedCurrencyMerchant(newMerchant);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Image
                      src="/assets/crypto/qr.png" // Замените на свою картинку
                      alt="icon"
                      sx={{
                        width: '24px',
                        height: '24px',
                      }}
                      style={{ cursor: 'pointer' }}
                      onClick={() => openModal.onTrue()}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'grey' },
                  '&:hover fieldset': { borderColor: 'grey' },
                  '&.Mui-focused fieldset': { borderColor: 'grey' },
                },
                '& .MuiSvgIcon-root': { color: 'grey' },
                '& .MuiInputBase-root': {
                  color: 'black',
                },
              }}
            />
            <Typography sx={{ color: 'grey', width: 1, fontSize: '12px' }}>
              AMOUNT
            </Typography>
            <Stack direction="row" width={1} justifyContent="space-between">
              <TextField
                placeholder="Min. amount: 0.001"
                sx={{ width: '80%' }}
              />
              <TextField
                select
                value={selectedCurrency.value}
                onChange={(e) => {
                  const newCurrency = CURRENCIES.find(
                    (c) => c.value === e.target.value,
                  );
                  if (newCurrency) setSelectedCurrencyMerchant(newCurrency);
                }}
                SelectProps={{ sx: { color: 'grey' } }}
                sx={{
                  // '& .MuiOutlinedInput-root': {
                  //   '& fieldset': { borderColor: 'grey', border: 'none' },
                  //   '&:hover fieldset': { borderColor: 'grey' },
                  //   '&.Mui-focused fieldset': { borderColor: 'grey' },
                  // },
                  '& .MuiSvgIcon-root': { color: 'grey' },
                }}
              >
                {CURRENCIES.map((currency) => (
                  <MenuItem key={currency.value} value={currency.value}>
                    {currency.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack gap={1} my={2}>
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ color: 'grey', fontSize: '12px' }}>
                  Fee:
                </Typography>
                <Typography sx={{ fontSize: '12px' }}>1.2%</Typography>
              </Stack>
              <Divider sx={{ width: '100%', color: 'grey' }} />
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ color: 'grey', fontSize: '12px' }}>
                  Amount received:
                </Typography>
                <Stack direction="row" gap={1}>
                  <Typography sx={{ fontSize: '12px' }}>0</Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                    {selectedCurrency.label}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Typography sx={{ color: 'grey', fontSize: '12px' }}>
              Justo donec enim diam vulputate ut pharetra. Ut placeratorci nulla
              pellen. Nulla pellentesque dignissim enim sit.
            </Typography>
            <Button
              sx={{ width: 1, background: 'black', my: 2 }}
              variant="contained"
            >
              Withdraw
            </Button>
          </Stack>
        </Box>
      </Box>
      <Modal open={openModal.value} onClose={() => openModal.onFalse()}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid gray.500',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack gap={1}>
            <Typography sx={{ fontSize: '26px', fontWeight: 1000 }}>
              Invite Friends
            </Typography>
            <Typography sx={{ textAlign: 'center' }}>
              Send it to your friend or show them the QR code
            </Typography>
            <Image src="/assets/crypto/qr.png" />
            <Button
              sx={{
                bgcolor: '#e0f64b',
                '&:hover': { bgcolor: '#e0f64b' },
              }}
              onClick={() => openModal.onFalse()}
            >
              Share
            </Button>
            <Button
              sx={{ bgcolor: 'lightgrey', '&:hover': { bgcolor: 'lightgrey' } }}
              onClick={() => openModal.onFalse()}
            >
              Download
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Container>
  );
};
export default memo(Withdraw);
