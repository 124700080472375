import React, { useState } from 'react';

import Stack from '@mui/material/Stack';
import { Box, Modal, Typography } from '@mui/material';

import MobilePayment from './mobile-payment';
import IBANPaymentForm from './IBAN-payment';
import Iconify from '../../../../../components/iconify';
import { BlockType } from '../../../../../types/page-generator';
import { useResponsive } from '../../../../../hooks/use-responsive';

type Props = {
  close: () => void;
  block: BlockType | undefined;
};

const Payments = ({ close, block }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const pages = [
    {
      id: 1,
      label: 'Money transfer',
      icon: 'hugeicons:transaction',
      modal: 'Money',
      show: block?.settings?.enableMoneyTransfer,
    },
    {
      id: 2,
      label: 'Mobile payment',
      icon: 'humbleicons:mobile',
      modal: 'Mobile',
      show: block?.settings?.enableMobilePayment,
    },
    {
      id: 3,
      label: 'IBAN payment',
      icon: 'healthicons:money-bag-outline',
      modal: 'IBAN',
      show: block?.settings?.enableIbanPayment,
    },
    {
      id: 4,
      label: 'Utility bills',
      icon: 'majesticons:scroll-text-line',
      modal: 'Utility',
      show: block?.settings?.enableUtilityBills,
    },
    {
      id: 5,
      label: 'Transport',
      icon: 'lucide:car',
      modal: 'Transport',
      show: block?.settings?.enableTransport,
    },
    {
      id: 6,
      label: 'Insurance',
      icon: 'akar-icons:umbrella',
      modal: 'Insurance',
      show: block?.settings?.enableInsurance,
    },
    {
      id: 7,
      label: 'Penalties',
      icon: 'solar:document-outline',
      modal: 'Penalties',
      show: block?.settings?.enablePenalties,
    },
    {
      id: 8,
      label: 'Charity',
      icon: 'bi:suit-heart',
      modal: 'Charity',
      show: block?.settings?.enableCharity,
    },
  ];

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: !isMobile ? 400 : '100%',
        bgcolor: 'background.paper',
        border: '2px solid gray.500',
        borderRadius: !isMobile ? '10px' : '0px',
        boxShadow: 24,
        p: 4,
        pt: 1,
        height: !isMobile ? 'auto' : '100%',
      }}
    >
      <Stack direction="row" alignItems="center" gap={2} mb={1}>
        <Iconify icon="fluent:ios-arrow-24-regular" sx={{ cursor: 'pointer' }} onClick={close} />
        <Typography>Payments</Typography>
      </Stack>

      <Stack gap={2}>
        {pages.map((item) => (
          <Stack
            key={item.id}
            onClick={() => setActiveModal(item.modal)}
            sx={{
              cursor: 'pointer',
              p: 1,
              borderRadius: '8px',
              display: item?.show ? 'block' : 'none',
              '&:hover': { bgcolor: '#f5f5f5' },
            }}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" gap={1}>
                <Iconify icon={item.icon} />
                <Typography sx={{ fontSize: '14px' }}>{item.label}</Typography>
              </Stack>
              <Iconify icon="ic:outline-info" sx={{ color: 'grey' }} />
            </Stack>
          </Stack>
        ))}
      </Stack>

      {/* Универсальная модалка */}
      <Modal open={!!activeModal} onClose={() => setActiveModal(null)}>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '100%' : '50%',
            height: isMobile ? '100%' : '50%',
            bgcolor: 'background.paper',
            p: 2,
            borderRadius: isMobile ? '' : '10px',
          }}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {activeModal === 'Mobile' ? (
            <MobilePayment close={() => setActiveModal(null)} />
          ) : activeModal === 'IBAN' ? (
            <IBANPaymentForm close={() => setActiveModal(null)} />
          ) : (
            <Stack>
              <Stack direction="row" alignItems="center" gap={2}>
                <Iconify
                  icon="fluent:ios-arrow-24-regular"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setActiveModal(null)}
                />
              </Stack>
              <Typography variant="h6">{activeModal} Payment</Typography>
              <Typography>Modal content for {activeModal}.</Typography>
            </Stack>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default Payments;
