'use client';

import { FC, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Player from '@vimeo/player';
import { ComponentTypeProps } from '../../../../types/page-generator';

export const VimeoIframe: FC<ComponentTypeProps> = ({ block }) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const url = block?.url as string;

  // Извлекаем ID видео из URL, если передан полный адрес
  const extractedId = block?.url ? new URL(url).pathname.split('/').pop() : null;
  const videoId = block?.videoId ?? (extractedId ? Number(extractedId) : undefined);

  useEffect(() => {
    if (playerRef.current && typeof videoId === 'number') {
      const player = new Player(playerRef.current, {
        id: videoId,
        autoplay: true,
        muted: true,
        loop: true,
        background: true,
      });

      return () => {
        player.destroy();
      };
    }
    return undefined; // 🔹 Исправление для ESLint (consistent-return)
  }, [videoId]);

  return (
    <Box
      sx={
        block?.settings?.fullSize
          ? {
              top: 0,
              left: 0,
              width: '100%', // 🔹 Вся ширина экрана
              height: '100vh', // 🔹 Вся высота экрана
              overflow: 'hidden',
              '& iframe': {
                width: '100%',
                height: '100%',
              },
            }
          : {
              position: 'relative',
              width: url ? '100%' : 'auto',
              paddingTop: url ? '56.25%' : '0%',
              margin: 'auto',
              overflow: 'hidden',
              borderRadius: '8px',
              height: url ? '100%' : 'auto',
              '& iframe': {
                width: '100%',
                height: '100%',
              },
            }
      }
    >
      <div
        ref={playerRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: url ? '100%' : 'auto',
          height: url ? '100%' : 'auto',
        }}
      />
    </Box>
  );
};
