import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Box, Typography } from '@mui/material';

import { LoanProgress } from './loan-progress';
import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';

type Props = {
  close: () => void;
  loanRequestClose: () => void;
  loanCalculatorClose: () => void;
  recentCardsClose: () => void;
  documentAttachClose: () => void;
  loanSummaryClose: () => void;
  enterPinClose: () => void;
};

export const SuccessPage = ({ close,loanRequestClose, loanCalculatorClose, recentCardsClose, documentAttachClose, loanSummaryClose, enterPinClose }: Props) => {
  const loanProgress = useBoolean();
  const isMobile = useResponsive('down', 'sm');

  return (
    <Box
      sx={{
        // position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        bgcolor: '#7551DC',
        p: 2,
        borderRadius: 2,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Прорези слева */}
      <Box
        sx={{
          position: 'absolute',
          top: '51%',
          left: 0,
          transform: 'translateY(-50%)',
          width: 30,
          height: 40,
          borderRadius: '50%',
          bgcolor: '#7551DC',
        }}
      />
      {/* Прорези справа */}
      <Box
        sx={{
          position: 'absolute',
          top: '51%',
          right: 0,
          transform: 'translateY(-50%)',
          width: 30,
          height: 40,
          borderRadius: '50%',
          bgcolor: '#7551DC',
        }}
      />

      {/* Контент */}
      <Stack gap={1}>
        <Stack direction="row" alignItems="center" gap={2} justifyContent="space-between">
          <Box
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid',
              borderColor: '#7551DC',
              bgcolor: 'background.paper',
            }}
          >
            <Iconify icon="solar:arrow-left-outline" sx={{ color: '#7551DC',cursor: 'pointer' }} onClick={close} />
          </Box>
          <Box
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid',
              borderColor: '#7551DC',
              bgcolor: 'background.paper',
            }}
          >
            <Iconify icon="solar:download-linear" sx={{ color: '#7551DC' }} onClick={close} />
          </Box>
        </Stack>

        <Box
          sx={{
            my: 5,
            bgcolor: 'white', // Цвет фона карточки
            borderRadius: 3,
            p: 2,
            textAlign: 'center',
            boxShadow: 3,
          }}
        >
          {/* Иконка с подтверждением */}
          <Box
            sx={{
              width: 80,
              height: 80,
              mx: 'auto',
              mb: 2,
              borderRadius: '50%',
              bgcolor: '#ece2fc',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Iconify
              icon="lets-icons:done-round"
              sx={{ width: 40, height: 40, color: '#7551DC' }}
            />
          </Box>

          {/* Заголовок */}
          <Typography sx={{ fontWeight: 1000, color: '#000000', mb: 1, fontSize: '20px' }}>
            Successful
          </Typography>

          {/* Подзаголовок */}
          <Typography sx={{ color: '#757575', mb: 1, fontSize: '12px' }}>
            Your loan request has been submitted, please check regularly through this application.
          </Typography>

          {/* Дата */}
          <Typography variant="caption" sx={{ color: '#929CBD' }}>
            22 Jul, 2023 11:12 AM
          </Typography>

          <Divider
            sx={{
              my: 2, // Отступы сверху и снизу
              borderStyle: 'dashed', // Пунктирный стиль
            }}
          />

          <Typography sx={{ textAlign: 'start', color: '#757575' }}>Request loan for</Typography>
          {/* Блок запроса займа */}
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{
              bgcolor: '#f8f4fd',
              p: 1.5,
              borderRadius: 2,
              mb: 2,
            }}
          >
            <Box
              sx={{
                width: 40,
                height: 40,
                borderRadius: 1,
                bgcolor: '#ece2fc',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Iconify icon="ph:student-light" sx={{ fontSize: 24, color: '#7551DC' }} />
            </Box>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 600, color: '#000' }}>
                Student Loan
              </Typography>
              <Typography variant="caption" sx={{ color: '#757575' }}>
                for period July 2023
              </Typography>
            </Box>
          </Stack>

          {/* Сумма займа */}
          <Typography variant="body2" sx={{ color: '#9e9e9e', mb: 0.5 }}>
            Total Loan
          </Typography>
          <Typography variant="h2" sx={{ fontWeight: 1000, color: '#7551DC' }}>
            $1.000
          </Typography>
        </Box>

        <Button
          sx={{
            bgcolor: '#FFD140',
            color: 'white',
            borderRadius: '10px',
            '&:hover': {
              bgcolor: '#FFD140',
              color: 'white',
              borderRadius: '10px',
            },
          }}
          onClick={loanProgress.onTrue}
        >
          Continue
        </Button>
      </Stack>
      <Modal open={loanProgress.value} onClose={loanProgress.onFalse}>
        <LoanProgress close={loanProgress.onFalse} loanRequestClose={loanRequestClose} loanCalculatorClose={loanCalculatorClose} recentCardsClose={recentCardsClose} documentAttachClose={documentAttachClose} loanSummaryClose={loanSummaryClose} enterPinClose={enterPinClose} successPageClose={close}/>
      </Modal>
    </Box>
  );
};
