const author = {
  name: 'Dan Tourlan',
  avatar: '/assets/crypto/nft/profile/avatar.webp',
  items: [
    {
      id: 'item-1',
      name: 'Oh My Deer',
      image: '/assets/crypto/nft/profile/item1.webp',
      price: 29.98,
      collection: 'Surreal World',
      isListed: true,
    },
    {
      id: 'item-2',
      name: 'Nyan Cat',
      image: '/assets/crypto/nft/profile/item2.webp',
      price: 14.22,
      collection: 'Nyan Cat',
      isListed: false,
    },
    {
      id: 'item-3',
      name: 'Otherworldly',
      image: '/assets/crypto/nft/profile/item3.webp',
      price: 17.34,
      collection: 'Surreal World',
      isListed: false,
    },
    {
      id: 'item-4',
      name: 'Underwater',
      image: '/assets/crypto/nft/profile/item4.webp',
      price: 9.99,
      collection: 'Pacific Ocean',
      isListed: false,
    },
    {
      id: 'item-5',
      name: 'Hypocrisy',
      image: '/assets/crypto/nft/profile/item5.webp',
      price: 43.19,
      collection: 'My Little Dollhouse',
      isListed: false,
    },
    {
      id: 'item-6',
      name: 'The Last Sunset',
      image: '/assets/crypto/nft/profile/item6.webp',
      price: 3.5,
      collection: 'Vaporwave',
      isListed: false,
    },
    {
      id: 'item-7',
      name: 'Time Travel',
      image: '/assets/crypto/nft/profile/item7.webp',
      price: 33.02,
      collection: 'Geometry',
      isListed: false,
    },
    {
      id: 'item-8',
      name: 'Meditation',
      image: '/assets/crypto/nft/profile/item8.webp',
      price: 39.99,
      collection: 'Nature Calls',
      isListed: false,
    },
    {
      id: 'item-9',
      name: 'Brainstorm',
      image: '/assets/crypto/nft/profile/item9.webp',
      price: 21.16,
      collection: 'Geometry',
      isListed: false,
    },
    {
      id: 'item-10',
      name: 'Cooldown',
      image: '/assets/crypto/nft/profile/item10.webp',
      price: 3.5,
      collection: 'Pacific Ocean',
      isListed: false,
    },
  ],
  collections: [
    {
      id: 'collection-1',
      name: 'Rainbow Road',
      image: '/assets/crypto/nft/profile/collection1.webp',
      description: 'Fabulous collection of beautiful 10 NFTs',
      avatar: '/assets/crypto/nft/profile/avatar.webp',
    },
    {
      id: 'collection-2',
      name: 'Unicorns are Real',
      image: '/assets/crypto/nft/profile/collection2.webp',
      description: 'Luxury collection of 70 stunning NFTs',
      avatar: '/assets/crypto/nft/profile/avatar.webp',
    },
    {
      id: 'collection-3',
      name: 'The Perfectionist',
      image: '/assets/crypto/nft/profile/collection3.webp',
      description: 'Amazing collection of 22 mesmerizing NFTs',
      avatar: '/assets/crypto/nft/profile/avatar.webp',
    },
    {
      id: 'collection-4',
      name: 'The Purple Haze',
      image: '/assets/crypto/nft/profile/collection4.webp',
      description: 'Wonderful collection of 125 exclusive NFTs',
      avatar: '/assets/crypto/nft/profile/avatar.webp',
    },
    {
      id: 'collection-5',
      name: 'Brain Dance',
      image: '/assets/crypto/nft/profile/collection5.webp',
      description: 'Hyped collection of 10 futuristic NFTs',
      avatar: '/assets/crypto/nft/profile/avatar.webp',
    },
    {
      id: 'collection-6',
      name: 'Simplicity',
      image: '/assets/crypto/nft/profile/collection6.webp',
      description: 'Minimal collection of 23 simple NFTs',
      avatar: '/assets/crypto/nft/profile/avatar.webp',
    },
    {
      id: 'collection-7',
      name: 'The Naturalist',
      image: '/assets/crypto/nft/profile/collection7.webp',
      description: 'Hyped collection of 97 beautiful NFTs',
      avatar: '/assets/crypto/nft/profile/avatar.webp',
    },
    {
      id: 'collection-8',
      name: 'Living on the Edge',
      image: '/assets/crypto/nft/profile/collection8.webp',
      description: 'Dream collection of 56 memorable NFTs',
      avatar: '/assets/crypto/nft/profile/avatar.webp',
    },
    {
      id: 'collection-9',
      name: 'Sahara Vacation',
      image: '/assets/crypto/nft/profile/collection9.webp',
      description: 'Essential collection of 24 inspiring NFTs',
      avatar: '/assets/crypto/nft/profile/avatar.webp',
    },
    {
      id: 'collection-10',
      name: 'Molecular',
      image: '/assets/crypto/nft/profile/collection10.webp',
      description: 'Science collection of 10 breathtaking NFTs',
      avatar: '/assets/crypto/nft/profile/avatar.webp',
    },
  ],
};

export default author;
