'use client'

import React, { FC, useState } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import {
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  Container,
  Typography,
} from '@mui/material';

import Image from '../../../../../components/image';
import { ComponentTypeProps } from '../../../../../types/page-generator';

const StakingComponent: FC<ComponentTypeProps> = ({ block }) => {
  const [amount, setAmount] = useState(50);
  const [plan, setPlan] = useState(365);
  const [fromCurrency, setFromCurrency] = useState('USD');

  const plans = [
    { days: 30, rate: 1.7 },
    { days: 90, rate: 6 },
    { days: 180, rate: 13 },
    { days: 365, rate: 30 },
  ];

  return (
    <Container maxWidth="sm">
      <Stack width={1} direction="row" gap={1}>
        <Image
          sx={{ width: '20px' }}
          src="/assets/icons/wallet/icon-stacking-light.svg"
        />
        <Typography>Stacking</Typography>
      </Stack>
      <Box
        sx={{
          p: 3,
          border: '1px solid #ddd',
          borderRadius: 2,
          mx: 'auto',
        }}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                type="number"
                value={amount}
                label="AMOUNT"
                onChange={(e) => setAmount(+e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <Select
                value={fromCurrency}
                onChange={(e) => setFromCurrency(e.target.value)}
              >
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="EUR">EUR</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ fontSize: '10px' }}>Min. amount: 50 BTC</Typography>
          <Typography sx={{ fontSize: '10px' }}>
            Max. amount: 600000 BTC
          </Typography>
        </Stack>

        <Typography variant="h6" sx={{ mt: 2 }}>
          Choose Plan
        </Typography>
        <Stack
          sx={{ my: 1, justifyContent: 'space-between', width: '100%' }}
          direction="row"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {plans.map(({ days, rate }) => (
            <Button
              key={days}
              onClick={() => setPlan(days)}
              sx={{
                width: '49%',
                p: 2,
                border: '1px solid #ddd',
                borderRadius: 1,
                textAlign: 'center',
                cursor: 'pointer',
                bgcolor: plan === days ? 'yellowgreen' : 'transparent',
                color: plan === days ? 'white' : 'black',
                '&:hover': { bgcolor: 'lightgray' },
                my: 0.5,
              }}
            >
              <Stack>
                <Typography fontWeight="bold">{days} Days</Typography>
                <Typography>{rate}% Rate</Typography>
              </Stack>
            </Button>
            // <Grid
            //   key={days}
            //   item
            //   xs={6} // Вместо 5.5
            //   onClick={() => setPlan(days)}
            //   sx={{
            //     p: 2,
            //     border: '1px solid #ddd',
            //     borderRadius: 1,
            //     textAlign: 'center',
            //     cursor: 'pointer',
            //     bgcolor: plan === days ? 'yellowgreen' : 'transparent',
            //     color: plan === days ? 'white' : 'black',
            //     '&:hover': { bgcolor: 'lightgray' },
            //   }}
            // >
            //   <Typography fontWeight="bold">{days} Days</Typography>
            //   <Typography>{rate}% Rate</Typography>
            // </Grid>
          ))}
        </Stack>

        <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
          <Typography variant="h6">Amount Received:</Typography>
          <Typography variant="body1" color="green">
            +{' '}
            {(
              (amount * (plans.find((p) => p.days === plan)?.rate || 0)) /
              100
            ).toFixed(2)}{' '}
            {fromCurrency}
          </Typography>
        </Stack>
        <Button variant="contained" fullWidth sx={{ mt: 2, bgcolor: 'black' }}>
          Stack
        </Button>
      </Box>
    </Container>
  );
};
export default StakingComponent;
