import React from 'react';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';
import SettingsModal from '../wallet-crypto/modal/setiings-modal';

type Props = {
  close: () => void;
  card:
    | {
        id: number;
        title: string;
        description: string;
        amount: string;
        percent: string;
        payOfDate: string;
        loanActive: string;
        cardType: string; // Тип карты
        merchant: string; // Продавец
        activity: string; // Активность
        images: { file: File | string; preview: string }[];
      }
    | any;
};

export const CardInfo = ({ close, card }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const settingsModal = useBoolean();
  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // border: '1px solid',
            // borderColor: 'grey',
          }}
        >
          <Iconify icon="fluent:ios-arrow-24-regular" sx={{ cursor: 'pointer' }} onClick={close} />
        </Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width={1}>
          <Typography>Card info</Typography>
          <IconButton onClick={() => settingsModal.onTrue()}>
            <Iconify icon="material-symbols:settings-rounded" />
          </IconButton>
        </Stack>
      </Stack>
      <Stack width={1} alignItems="center">
        {!!card?.images.length && (
          <Image
            src={card?.images[0]?.preview}
            sx={{ width: '270px', mt: 2, borderRadius: '10px' }}
          />
        )}
        <Grid container spacing={1} mt={2}>
          <Grid item xs={6}>
            <Typography>Title</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{card.title}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Description</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{card.description}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Amount</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{card.amount}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Type</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{card.cardType}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Merchant</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{card.merchant}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Activity</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{card.activity}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Terms and Conditions</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Some conditions</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Bonuses</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>5% on replenishment</Typography>
          </Grid>
        </Grid>
      </Stack>
      <Modal open={settingsModal.value} onClose={() => settingsModal.onFalse}>
        <SettingsModal close={() => settingsModal.onFalse()}/>
      </Modal>
    </Box>
  );
};
