import { useState } from 'react';

import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import { Box, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';
import { RecentCards } from './recent-cards';

type Props = {
  close: () => void;
  loanRequestClose: () => void;
};

export const LoanCalculator = ({ close, loanRequestClose }: Props) => {
  const recentCards = useBoolean();
  const isMobile = useResponsive('down', 'sm');
  const periods = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [activePeriod, setActivePeriod] = useState(12);
  const [value, setValue] = useState<string>('');
  const marks = [
    {
      value: 345,
      label: '$ 345',
    },
    {
      value: 10000,
      label: '$ 10.000',
    },
  ];

  const handleChangePeriod = (event: React.ChangeEvent<{ value: unknown }>) => {
    // @ts-ignore
    setActivePeriod(event.target.value as string);
  };

  const formatNumber = (v: string) => {
    // Удаляем все символы, кроме чисел
    const numericValue = v.replace(/\D/g, '');
    // Форматируем число, добавляя разделители тысяч
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setValue(formattedValue);
  };

  const formatValue = (v: number) =>
    // Форматируем значение с разделителем тысяч
    v.toLocaleString();

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid',
            borderColor: '#7551DC',
          }}
        >
          <Iconify icon="solar:arrow-left-outline" sx={{ color: '#7551DC',cursor: 'pointer' }} onClick={close} />
        </Box>
        <Typography>Loan Calculator</Typography>
      </Stack>
      <Stack
        gap={3}
        p={2}
        mt={2}
        sx={
          isMobile
            ? {
                maxHeight: 'calc(100vh - 70px)', // Ограничение высоты (регулируйте по своему дизайну)
                overflowY: 'auto', // Включаем вертикальную прокрутку
              }
            : {
              maxHeight: 'calc(100% - 70px)', // Ограничение высоты (регулируйте по своему дизайну)
              overflowY: 'auto', // Включаем вертикальную прокрутку
            }
        }
      >
        <Stack>
          <Typography sx={{ fontWeight: 1000, fontSize: '18px' }}>Choose loan value</Typography>
          <Typography sx={{ fontSize: '12px', color: 'grey', textAlign: 'justify' }}>
            The calculated amount is approximate. the exact tearms of the loan are determinated
            individually
          </Typography>
        </Stack>
        <Stack>
          <Typography>Loan Name</Typography>
          <TextField fullWidth sx={{ border: '1px solid black', borderRadius: '10px' }} />
        </Stack>
        <Stack>
          <Typography>Period</Typography>
          <TextField
            select
            fullWidth
            value={activePeriod}
            onChange={handleChangePeriod}
            sx={{ border: '1px solid black', borderRadius: '10px' }}
          >
            {periods.map((period, id) => (
              <MenuItem
                key={id}
                value={period}
                sx={{
                  mx: 1,
                  borderRadius: 0.75,
                }}
              >
                {period} Month
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack>
          <Typography>How much money you need?</Typography>
          <TextField
            fullWidth
            value={value}
            onChange={handleChange}
            placeholder="Введите сумму"
            sx={{
              border: '1px solid black',
              borderRadius: '10px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px', // Чтобы TextField и его внутренности имели одинаковые углы
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="bx:dollar" sx={{ color: 'black' }} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Slider
          defaultValue={1000} // Начальное значение
          min={345} // Минимум
          max={10000} // Максимум
          marks={marks} // Метки для начала и конца
          step={1} // Шаг изменения значения
          aria-label="Custom Slider"
          valueLabelDisplay="auto" // Отображение текущего значения
          valueLabelFormat={formatValue} // Форматирование значения
          sx={{
            width: '100%', // Ширина ползунка
            '& .MuiSlider-thumb': {
              bgcolor: 'white', // Цвет "кружка" ползунка
              border: '1px solid grey',
              '&:hover': {
                boxShadow: '0px 0px 8px rgba(117, 81, 220, 0.5)', // Эффект при наведении
              },
            },
            '& .MuiSlider-track': {
              bgcolor: '#9A82E4', // Цвет заполненной части
            },
            '& .MuiSlider-rail': {
              bgcolor: 'grey', // Цвет незаполненной части
            },
            '& .MuiSlider-mark': {
              bgcolor: 'grey', // Цвет меток
              // height: 6, // Высота меток
              // width: 6, // Ширина меток
              borderRadius: '50%', // Круглая форма меток
            },
            '& .MuiSlider-markLabel': {
              fontSize: '12px', // Размер текста меток
              color: 'black', // Цвет текста меток
              fontWeight: 1000,
            },
          }}
        />
        <Stack
          alignItems="center"
          sx={{
            bgcolor: '#E8DDFD',
            border: '2px dashed',
            borderColor: '#9A82E4',
            borderRadius: '10px',
            p: 2,
          }}
        >
          <Typography sx={{ color: '#0070E1', fontSize: '20px' }}>Monthly Payment</Typography>
          <Typography sx={{ color: '#0070E1', fontSize: '20px', fontWeight: 1000 }}>
            $ 170
          </Typography>
          <Typography sx={{ fontWeight: 1000, fontSize: '10px', color: 'red' }}>
            Interest 2,5%
          </Typography>
        </Stack>
        <Button sx={{
          bgcolor: '#7551DC',
          color: 'white',
          borderRadius: '10px',
          "&:hover" : {
            bgcolor: '#7551DC',
            color: 'white',
            borderRadius: '10px',
          }
        }} onClick={recentCards.onTrue}>Next</Button>
      </Stack>
      <Modal open={recentCards.value} onClose={recentCards.onFalse}>
        <RecentCards close={recentCards.onFalse} loanRequestClose={loanRequestClose} loanCalculatorClose={close}/>
      </Modal>
    </Box>
  );
};
