import React from 'react';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

// eslint-disable-next-line import/no-cycle
import { Loan } from './view-more';
import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';
import { RecentCards } from '../../../wallet/components/pages/recent-cards';
import { RecentCardsPayment } from '../../../wallet/components/pages/recent-cards-payment';

type Props = {
  loan: Loan | undefined;
};

export const LoanInfoPayment = ({ loan }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const infoPayment = useBoolean();
  const payOff = useBoolean();

  return (
    <Box onClick={infoPayment.onTrue}>
      <Grid container mt={2}>
        <Grid item xs={10} md={10} sx={{ display: 'flex', alignItems: 'center' }}>
          <Stack direction="row" width={1} justifyContent="space-between">
            <Stack direction="row" gap={1} alignItems="center">
              <Box
                sx={{
                  width: '50px',
                  height: '50px',
                  background: '#E8DDFD',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Iconify
                  icon={loan?.icon || ''}
                  sx={{
                    width: '40px',
                    height: '40px',
                    color: '#7551DC',
                  }}
                />
              </Box>
              <Stack>
                <Typography sx={{ fontSize: '14px', fontWeight: 1000 }}>{loan?.name}</Typography>
                <Typography sx={{ fontSize: '12px', color: 'grey' }}>Active</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={2} md={2}>
          <Box sx={{ position: 'relative', width: '60px', height: '60px' }}>
            <svg width="60" height="60" viewBox="0 0 40 40">
              {/* Фон круга */}
              <circle cx="20" cy="20" r="15" fill="none" stroke="#d6d6d6" strokeWidth="3" />
              {/* Заполненный круг */}
              <circle
                cx="20"
                cy="20"
                r="15"
                fill="none"
                stroke="#7551DC"
                strokeWidth="3"
                strokeDasharray="94.2" // Длина окружности
                strokeDashoffset={`${94.2 - (parseFloat(loan?.percent || '') / 100) * 94.2}`}
                strokeLinecap="round"
                transform="rotate(90 20 20)" // Поворот на 90 градусов
              />
            </svg>
            {/* Текст в центре */}
            <Typography
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '10px',
                // fontWeight: 'bold',
              }}
            >
              {loan?.percent}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ width: '100%' }} />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Stack>
            <Typography sx={{ fontWeight: 1000 }}>Mastercard</Typography>
            <Typography sx={{ fontSize: '12px', color: 'grey' }}>1254 **** **** 1293</Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ textAlign: 'end', fontWeight: 'bold' }}>${loan?.balance}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: 'grey' }}>Reference number</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: 'end', fontWeight: 'bold' }}>AE1223LI</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: 'grey' }}>Payment Date</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: 'end', fontWeight: 'bold' }}>{loan?.date}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: 'grey' }}>Period</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: 'end', fontWeight: 'bold' }}>{loan?.period}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: 'grey' }}>Payment Amount</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: 'end', fontWeight: 'bold' }}>${loan?.sum}</Typography>
        </Grid>
        <Stack width={1} alignItems="center">
          <Button sx={{ bgcolor: '#7551DC', color: 'white', width: '100%', mt: 2 }}>Pay off</Button>
        </Stack>
        <Modal open={infoPayment.value} onClose={infoPayment.onFalse}>
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: isMobile ? '100%' : '50%',
              height: isMobile ? '100%' : '50%',
              bgcolor: 'background.paper',
              p: 2,
              borderRadius: isMobile ? '' : '10px',
            }}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid',
                  borderColor: '#7551DC',
                }}
              >
                <Iconify
                  icon="solar:arrow-left-outline"
                  sx={{ color: '#7551DC', cursor: 'pointer', zIndex: 10 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    infoPayment.onFalse();
                  }}
                />
              </Box>
              <Typography>{loan?.name} Info</Typography>
            </Stack>
            <Grid container mt={2}>
              <Grid item xs={8} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Box
                    sx={{
                      width: '50px',
                      height: '50px',
                      background: '#E8DDFD',
                      borderRadius: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Iconify
                      icon={loan?.icon || ''}
                      sx={{
                        width: '40px',
                        height: '40px',
                        color: '#7551DC',
                      }}
                    />
                  </Box>
                  <Stack>
                    <Typography sx={{ fontSize: '14px', fontWeight: 1000 }}>
                      {loan?.name}
                    </Typography>
                    <Typography sx={{ fontSize: '12px', color: 'grey' }}>Active</Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={2} md={2}>
                <Box sx={{ position: 'relative', width: '60px', height: '60px' }}>
                  <svg width="60" height="60" viewBox="0 0 40 40">
                    {/* Фон круга */}
                    <circle cx="20" cy="20" r="15" fill="none" stroke="#d6d6d6" strokeWidth="3" />
                    {/* Заполненный круг */}
                    <circle
                      cx="20"
                      cy="20"
                      r="15"
                      fill="none"
                      stroke="#7551DC"
                      strokeWidth="3"
                      strokeDasharray="94.2" // Длина окружности
                      strokeDashoffset={`${94.2 - (parseFloat(loan?.percent || '') / 100) * 94.2}`}
                      strokeLinecap="round"
                      transform="rotate(90 20 20)" // Поворот на 90 градусов
                    />
                  </svg>
                  {/* Текст в центре */}
                  <Typography
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      fontSize: '10px',
                      // fontWeight: 'bold',
                    }}
                  >
                    {loan?.percent}
                  </Typography>
                </Box>
              </Grid>
              <Divider sx={{ width: '100%', my: 1 }} />
              <Grid item xs={6}>
                <Typography sx={{ color: 'grey' }}>Reference number</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ textAlign: 'end', fontWeight: 'bold' }}>AE1223LI</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: 'grey' }}>Payment Date</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ textAlign: 'end', fontWeight: 'bold' }}>{loan?.date}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: 'grey' }}>Period</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ textAlign: 'end', fontWeight: 'bold' }}>
                  {loan?.period}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: 'grey' }}>Payment Amount</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ textAlign: 'end', fontWeight: 'bold' }}>${loan?.sum}</Typography>
              </Grid>
            </Grid>
            <Stack width={1} alignItems="center">
              <Button
                sx={{
                  bgcolor: '#7551DC',
                  color: 'white',
                  width: '100%',
                  mt: 2,
                  '&:hover': {
                    bgcolor: '#7551DC',
                    color: 'white',
                  },
                }}
                onClick={payOff.onTrue}
              >
                Pay off
              </Button>
            </Stack>
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mt: 2 }}>
              Next Payment
            </Typography>
            <Grid container mt={2}>
              <Grid item xs={10} md={10} sx={{ display: 'flex', alignItems: 'center' }}>
                <Stack direction="row" width={1} justifyContent="space-between">
                  <Stack direction="row" gap={1} alignItems="center">
                    <Box
                      sx={{
                        width: '50px',
                        height: '50px',
                        background: '#E8DDFD',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Iconify
                        icon={loan?.icon || ''}
                        sx={{
                          width: '40px',
                          height: '40px',
                          color: '#7551DC',
                        }}
                      />
                    </Box>
                    <Stack>
                      <Typography sx={{ fontSize: '12px', color: 'grey' }}>Payment date</Typography>
                      <Typography sx={{ fontSize: '16px', color: 'black' }}>
                        {loan?.date}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid
                item
                xs={2}
                md={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ color: '#7551DC' }}>$ {loan?.sum}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={payOff.value} onClose={payOff.onFalse}>
          <RecentCardsPayment close={payOff.onFalse} infoPaymentClose={infoPayment.onFalse} />
        </Modal>
      </Grid>
    </Box>
  );
};
