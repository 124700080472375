import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';

type Props = {
  close: () => void;
};

export const Notifications = ({ close }: Props) => {
  const loanCalculator = useBoolean();
  const isMobile = useResponsive('down', 'sm');

  const notifications = [
    {
      date: '01/11/2023',
      transactions: [
        {
          icon: 'akar-icons:arrow-down',
          type: 'Student Loan',
          card: 'MasterCard **** 4321',
          sum: '-$20.00',
        },
        {
          icon: 'akar-icons:arrow-down',
          type: 'Payment Loan #1987',
          card: 'MasterCard **** 4321',
          sum: '-$20.00',
        },
        {
          icon: 'akar-icons:arrow-up',
          type: 'Deposit Money',
          card: 'MasterCard **** 4321',
          sum: '+$40.00',
        },
      ],
    },
    {
      date: '01/10/2023',
      transactions: [
        {
          icon: 'akar-icons:arrow-down',
          type: 'Student Loan',
          card: 'MasterCard **** 4321',
          sum: '-$20.00',
        },
        {
          icon: 'akar-icons:arrow-down',
          type: 'Payment Loan #1987',
          card: 'MasterCard **** 4321',
          sum: '-$20.00',
        },
        {
          icon: 'akar-icons:arrow-up',
          type: 'Deposit Money',
          card: 'MasterCard **** 4321',
          sum: '+$40.00',
        },
      ],
    },
    {
      date: '01/09/2023',
      transactions: [
        {
          icon: 'akar-icons:arrow-down',
          type: 'Payment Loan #1987',
          card: 'MasterCard **** 4321',
          sum: '-$20.00',
        },
        {
          icon: 'akar-icons:arrow-up',
          type: 'Deposit Money',
          card: 'MasterCard **** 4321',
          sum: '+$40.00',
        },
        {
          icon: 'akar-icons:arrow-up',
          type: 'Deposit Money',
          card: 'MasterCard **** 4321',
          sum: '+$40.00',
        },
      ],
    },
  ];

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      {/* Заголовок */}
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid',
            borderColor: '#7551DC',
          }}
        >
          <Iconify
            icon="solar:arrow-left-outline"
            sx={{ color: '#7551DC', cursor: 'pointer' }}
            onClick={close}
          />
        </Box>
        <Typography>Notification</Typography>
      </Stack>

      {/* Содержимое с прокруткой */}
      <Scrollbar sx={{ height: '90%' }}>
        <Stack gap={3} p={2} mt={2}>
          {notifications.map((notification, idx) => (
            <Stack key={idx}>
              {/* Дата уведомления */}
              <Typography sx={{ fontWeight: 600, mb: 1 }}>{notification.date}</Typography>

              {/* Транзакции для даты */}
              {notification.transactions.map((transaction, tIdx) => (
                <Grid key={tIdx} container spacing={1} alignItems="center" mb={1}>
                  <Grid item xs={2} md={2}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <Box
                        sx={{
                          width: '40px',
                          height: '40px',
                          background: '#E8DDFD',
                          borderRadius: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Iconify
                          icon={transaction.icon || 'akar-icons:circle'}
                          sx={{
                            width: '25px',
                            height: '25px',
                            color: '#7551DC',
                          }}
                        />
                      </Box>
                    </Stack>
                  </Grid>
                    <Grid item xs={8}>
                      <Stack>
                        <Typography sx={{ fontSize: '14px', fontWeight: 1000 }}>
                          {transaction.type}
                        </Typography>
                        <Typography sx={{ fontSize: '12px', color: 'grey' }}>
                          {transaction.card}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        sx={{
                          fontWeight: 1000,
                          color: transaction.sum.startsWith('+') ? 'green' : 'red',
                        }}
                      >
                        {transaction.sum}
                      </Typography>
                    </Grid>
                  </Grid>
              ))}
              <Divider sx={{ my: 2 }} />
            </Stack>
          ))}
        </Stack>
      </Scrollbar>
    </Box>
  );
};
