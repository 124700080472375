import React from 'react';

import Stack from '@mui/material/Stack';
import { Box, Button, TextField, Typography, InputAdornment } from '@mui/material';
import { VpnKey, Comment, CreditCard, AttachMoney, AccountCircle, AccountBalance } from '@mui/icons-material';

import Iconify from '../../../../../components/iconify';

type Props = {
  close: () => void;
};

const IBANPaymentForm = ({ close }: Props) => (
    <Box sx={{ maxWidth: 500, mx: 'auto', borderRadius: 2 }}>
      <Stack direction="row" alignItems="center" gap={2}>
        <Iconify icon="fluent:ios-arrow-24-regular" sx={{ cursor: 'pointer' }} onClick={close} />
        <Typography>IBAN Payment</Typography>
      </Stack>
      <Typography variant="h6" gutterBottom my={1}>
        Beneficiary info
      </Typography>

      <TextField
        fullWidth
        variant="outlined"
        placeholder="IBAN number"
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CreditCard sx={{color: '#ff8a65'}}/>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        fullWidth
        variant="outlined"
        placeholder="Beneficiary name"
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle sx={{color: '#ff8a65'}}/>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        fullWidth
        variant="outlined"
        placeholder="BIC code"
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <VpnKey sx={{color: '#ff8a65'}}/>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        fullWidth
        variant="outlined"
        placeholder="Beneficiary bank"
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountBalance sx={{color: '#ff8a65'}}/>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        fullWidth
        variant="outlined"
        placeholder="Amount"
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AttachMoney sx={{color: '#ff8a65'}}/>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        fullWidth
        variant="outlined"
        placeholder="Comment"
        margin="normal"
        multiline
        rows={3}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ alignItems: "flex-start", display: "flex" }}>
              <Comment sx={{color: '#ff8a65'}}/>
            </InputAdornment>
          ),
          sx: { alignItems: "baseline" } // Выравниваем содержимое инпута
        }}
      />

      <Box mt={2}>
        <Button fullWidth variant="contained" sx={{ bgcolor: '#000', color: '#fff' }}>
          Send
        </Button>
      </Box>
    </Box>
  );

export default IBANPaymentForm;
