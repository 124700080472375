'use client'

// components
import { FC, useState } from 'react';
import { useSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {
  Container,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';

import Image from '../../../../../components/image';
import { ComponentTypeProps } from '../../../../../types/page-generator';

const WalletReferralProgram: FC<ComponentTypeProps> = ({ block }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [referralId, setReferralId] = useState(
    'bc2w-qkj7-834-jj0h-458h-dwawp-q034',
  );
  const [referralLink, setReferralLink] = useState(
    'https://crypto-merkulove/design-0010',
  );
  const handleCopyId = () => {
    navigator.clipboard
      .writeText(referralId)
      .then(() => {
        enqueueSnackbar('הערך הועתק ללוח', {
          variant: 'success',
          // dir: 'ltr',
        });
      })
      .catch((error) => {
        enqueueSnackbar('העתקת ערך נכשלה:', {
          variant: 'error',
          // dir: 'ltr',
        });
      });
  };
  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(referralId)
      .then(() => {
        enqueueSnackbar('הערך הועתק ללוח', {
          variant: 'success',
          // dir: 'ltr',
        });
      })
      .catch((error) => {
        enqueueSnackbar('העתקת ערך נכשלה:', {
          variant: 'error',
          // dir: 'ltr',
        });
      });
  };
  return (
    <Container maxWidth="sm">
      <Stack width={1} direction="row" gap={1}>
        <Image
          sx={{ width: '20px' }}
          src="/assets/icons/wallet/icon-invite-light.svg"
        />
        <Typography>Referral Program</Typography>
      </Stack>
      <Box
        sx={{
          border: '1px solid',
          borderColor: '#80808066',
          borderRadius: '10px',
          p: 2,
        }}
      >
        <Typography>Referral Program</Typography>
        <Image src="/assets/crypto/referral-image.png" />
        <Typography sx={{ fontWeight: 800 }}>Referral Details</Typography>
        <Typography sx={{ color: 'grey', my: 2 }}>
          Copy the referral code and send it to the friend
        </Typography>
        <Stack gap={2}>
          <TextField
            fullWidth
            label="Referral ID"
            value={referralId}
            sx={{ borderRadius: '50%' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    onClick={handleCopyId}
                    sx={{
                      bgcolor: 'black',
                      color: 'white',
                      borderRadius: '10px',
                      '&:hover': {
                        bgcolor: 'black',
                        color: 'white',
                      },
                    }}
                  >
                    Copy
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            label="Referral Link"
            value={referralLink}
            sx={{ borderRadius: '50%' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    onClick={handleCopyLink}
                    sx={{
                      bgcolor: 'black',
                      color: 'white',
                      borderRadius: '10px',
                      '&:hover': {
                        bgcolor: 'black',
                        color: 'white',
                      },
                    }}
                  >
                    Copy
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Box>
    </Container>
  );
};
export default WalletReferralProgram;
