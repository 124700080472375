'use client';

import React, { FC } from 'react';

import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';
import { Box, Card, Button, Divider, Typography } from '@mui/material';

import NewLoan from '../modal/new-loan';
import Image from '../../../../../components/image';
import { useBoolean } from '../../../../../hooks/use-boolean';
import { ComponentTypeProps } from '../../../../../types/page-generator';

const LoanCard: FC<ComponentTypeProps> = ({ block }) => {
  const openLoanModal = useBoolean();

  return (
    <Container maxWidth="sm" sx={{ px: 0 }}>
      <Box sx={{ margin: 'auto', textAlign: 'center' }}>
        <Typography variant="h5" gutterBottom sx={{ textAlign: 'start' }}>
          Loans
        </Typography>
        <Typography color="textSecondary" sx={{ textAlign: 'start' }}>
          Current loans
        </Typography>
        <Card sx={{ backgroundColor: '#fff4ee', marginTop: 2, p: 2 }}>
          <Stack direction="row" justifyContent="space-between" width={1} alignItems="center">
            <Stack direction="row" width={1} alignItems="center">
              <Image src="/assets/icons/wallet/loan.svg" />
              <Typography variant="h6">- 20 532.00 USD</Typography>
            </Stack>

            <Typography
              sx={{
                cursor: 'pointer',
                float: 'right',
                color: 'rgb(255, 138, 113)',
                fontSize: '12px',
              }}
            >
              Repay→
            </Typography>
          </Stack>
        </Card>
        <Box textAlign="left" mt={2} p={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography sx={{ color: 'grey' }}>Rate:</Typography>
            <Typography>13%</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography sx={{ color: 'grey' }}>Period:</Typography>
            <Typography>24 months</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography sx={{ color: 'grey' }}>Monthly payment:</Typography>
            <Typography>1 117.00 USD</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography sx={{ color: 'grey' }}>Total paid:</Typography>
            <Typography>4 468.00 USD</Typography>
          </Stack>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Button
          variant="contained"
          sx={{ backgroundColor: '#ffccbc', width: '100%', color: 'black', borderRadius: '10px' }}
          onClick={openLoanModal.onTrue}
        >
          + New Loan
        </Button>
      </Box>

      <Modal open={openLoanModal.value} onClose={() => openLoanModal.onFalse()}>
        <NewLoan close={() => openLoanModal.onFalse()} />
      </Modal>
    </Container>
  );
};

export default LoanCard;
