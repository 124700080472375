import React, { useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Box,
  Button,
  Divider,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import Iconify from '../../../../../components/iconify';
import Image from '../../../../../components/image';
import { useResponsive } from '../../../../../hooks/use-responsive';

type Props = {
  close: () => void;
};

const NewLoan = ({ close }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const [currency, setCurrency] = useState('USD');
  const [period, setPeriod] = useState('24 mos');
  const [amount, setAmount] = useState(1000);
  const [earlyWithdrawal, setEarlyWithdrawal] = useState(false);
  const periods = ['3 mos', '6 mos', '12 mos', '18 mos', '24 mos', '36 mos'];
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: !isMobile ? 400 : '100%',
        bgcolor: 'background.paper',
        border: '2px solid gray.500',
        borderRadius: !isMobile ? '10px' : '0px',
        boxShadow: 24,
        p: 4,
        pt: 1,
        height: !isMobile ? 'auto' : '100%',
      }}
    >
      <Box
        sx={{
          mx: 'auto',
          // bgcolor: '#f8f8f8',
          borderRadius: 2,
        }}
      >
        <Stack direction="row" alignItems="center" gap={2} mb={1}>
          <Iconify icon="fluent:ios-arrow-24-regular" sx={{ cursor: 'pointer' }} onClick={close} />
          <Typography>Add loan</Typography>
        </Stack>
        <Box
          sx={{
            mx: 'auto',
            bgcolor: '#f8f8f8',
            borderRadius: 2,
          }}
        >
          <Typography sx={{ fontSize: '12px', color: 'grey' }}>Choose currency</Typography>
          <ToggleButtonGroup
            value={currency}
            exclusive
            onChange={(e, newCurrency) => newCurrency && setCurrency(newCurrency)}
            sx={{ mb: 2 }}
          >
            <ToggleButton value="USD">USD</ToggleButton>
            <ToggleButton value="EUR">EUR</ToggleButton>
          </ToggleButtonGroup>

          <Typography sx={{ fontSize: '12px', color: 'grey' }}>Choose deposit period</Typography>
          <Stack direction="row" gap={1} justifyContent="space-between">
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ bgcolor: 'rgb(255, 247, 242)', p: 4 }}
            >
              <Typography sx={{ color: 'rgb(255, 138, 113)', fontSize: '12px' }}>You Rate</Typography>
              <Typography sx={{ color: 'rgb(255, 138, 113)', fontSize: '12px' }}>13 %</Typography>
            </Stack>
            <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={1} mb={2}>
              {periods.map((p) => (
                <ToggleButton
                  key={p}
                  value={p}
                  selected={period === p}
                  onClick={() => setPeriod(p)}
                  sx={{fontSize: '12px'}}
                >
                  {p}
                </ToggleButton>
              ))}
            </Box>
          </Stack>
          <Typography sx={{ fontSize: '12px', color: 'grey' }}>Amount</Typography>
          <TextField
            fullWidth
            type="number"
            value={amount}
            onChange={(e) => setAmount(Number(e.target.value))}
            InputProps={{
              startAdornment: <Typography sx={{ pr: 1 }}>$</Typography>,
            }}
            sx={{ mb: 2 }}
          />
          <Typography sx={{ fontSize: '12px', color: 'grey' }}>
            Calculated monthly repayment
          </Typography>
          <TextField
            fullWidth
            type="number"
            value={amount}
            // onChange={(e) => setAmount(Number(e.target.value))}
            InputProps={{
              startAdornment: <Typography sx={{ pr: 1 }}>$</Typography>,
            }}
            sx={{ mb: 2 }}
          />

          <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
            <Typography>Early loan repayment</Typography>
            <Switch
              checked={earlyWithdrawal}
              onChange={() => setEarlyWithdrawal(!earlyWithdrawal)}
            />
          </Box>

          <Button
            fullWidth
            variant="contained"
            sx={{ bgcolor: '#000', borderRadius: '10px' }}
            onClick={close}
          >
            Open Loan
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NewLoan;
