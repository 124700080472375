'use client'

import React, { FC, useState } from 'react';

import {
  Box,
  Tab,
  Grid,
  Tabs,
  Card,
  Stack,
  Avatar,
  Button,
  Container,
  CardMedia,
  Typography,
  CardContent,
} from '@mui/material';

import author from '../../../../../constants/author';
import { ComponentTypeProps } from '../../../../../types/page-generator';
import Image from '../../../../../components/image';

const NftProfile: FC<ComponentTypeProps> = ({ block }) => {
  const [tab, setTab] = useState(0);

  return (
    <Container maxWidth="sm">
      <Stack width={1} direction="row" gap={1}>
        <Typography>NFT profile</Typography>
      </Stack>
      {/* Профиль */}
      <Box
        sx={{
          p: 2,
          borderRadius: 2,
          backgroundColor: 'black',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Stack direction="row" gap={1} justifyContent="space-between" width={1}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Avatar src={author.avatar} sx={{ width: 56, height: 56 }} />
            <Typography variant="h6">{author.name}</Typography>
          </Stack>
          <Box>
            <Typography>
              Items:{' '}
              <b style={{ color: 'yellowgreen' }}>{author.items.length}</b>
            </Typography>
            <Typography>
              Collections:{' '}
              <b style={{ color: 'yellowgreen' }}>
                {author.collections.length}
              </b>
            </Typography>
          </Box>
        </Stack>
      </Box>

      {/* Кнопки Wallet / History */}
      <Stack direction="row" spacing={2} mt={2} justifyContent="center">
        <Button variant="outlined" fullWidth>
          Wallet
        </Button>
        <Button variant="outlined" fullWidth>
          History
        </Button>
      </Stack>

      {/* Табы */}
      <Tabs
        value={tab}
        onChange={(_, newValue) => setTab(newValue)}
        centered
        sx={{ mt: 2 }}
      >
        <Tab label="Items" />
        <Tab label="Collections" />
      </Tabs>

      {/* Контент */}
      <Box
        sx={{
          height: '500px',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'grey',
            borderRadius: '10px',
            border: '2px solid grey',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'lightgrey',
            borderRadius: '10px',
          },
        }}
      >
        <Grid container spacing={2} mt={2}>
          {tab === 0 &&
            author.items.map((item) => (
              <Grid item xs={6} key={item.id}>
                <Card sx={{ borderRadius: 2 }}>
                  <CardMedia
                    component="img"
                    height="120"
                    image={item.image}
                    alt={item.name}
                  />
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Typography fontWeight="bold">{item.name}</Typography>
                    <Typography variant="body2">{item.collection}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}

          {tab === 1 &&
            author.collections.map((collection) => (
              <Grid item xs={6} key={collection.id}>
                <Card sx={{ borderRadius: 2 }}>
                  <CardMedia
                    component="img"
                    height="120"
                    image={collection.image}
                    alt={collection.name}
                  />
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Avatar
                      src={collection.avatar}
                      sx={{ width: 24, height: 24, mx: 'auto', mb: 1 }}
                    />
                    <Typography fontWeight="bold">{collection.name}</Typography>
                    <Typography variant="body2">
                      {collection.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default NftProfile;
