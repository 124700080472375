'use client'

// components
import { useState, memo, FC, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Container, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';

import {
  CRYPTO_CURRENCIES,
  CURRENCIES,
} from '../../../../../constants/currencies';
import Image from '../../../../../components/image';
import Scrollbar from '../../../../../components/scrollbar';
import CryptoWalletItem from './crypto-wallet-item';
import { ComponentTypeProps } from '../../../../../types/page-generator';

interface Currency {
  value: string;
  label: string;
}

interface WalletItemProps {
  key: string;
  balance: number;
}


const WalletOverview: FC<ComponentTypeProps> = ({ block }) => {
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(
    CURRENCIES[0],
  ); // Стейт для выбора валюты
  const totalBTC = 0.234156;
  const [selectedBalance, setSelectedBalance] = useState<number>(0);

  useEffect(() => {
    const balance =
      data.find((item) => item.key === selectedCurrency.value)?.balance || 0;
    setSelectedBalance(balance);
  }, [selectedCurrency]);

  const data: WalletItemProps[] = [
    { key: 'btc', balance: 0.1832 },
    { key: 'usd', balance: 125.14 },
    { key: 'eur', balance: 0.587 },
    { key: 'gbp', balance: 12.356 },
    { key: 'aud', balance: 0.999 },
    { key: 'cad', balance: 7.504 },
    { key: 'orchid', balance: 12.102 },
    { key: 'usdt', balance: 0 },
    { key: 'ada', balance: 0 },
    { key: 'xph', balance: 0 },
    { key: 'matic', balance: 0 },
    { key: 'link', balance: 0 },
    { key: 'okb', balance: 0 },
  ];

  return (
    <Container maxWidth="sm">
      <Stack width={1} direction="row" gap={1}>
        <Image
          sx={{ width: '20px' }}
          src="/assets/icons/wallet/icon-wallet-light.svg"
        />
        <Typography>Wallet Cryptocurrency</Typography>
      </Stack>
      <Box
        sx={{
          position: 'relative',
          // height: '300px', // Можно настроить под размер mountains.svg
          backgroundColor: 'black', // Чёрный фон сохраняем
          borderRadius: '10px',
          opacity: 0.9,
        }}
      >
        {/* Фоновое изображение */}
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundImage: 'url(/assets/mountains.svg)',
          }}
        >
          <Image
            src="/assets/wallet.webp"
            sx={{
              position: 'absolute',
              top: '-20px', // Двигаем выше границы блока
              right: '20px',
              height: '140px', // Размер картинки (настраиваемый)
              width: 'auto',
              zIndex: 2, // Поднимаем выше текста
            }}
          />
          <Grid
            container
            sx={{ position: 'relative', zIndex: 1, padding: '16px', pb: 0 }}
          >
            <Grid item xs={12}>
              <Stack alignItems="flex-start">
                <Typography sx={{ color: 'white', fontSize: '12px' }}>
                  Total Assets
                </Typography>
                <Stack direction="row-reverse" alignItems="center" gap={1}>
                  <TextField
                    select
                    value={selectedCurrency.value}
                    onChange={(e) => {
                      const newCurrency = CURRENCIES.find(
                        (c) => c.value === e.target.value,
                      );
                      if (newCurrency) setSelectedCurrency(newCurrency);
                    }}
                    SelectProps={{ sx: { color: 'grey' } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: 'white', border: 'none' },
                        '&:hover fieldset': { borderColor: 'grey' },
                        '&.Mui-focused fieldset': { borderColor: 'grey' },
                      },
                      '& .MuiSvgIcon-root': { color: 'grey' },
                    }}
                  >
                    {CURRENCIES.map((currency) => (
                      <MenuItem key={currency.value} value={currency.value}>
                        {currency.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Typography
                    sx={{ color: 'white', fontSize: '24px', fontWeight: 800 }}
                  >
                    {selectedBalance}
                  </Typography>
                </Stack>
                <Stack direction="row-reverse" gap={1} sx={{ pb: '10px' }}>
                  <Typography sx={{ color: 'grey' }}>BTC</Typography>
                  <Typography sx={{ color: 'white' }}>{totalBTC}</Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ my: '10px' }} />
      <Scrollbar sx={{ height: '550px' }}>
        <Stack gap={0}>
          {CRYPTO_CURRENCIES.map((item, idx) => (
            <CryptoWalletItem
              key={item.value}
              icon={item.icon}
              name={item.name}
              symbol={item.label}
              balance={item.usd}
              usdValue={item.exchange.usd}
              idx={idx}
            />
          ))}
        </Stack>
      </Scrollbar>
    </Container>
  );
};
export default WalletOverview;
