import { useForm } from 'react-hook-form';

import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Iconify from '../../../../components/iconify';
import { useResponsive } from '../../../../hooks/use-responsive';
import FormProvider, { RHFSwitch } from '../../../../components/hook-form';

type Props = {
  close: () => void;
};

export const CardSettings = ({ close }: Props) => {
  const isMobile = useResponsive('down', 'sm');

  const methods = useForm({});

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data) => {});

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      {/* Заголовок */}
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid',
            borderColor: '#7551DC',
          }}
        >
          <Iconify
            icon="solar:arrow-left-outline"
            sx={{ color: '#7551DC', cursor: 'pointer' }}
            onClick={close}
          />
        </Box>
        <Typography>Settings</Typography>
      </Stack>
      <Stack>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <RHFSwitch name="sabing" label="Switch card to savings account" />
        </FormProvider>
      </Stack>

      {/* <Scrollbar sx={{ height: '60%' }}/> */}
    </Box>
  );
};
