'use client'

import { FC, useState } from 'react';

import {
  Table,
  Stack,
  TableRow,
  Container,
  TextField,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  ToggleButton,
  TableSortLabel,
  ToggleButtonGroup,
} from '@mui/material';

import Image from '../../../../../components/image';
import Label from '../../../../../components/label';
import Iconify from '../../../../../components/iconify';
import Scrollbar from '../../../../../components/scrollbar';
import { CRYPTO_CURRENCIES } from '../../../../../constants/currencies';
import { ComponentTypeProps } from '../../../../../types/page-generator';

const CryptoWalletList: FC<ComponentTypeProps> = ({ block }) => {
  const [tabIndex, setTabIndex] = useState('favorites');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState<'name' | 'price' | 'change'>('name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const [favorites, setFavorites] = useState<Record<string, boolean>>({});

  const toggleFavorite = (coinId: string) => {
    setFavorites((prev) => ({
      ...prev,
      [coinId]: !prev[coinId],
    }));
  };

  // Фильтрация списка криптовалют
  const filteredData = CRYPTO_CURRENCIES.filter((coin) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      coin.name.toLowerCase().includes(searchLower) ||
      coin.label.toLowerCase().includes(searchLower) ||
      coin.usd.toString().includes(searchLower) ||
      coin.exchange.usd.toString().includes(searchLower)
    );
  });

  // Сортировка списка
  const sortedData = [...filteredData].sort((a, b) => {
    let valueA;
    let valueB;
    switch (sortBy) {
      case 'name':
        valueA = a.name.toLowerCase();
        valueB = b.name.toLowerCase();
        break;
      case 'price':
        valueA = a.usd;
        valueB = b.usd;
        break;
      case 'change':
        valueA = a.exchange.usd;
        valueB = b.exchange.usd;
        break;
      default:
        return 0;
    }

    if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
    if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });
  const getBorderRadius = (tab: string) => {
    switch (tab) {
      case 'favorites':
        return {
          borderTopLeftRadius: '20px',
          borderBottomLeftRadius: '20px',
        };
      case 'coins':
        return {}; // Средняя кнопка без скруглений
      case 'trending':
        return {
          borderTopRightRadius: '20px',
          borderBottomRightRadius: '20px',
        };
      default:
        return {};
    }
  };

  return (
    <Container maxWidth="sm">
      {/* Переключение вкладок */}
      <ToggleButtonGroup
        value={tabIndex}
        exclusive
        onChange={(_, newValue) => newValue && setTabIndex(newValue)}
        sx={{
          mb: 2,
          width: '100%',
          border: '1px solid grey',
          borderRadius: '20px',
          p: 0,
        }}
      >
        {['favorites', 'coins', 'trending'].map((tab) => (
          <ToggleButton
            key={tab}
            value={tab}
            disableRipple // Отключаем эффект нажатия
            style={{ ...getBorderRadius(tab) }}
            sx={(theme) => ({
              width: '100%',
              border: 'none',
              borderRadius: '20px',
              textTransform: 'none',
              fontWeight: 'bold',
              transition: 'background-color 0.3s ease', // Плавное изменение цвета
              backgroundColor: tabIndex === tab ? '#F0FF40' : 'transparent', // Активный фон
              color: tabIndex === tab ? 'black' : 'grey',
              '&.Mui-selected': {
                backgroundColor: '#F0FF40', // Цвет активной кнопки
                color: 'black',
              },
              '&.MuiToggleButtonGroup-grouped': {
                margin: 0,
              },
              '&.MuiButtonBase-root': {
                borderRadius: '20px',
              },
              '&:hover': {
                backgroundColor: '#E0E030',
              },
            })}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      {/* Фильтр по имени / цене / изменению */}
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search by name, price, change 24h..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Scrollbar sx={{ height: '550px', width: '100%' }}>
        {/* Таблица с сортировкой */}
        <Table>
          <TableHead
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              zIndex: 10, // Увеличиваем z-index, чтобы заголовок был поверх контента
            }}
          >
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'name'}
                  direction={sortOrder}
                  onClick={() => {
                    setSortBy('name');
                    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                  }}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'price'}
                  direction={sortOrder}
                  onClick={() => {
                    setSortBy('price');
                    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                  }}
                >
                  Price
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'change'}
                  direction={sortOrder}
                  onClick={() => {
                    setSortBy('change');
                    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                  }}
                >
                  Change 24h
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((item, idx) => (
              <TableRow
                sx={{
                  borderTopRightRadius: idx === 0 ? '10px' : 0,
                  borderTopLeftRadius: idx === 0 ? '10px' : 0,
                  borderBottomLeftRadius:
                    idx === sortedData.length - 1 ? '10px' : 0,
                  borderBottomRightRadius:
                    idx === sortedData.length - 1 ? '10px' : 0,
                  border: '1px solid #E0E0E0',
                  padding: '8px 16px',
                  width: '100%',
                }}
              >
                <TableCell>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Iconify
                      icon={favorites[item.name] ? 'bi:star-fill' : 'bi:star'}
                      sx={{
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer',
                        color: 'yellow',
                      }}
                      onClick={() => toggleFavorite(item.name)}
                    />
                    <Image
                      src={item.icon}
                      alt={item.name}
                      sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: '#F5F5F5',
                        '& img': { objectFit: 'contain' },
                      }}
                    />
                    <Typography variant="subtitle1" fontWeight={600}>
                      {item.name}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack alignItems="flex-end" direction="row" gap={1}>
                    <Typography variant="subtitle1" fontWeight={600}>
                      $
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={600}>
                      {item.usd}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack alignItems="center" direction="row">
                    <Label
                      sx={{
                        bgcolor: item.change < 0 ? 'red' : 'green',
                        color: 'white',
                        width: '50px',
                      }}
                    >
                      {item.change} %
                    </Label>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Container>
  );
};

export default CryptoWalletList;
