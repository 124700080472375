'use client'

import { useSnackbar } from 'notistack';
// components
import { FC, memo, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import {
  Divider,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';

import Image from '../../../../../components/image';
import { ComponentTypeProps } from '../../../../../types/page-generator';
import {
  MERCHANTS,
  CURRENCIES,
} from '../../../../../constants/currencies';

interface Currency {
  value: string;
  label: string;
}

const DepositWallet: FC<ComponentTypeProps> = ({ block }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [textFieldValue, setTextFieldValue] = useState(
    'bc2w-qkj7-834-jj0h-458h-dwawp-q034',
  );
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(
    CURRENCIES[0],
  );
  const [selectedCurrencyMerchant, setSelectedCurrencyMerchant] =
    useState<Currency>(MERCHANTS[0]);
  const handleCopy = () => {
    navigator.clipboard
      .writeText(textFieldValue)
      .then(() => {
        enqueueSnackbar('הערך הועתק ללוח', {
          variant: 'success',
          // dir: 'ltr',
        });
      })
      .catch((error) => {
        enqueueSnackbar('העתקת ערך נכשלה:', {
          variant: 'error',
          // dir: 'ltr',
        });
      });
  };
  return (
    <Container maxWidth='sm' sx={{px: 2}}>
      <Stack width={1} direction="row" gap={1}>
        <Image
          sx={{ width: '20px' }}
          src="/assets/icons/wallet/icon-deposit-light.svg"
        />
        <Typography>Deposit</Typography>
      </Stack>
      <Box
        sx={{
          border: '1px solid',
          borderColor: '#80808066',
          borderRadius: '10px',
          p: 2,
        }}
      >
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <Stack width={1}>
              <Typography sx={{ color: 'grey', width: 1 }}>AMOUNT</Typography>
              <Stack direction="row" width={1}>
                <TextField placeholder="0.00" sx={{ width: '80%' }} />
                <TextField
                  select
                  value={selectedCurrency.value}
                  onChange={(e) => {
                    const newCurrency = CURRENCIES.find(
                      (c) => c.value === e.target.value,
                    );
                    if (newCurrency) setSelectedCurrencyMerchant(newCurrency);
                  }}
                  SelectProps={{ sx: { color: 'grey' } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: 'white', border: 'none' },
                      '&:hover fieldset': { borderColor: 'grey' },
                      '&.Mui-focused fieldset': { borderColor: 'grey' },
                    },
                    '& .MuiSvgIcon-root': { color: 'grey' },
                  }}
                >
                  {CURRENCIES.map((currency) => (
                    <MenuItem key={currency.value} value={currency.value}>
                      {currency.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Typography sx={{ color: 'grey', width: 1 }}>MERCHANT</Typography>
              <TextField
                select
                value={selectedCurrencyMerchant.value}
                onChange={(e) => {
                  const newMerchant = MERCHANTS.find(
                    (m) => m.value === e.target.value,
                  );
                  if (newMerchant) setSelectedCurrencyMerchant(newMerchant);
                }}
                SelectProps={{ sx: { color: 'grey' } }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'grey' },
                    '&:hover fieldset': { borderColor: 'grey' },
                    '&.Mui-focused fieldset': { borderColor: 'grey' },
                  },
                  '& .MuiSvgIcon-root': { color: 'grey' },
                  '& .MuiInputBase-root': {
                    color: 'black',
                  },
                }}
              >
                {MERCHANTS.map((merchant) => (
                  <MenuItem key={merchant.value} value={merchant.value}>
                    {merchant.label}
                  </MenuItem>
                ))}
              </TextField>
              <Stack gap={2} my={2}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ color: 'grey' }}>Fee:</Typography>
                  <Typography>0.1%</Typography>
                </Stack>
                <Divider sx={{ width: '100%', color: 'grey' }} />
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ color: 'grey' }}>
                    Amount received:
                  </Typography>
                  <Stack direction="row" gap={1}>
                    <Typography>0</Typography>
                    <Typography>{selectedCurrency.label}</Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Typography sx={{ color: 'grey', fontSize: '12px' }}>
                Justo donec enim diam vulputate ut pharetra. Ut placeratorci
                nulla pellen. Nulla pellentesque dignissim enim sit.
              </Typography>
              <Button
                sx={{ width: 1, background: 'black', my: 2 }}
                variant="contained"
              >
                Continue
              </Button>
            </Stack>
          </Grid>
          <Grid item md={12} xs={12}>
            <Stack>
              <Image
                src="/assets/crypto/qr.png"
                sx={{
                  width: '100%',
                  maxWidth: 310,
                  height: 'auto',
                  alignSelf: 'center',
                }}
              />
              {/* <CopyField */}
              {/*  label="Referral ID" */}
              {/*  value="bc2w-qkj7-834-jj0h-458h-dwawp-q034" */}
              {/* /> */}
              <TextField
                fullWidth
                label="Address"
                value={textFieldValue}
                sx={{ borderRadius: '50%', my: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        onClick={handleCopy}
                        sx={{
                          bgcolor: 'black',
                          color: 'white',
                          borderRadius: '10px',
                          '&:hover': {
                            bgcolor: 'black',
                            color: 'white',
                          },
                        }}
                      >
                        Copy
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default memo(DepositWallet);
