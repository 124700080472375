import { useState } from 'react';

import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
import { useResponsive } from '../../../../hooks/use-responsive';

type Props = {
  close: () => void;
};

export const Share = ({ close }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [phoneSearch, setPhoneSearch] = useState(''); // Поле поиска по телефону
  const [foundPerson, setFoundPerson] = useState<any>(null); // Найденный человек
  const peopleArray = [
    {
      avatar: '/assets/images/specialists/specialists1.jpg',
      name: 'Michael',
      phone: '123-456-7890',
    },
    { avatar: '/assets/images/specialists/specialists2.jpg', name: 'Emily', phone: '987-654-3210' },
    { avatar: '/assets/images/specialists/specialists3.jpg', name: 'James', phone: '555-555-5555' },
    {
      avatar: '/assets/images/specialists/specialists4.jpg',
      name: 'Olivia',
      phone: '444-444-4444',
    },
    {
      avatar: '/assets/images/specialists/specialists5.jpg',
      name: 'William',
      phone: '333-333-3333',
    },
    {
      avatar: '/assets/images/specialists/specialists6.jpg',
      name: 'Mickle',
      phone: '222-222-2222',
    },
    {
      avatar: '/assets/images/specialists/specialists7.jpg',
      name: 'Benjamin',
      phone: '111-111-1111',
    },
  ];
  const [peoples, setPeoples] = useState([
    {
      avatar: '/assets/images/specialists/specialists1.jpg',
      name: 'Michael',
      phone: '123-456-7890',
    },
    {
      avatar: '/assets/images/specialists/specialists7.jpg',
      name: 'Benjamin',
      phone: '111-111-1111',
    },
  ]);

  // Поиск человека по номеру телефона
  const handlePhoneSearch = () => {
    const person = peopleArray.find(
      (p) => p.phone.includes(phoneSearch.trim()) && !peoples.some((pp) => pp.name === p.name)
    );
    setFoundPerson(person || null); // Устанавливаем найденного человека или null
  };

  // Добавление найденного человека в список
  const handleAddPerson = () => {
    if (foundPerson) {
      if (!peoples.some((p) => p.name === foundPerson.name)) {
        setPeoples((prev) => [...prev, foundPerson]); // Добавляем найденного человека
      } else {
        alert(`${foundPerson.name} is already in the list!`); // Уведомляем пользователя
      }
      setFoundPerson(null); // Сбрасываем найденного человека
      setPhoneSearch(''); // Очищаем поле поиска
    }
  };

  // Удаление человека из списка
  const handleRemovePerson = (name: string) => {
    setPeoples((prev) => prev.filter((person) => person.name !== name));
  };

  // Фильтрация списка по имени
  const filteredPeoples = peoples.filter((person) =>
    person.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      {/* Заголовок */}
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid',
            borderColor: '#7551DC',
          }}
        >
          <Iconify
            icon="solar:arrow-left-outline"
            sx={{ color: '#7551DC', cursor: 'pointer' }}
            onClick={close}
          />
        </Box>
        <Typography>Add people</Typography>
      </Stack>

      {/* Поиск по телефону */}
      <Stack direction="row" gap={2} mt={2} alignItems="center">
        <TextField
          fullWidth
          placeholder="Search by phone number"
          value={phoneSearch}
          onChange={(e) => setPhoneSearch(e.target.value)}
          InputProps={{
            endAdornment: phoneSearch && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setPhoneSearch('');
                  }}
                >
                  <Iconify icon="ic:round-clear" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          onClick={handlePhoneSearch}
          variant="contained"
          sx={{
            backgroundColor: '#0047AB',
            color: 'white',
            '&:hover': { backgroundColor: '#003B93' },
          }}
        >
          Search
        </Button>
      </Stack>

      {/* Отображение найденного человека */}
      {foundPerson && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
          p={2}
          sx={{ border: '1px solid #ddd', borderRadius: '10px' }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Avatar src={foundPerson.avatar} />
            <Typography>{foundPerson.name}</Typography>
          </Stack>
          <Button
            onClick={handleAddPerson}
            variant="contained"
            sx={{
              backgroundColor: '#28a745',
              color: 'white',
              '&:hover': { backgroundColor: '#218838' },
            }}
          >
            Add
          </Button>
        </Stack>
      )}

      {/* Поле поиска по имени */}
      {/* <TextField */}
      {/*  fullWidth */}
      {/*  placeholder="Search by name" */}
      {/*  value={searchQuery} */}
      {/*  onChange={(e) => setSearchQuery(e.target.value)} */}
      {/*  sx={{ my: 2 }} */}
      {/* /> */}

      {/* Содержимое с прокруткой */}
      <Scrollbar sx={{ height: '60%' }}>
        <Stack gap={3} p={2}>
          {filteredPeoples.map((people, idx) => (
            <Stack direction="row" justifyContent="space-between" key={idx}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Avatar src={people.avatar} />
                <Typography>{people.name}</Typography>
              </Stack>
              <Button
                onClick={() => handleRemovePerson(people.name)}
                sx={{
                  backgroundColor: 'lightgrey',
                  color: 'black',
                  '&:hover': { backgroundColor: '#d3d3d3', color: 'black' },
                  borderRadius: '10px',
                  px: 2,
                }}
              >
                Remove
              </Button>
            </Stack>
          ))}
        </Stack>
      </Scrollbar>
    </Box>
  );
};
