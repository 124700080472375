
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import {
  Box,
  Typography,
} from '@mui/material';

import { EnterPin } from './enter-pin';
import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';

type Props = {
  close: () => void;
  loanRequestClose: () => void;
  loanCalculatorClose: () => void;
  recentCardsClose: () => void;
  documentAttachClose: () => void;
};

export const LoanSummary = ({ close, loanRequestClose, loanCalculatorClose, recentCardsClose, documentAttachClose }: Props) => {
  const enterPin = useBoolean();
  const isMobile = useResponsive('down', 'sm');

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid',
            borderColor: '#7551DC',
          }}
        >
          <Iconify icon="solar:arrow-left-outline" sx={{ color: '#7551DC',cursor: 'pointer' }} onClick={close} />
        </Box>
        <Typography>Loan Summary</Typography>
      </Stack>
      <Stack
        gap={3}
        p={2}
        mt={2}
        sx={
          isMobile
            ? {
                maxHeight: 'calc(100vh - 70px)', // Ограничение высоты (регулируйте по своему дизайну)
                overflowY: 'auto', // Включаем вертикальную прокрутку
              }
            : {}
        }
      >
        <Stack>
          <Typography sx={{ fontSize: '12px', color: 'grey', textAlign: 'justify' }}>
            Early and one time payment increases your loan limit and makes you eligible for higher
            amounts.
          </Typography>
        </Stack>
        <Paper elevation={2} sx={{padding: 2}}>
          <Stack>
            <Stack
              alignItems="flex-start"
              sx={{
                bgcolor: '#E8DDFD',
                borderRadius: '10px',
                p: 2,
              }}
            >
              <Stack direction="row" gap={2}>
                <Image src="/assets/images/mastercards.png" sx={{ width: '40px' }} />
                <Stack>
                  <Typography sx={{ fontWeight: 1000 }}>Mastercard</Typography>
                  <Typography sx={{ fontSize: '12px', color: 'grey' }}>
                    1254 **** **** 1293
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack mt={1}>
              <Stack direction='row' justifyContent='space-between'>
                <Typography sx={{color: 'grey'}}>Amount</Typography>
                <Typography sx={{fontWeight: 1000}}>$ 1.000</Typography>
              </Stack>
              <Stack direction='row' justifyContent='space-between'>
                <Typography sx={{color: 'grey'}}>Interest rate/month</Typography>
                <Typography sx={{fontWeight: 1000}}>0,5%</Typography>
              </Stack>
              <Stack direction='row' justifyContent='space-between'>
                <Typography sx={{color: 'grey'}}>Period</Typography>
                <Typography sx={{fontWeight: 1000}}>12 Months</Typography>
              </Stack>
              <Stack direction='row' justifyContent='space-between'>
                <Typography sx={{color: 'grey'}}>Agreement date </Typography>
                <Typography sx={{fontWeight: 1000}}>1 Sep,2023</Typography>
              </Stack>
              <Stack direction='row' justifyContent='space-between'>
                <Typography sx={{color: 'grey'}}>Monthly payment</Typography>
                <Typography sx={{fontWeight: 1000}}>$ 170</Typography>
              </Stack>
              <Stack direction='row' justifyContent='space-between' my={2}>
                <Typography sx={{color: 'grey'}}>Total</Typography>
                <Typography sx={{fontSize: '20px', fontWeight: 1000, color: '#7551DC'}}>$ 1.000</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
        <Button
          sx={{
            width: 1,
            bgcolor: '#7551DC',
            color: 'white',
            borderRadius: '10px',
            '&:hover': {
              bgcolor: '#7551DC',
              color: 'white',
              borderRadius: '10px',
            },
          }}
          onClick={enterPin.onTrue}
        >
          Summit
        </Button>
      </Stack>
      <Modal open={enterPin.value} onClose={enterPin.onFalse}>
        <EnterPin close={enterPin.onFalse} loanRequestClose={loanRequestClose} loanCalculatorClose={loanCalculatorClose} recentCardsClose={recentCardsClose} documentAttachClose={documentAttachClose} loanSummaryClose={close}/>
      </Modal>
    </Box>
  );
};
