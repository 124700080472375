'use client';

import React, { FC } from 'react';

import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';
import {
  Box,
  Card,
  Button,
  Typography,
  CardContent,
} from '@mui/material';

import Moneybox from '../modal/moneybox';
import DepositModal from '../modal/deposit-modal';
import Image from '../../../../../components/image';
import { useBoolean } from '../../../../../hooks/use-boolean';
import { ComponentTypeProps } from '../../../../../types/page-generator';

type DepositProps = {
  amount: number;
  currency: string;
  period: string;
  action: string;
  image?: string;
};

type GoalDepositProps = {
  amount: number;
  currency: string;
  goal: number;
  name: string;
  image: string;
};

const deposits: DepositProps[] = [
  {
    amount: 1712.78,
    currency: 'USD',
    period: 'Jan 1 - Apr 1, 2023',
    action: 'Withdrawal →',
    image: '/assets/icons/wallet/done-blue.svg',
  },
  {
    amount: 3648.37,
    currency: 'USD',
    period: 'Feb 1 - May 1, 2023',
    action: 'Top-up →',
    image: '/assets/icons/wallet/8.svg',
  },
];

const goalDeposit: GoalDepositProps = {
  amount: 650.37,
  currency: 'USD',
  goal: 1200,
  name: 'New iPhone Pro Max',
  image: '/assets/icons/wallet/pig.svg',
};

const DepositCard: React.FC<DepositProps> = ({ amount, currency, period, action, image }) => (
  <Card variant="outlined" sx={{ mb: 2, backgroundColor: '#fff8f3' }}>
    <CardContent
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box display="flex" alignItems="center">
        <Image src={image} />
        <Box>
          <Typography variant="h6" sx={{ mx: 1 }}>
            {amount} {currency}
          </Typography>
          <Typography sx={{ fontSize: '12px' }} color="textSecondary">
            {period}
          </Typography>
        </Box>
      </Box>
      <Button sx={{ color: 'rgb(255, 138, 113)', fontSize: '12px' }}>{action}</Button>
    </CardContent>
  </Card>
);

const GoalDepositCard: React.FC<GoalDepositProps> = ({ amount, currency, goal, name, image }) => (
  <Card variant="outlined" sx={{ mb: 2, backgroundColor: '#fff8f3' }}>
    <CardContent
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box display="flex" alignItems="center">
        <Image src={image} />
        <Box>
          <Typography variant="h6" sx={{ mx: 1 }}>
            {amount} {currency}
          </Typography>
          <Typography sx={{ fontSize: '12px' }} color="textSecondary">
            {name}
          </Typography>
        </Box>
      </Box>
      <Typography variant="caption">
        Goal: {goal} {currency}
      </Typography>
    </CardContent>
  </Card>
);
const DepositComponents: FC<ComponentTypeProps> = ({ block }) => {
  const openDepositModal = useBoolean();
  const openMoneyboxModal = useBoolean();

  return (
    <Container maxWidth="sm" sx={{ p: 0 }}>
      <Box sx={{ mx: 'auto', py: 2 }}>
        <Typography variant="h5" gutterBottom>
          Deposits
        </Typography>
        <Typography sx={{ fontSize: '12px', color: 'grey' }} gutterBottom>
          Current deposits
        </Typography>
        {deposits.map((deposit, index) => (
          <DepositCard key={index} {...deposit} />
        ))}
        <Typography sx={{ fontSize: '12px', color: 'grey' }} gutterBottom>
          Current deposits
        </Typography>
        <GoalDepositCard {...goalDeposit} />
        <Box display="flex" justifyContent="space-between" mt={2} gap={1}>
          <Button
            sx={{
              bgcolor: 'rgb(255, 217, 195)',
              color: 'black',
              width: '100%', borderRadius: '10px',
              '&:hover': { bgcolor: 'rgb(255, 217, 195)' },
            }}
            onClick={openMoneyboxModal.onTrue}
          >
            + Moneybox
          </Button>
          <Button
            sx={{ bgcolor: 'black', width: '100%', borderRadius: '10px', color: 'white' }}
            onClick={openDepositModal.onTrue}
          >
            + Deposit
          </Button>
        </Box>
      </Box>
      <Modal open={openDepositModal.value} onClose={() => openDepositModal.onFalse()}>
        <DepositModal close={() => openDepositModal.onFalse()} />
      </Modal>
      <Modal open={openMoneyboxModal.value} onClose={() => openMoneyboxModal.onFalse()}>
        <Moneybox close={() => openMoneyboxModal.onFalse()} />
      </Modal>
    </Container>
  );
};

export default DepositComponents;
