'use client'

import { Card, CardContent, Stack, Typography, Avatar } from '@mui/material';
import { FC } from 'react';
import { CRYPTO_CURRENCIES } from '../../../../../constants/currencies';
import Image from '../../../../../components/image';

interface CryptoWalletItemProps {
  icon: string;
  name: string;
  symbol: string;
  balance: number;
  usdValue: number;
  idx: number;
}

const CryptoWalletItem: FC<CryptoWalletItemProps> = ({
  icon,
  name,
  symbol,
  balance,
  usdValue,
  idx,
}) => (
  <Card
    sx={{
      borderRadius: 0,
      borderTopRightRadius: idx === 0 ? '10px' : 0,
      borderTopLeftRadius: idx === 0 ? '10px' : 0,
      borderBottomLeftRadius: idx === CRYPTO_CURRENCIES.length - 1 ? '10px' : 0,
      borderBottomRightRadius:
        idx === CRYPTO_CURRENCIES.length - 1 ? '10px' : 0,
      boxShadow: 'none',
      border: '1px solid #E0E0E0',
      padding: '8px 16px',
    }}
  >
    <CardContent
      sx={{ padding: '8px', '&:last-child': { paddingBottom: '8px' } }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        {/* Иконка криптовалюты */}
        <Image
          src={icon}
          alt={name}
          sx={{
            width: 40,
            height: 40,
            backgroundColor: '#F5F5F5',
            '& img': { objectFit: 'contain' },
          }}
        />
        {/* <Avatar */}
        {/*  src={icon} */}
        {/*  alt={name} */}
        {/*  sx={{ width: 40, height: 40, backgroundColor: '#F5F5F5' }} */}
        {/* /> */}

        <Stack flex={1}>
          <Typography variant="subtitle1" fontWeight={600}>
            {name}
          </Typography>
          <Typography variant="body2" color="gray">
            {symbol}
          </Typography>
        </Stack>

        <Stack alignItems="flex-end">
          <Typography variant="subtitle1" fontWeight={600}>
            {balance}
          </Typography>
          <Typography variant="body2" color="gray">
            {usdValue.toFixed(2)} USD
          </Typography>
        </Stack>
      </Stack>
    </CardContent>
  </Card>
);

export default CryptoWalletItem;
