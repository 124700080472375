'use client';

import { useState, useEffect, useCallback } from 'react';

// @mui
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Container from '@mui/material/Container';
// routes
// import { PATH_DASHBOARD as paths } from 'src/routes/paths';
// _mock
import { useRouter } from 'next/navigation';

import { Stack } from '@mui/material';
import Button from '@mui/material/Button';

// import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useTranslate } from 'src/locales';

// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';

import Water from '../water';
import Payments from '../payments';
import Property from '../property';
import Inquiries from '../inquiries';
import Veterinary from '../veterinary';
//
import PersonalInformation from '../personal-information';
import { useAuthContext } from '../../../../../auth/hooks';
import { useAppContext } from '../../../../../contexts/AppContext';
import { useResponsive } from '../../../../../hooks/use-responsive';
import { FamilyCards } from '../../../../../components/family-cards';
import { ComponentTypeProps } from '../../../../../types/page-generator';
import { ICustomerItem } from '../../../../../types/customer-citypeople';
import { fetchOneCustomer, getCustomerByPhoneOrMail } from '../../../../../api/customers';
import { MyApartments } from '../../../../../components/my-apartments';
import { getAttributes } from '../../../../../api/attributes';
import { TabLoan } from '../../../../wallet/components/tab-loan';

// ----------------------------------------------------------------------

export type AccountTab = {
  value: string;
  label: string;
  icon: JSX.Element;
};

export const TABS: AccountTab[] = [
  {
    value: 'personal',
    label: 'Personal information',
    icon: <Iconify icon="prime:user" width={24} />,
  },
  {
    value: 'myApartments',
    label: 'My apartments',
    icon: <Iconify icon="ph:building-apartment-fill" width={24} />,
  },
  {
    value: 'loanTab',
    label: 'Loan',
    icon: <Iconify icon="solar:wallet-outline" width={24} />,
  },
  {
    value: 'payments',
    label: 'Payments',
    icon: <Iconify icon="system-uicons:credit-card" width={24} />,
  },
  {
    value: 'property',
    label: 'Property',
    icon: <Iconify icon="tdesign:home" width={20} />,
  },
  {
    value: 'water',
    label: 'Water',
    icon: <Iconify icon="ion:water-outline" width={20} />,
  },
  {
    value: 'veterinary',
    label: 'Veterinary',
    icon: <Iconify icon="la:dog" width={24} />,
  },
  {
    value: 'inquiries',
    label: 'Inquiries',
    icon: <Iconify icon="grommet-icons:contact" width={18} />,
  },
  {
    value: 'family',
    label: 'Family',
    icon: <Iconify icon="icon-park-solid:family" width={24} />,
  },
];

// ----------------------------------------------------------------------

export default function ResidentAccountView({ block, params}: ComponentTypeProps) {
  const {sectionType} = params.section;
  const { user } = useAuthContext();
  const { dispatch, state } = useAppContext();
  const [customer, setCustomer] = useState<ICustomerItem[] | any>([]);
  const isMobile = useResponsive('down', 'sm');
  const settings = useSettingsContext();
  const { t } = useTranslate();
  const [currentTab, setCurrentTab] = useState('personal');
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { logout } = useAuthContext();
  const { attributes } = state;

  useEffect(() => {
    getAttributes(dispatch);
  }, [user]);

  useEffect(() => {
    if (user) {
      getCustomerByPhoneOrMail(user.phone, user.email, dispatch).then((response: any) => {
        if (response) {
          setCustomer(response[0]);
        }
      });
    }
  }, []);

  const init = () => {
    if (user) {
      getCustomerByPhoneOrMail(user.phone, user.email, dispatch).then((response: any) => {
        if (response) {
          setLoading(true);
          fetchOneCustomer(response[0]?.id).then((result) => {
            if (result) {
              setLoading(false);
              setCustomer(result.payload);
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    init();
  }, [user]);

  useEffect(() => {
    if (!block?.personalTabItem) {
      setCurrentTab('payments');
      if (!block?.paymentsTabItem) {
        setCurrentTab('property');
        if (!block?.propertyTabItem) {
          setCurrentTab('water');
          if (!block?.waterTabItem) {
            setCurrentTab('veterinary');
            if (!block?.veterinaryTabItem) {
              setCurrentTab('inquiries');
              if (!block?.inquiriesTabItem) {
                setCurrentTab('personal');
              }
            }
          }
        }
      }
    }
  }, [block]);

  const personalInformationTabItem = Object.prototype.hasOwnProperty.call(block, 'personalTabItem')
    ? block?.personalTabItem
    : true;
  const paymentsTabItem = Object.prototype.hasOwnProperty.call(block, 'paymentsTabItem')
    ? block?.paymentsTabItem
    : true;
  const propertyTabItem = Object.prototype.hasOwnProperty.call(block, 'propertyTabItem')
    ? block?.propertyTabItem
    : true;
  const waterTabItem = Object.prototype.hasOwnProperty.call(block, 'waterTabItem')
    ? block?.waterTabItem
    : true;
  const veterinaryMedicineTabItem = Object.prototype.hasOwnProperty.call(block, 'veterinaryTabItem')
    ? block?.veterinaryTabItem
    : true;
  const inquiriesAndUpdatesTabItem = Object.prototype.hasOwnProperty.call(block, 'inquiriesTabItem')
    ? block?.inquiriesTabItem
    : true;
  const familyTabItem = Object.prototype.hasOwnProperty.call(block, 'familyTabItem')
    ? block?.familyTabItem
    : true;
  const myApartmentsTabItem = Object.prototype.hasOwnProperty.call(block, 'myApartmentsTabItem')
    ? block?.myApartmentsTabItem
    : true;
  const loanTabItem = Object.prototype.hasOwnProperty.call(block, 'loanTabItem')
    ? block?.loanTabItem
    : true;
  console.log('loanTabItem', loanTabItem);
  const activeTabNames: string[] = [];

  if (personalInformationTabItem) activeTabNames.push('personal');
  if (paymentsTabItem) activeTabNames.push('payments');
  if (propertyTabItem) activeTabNames.push('property');
  if (waterTabItem) activeTabNames.push('water');
  if (veterinaryMedicineTabItem) activeTabNames.push('veterinary');
  if (inquiriesAndUpdatesTabItem) activeTabNames.push('inquiries');
  if (familyTabItem) activeTabNames.push('family');
  if (myApartmentsTabItem) activeTabNames.push('myApartments');
  if (loanTabItem) activeTabNames.push('loanTab');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const logOut = () => {
    logout();
    router.push('/');
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'} sx={{px: 0}}>
      <Stack direction="row">
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            mb: { xs: 3, md: 5 },
            '& .MuiTabs-scrollButtons' : {
              width: '20px',
              height: '20px'
            }
          }}
        >
          {TABS.filter((tab: AccountTab) => activeTabNames.includes(tab.value)).map(
            (tab: AccountTab) => (
              <Tab key={tab.value} label={`${t(`${tab.label}`)}`} icon={tab.icon} value={tab.value} />
            ),
          )}
        </Tabs>
        {!isMobile && (
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={logOut}
            sx={{ mx: 2, mt: 1 }}
          >
            להתנתק
          </Button>
        )}
      </Stack>
      {isMobile && (
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={logOut}
          sx={{ mx: 2, my: 1 }}
        >
          להתנתק
        </Button>
      )}

      {currentTab === 'personal' && personalInformationTabItem && (
        <PersonalInformation block={block} />
      )}

      {currentTab === 'payments' && paymentsTabItem && <Payments block={block} />}

      {currentTab === 'property' && propertyTabItem && <Property block={block} />}

      {currentTab === 'water' && waterTabItem && <Water block={block} />}

      {currentTab === 'veterinary' && veterinaryMedicineTabItem && <Veterinary block={block} />}

      {currentTab === 'inquiries' && inquiriesAndUpdatesTabItem && <Inquiries block={block} />}

      {currentTab === 'family' && familyTabItem && (
        <FamilyCards block={block} customer={customer} loading={loading} refreshData={init} sectionType={sectionType}/>
      )}

      {currentTab === 'myApartments' && familyTabItem && (
        <MyApartments block={block} attributes={attributes}/>
      )}

      {currentTab === 'loanTab' && loanTabItem && <TabLoan />}

    </Container>
  );
}
