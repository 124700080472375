'use client'

import { FC, useState } from 'react';
import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  ResponsiveContainer,
} from 'recharts';

import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import { ComponentTypeProps } from '../../../../../types/page-generator';

// Фейковые данные для разных криптовалют и временных интервалов
const dataMock = {
  BTC: {
    '15m': [
      { time: '00:00', price: 2500 },
      { time: '00:15', price: 10000 },
      { time: '00:30', price: 4000 },
      { time: '00:45', price: 5000 },
    ],
    '1h': [
      { time: '01:00', price: 7000 },
      { time: '02:00', price: 9000 },
      { time: '03:00', price: 6000 },
      { time: '04:00', price: 8000 },
    ],
    '4h': [
      { time: '01:00', price: 6000 },
      { time: '02:00', price: 9000 },
      { time: '03:00', price: 3000 },
      { time: '04:00', price: 9000 },
    ],
    '1D': [
      { time: '01:00', price: 1000 },
      { time: '02:00', price: 4000 },
      { time: '03:00', price: 6000 },
      { time: '04:00', price: 8000 },
    ],
  },
  ETH: {
    '15m': [
      { time: '00:00', price: 1800 },
      { time: '00:15', price: 2500 },
      { time: '00:30', price: 2200 },
      { time: '00:45', price: 2700 },
    ],
    '1h': [
      { time: '01:00', price: 2300 },
      { time: '02:00', price: 2800 },
      { time: '03:00', price: 2600 },
      { time: '04:00', price: 2900 },
    ],
    '4h': [
      { time: '01:00', price: 6000 },
      { time: '02:00', price: 3000 },
      { time: '03:00', price: 3000 },
      { time: '04:00', price: 9000 },
    ],
    '1D': [
      { time: '01:00', price: 5000 },
      { time: '02:00', price: 4000 },
      { time: '03:00', price: 9000 },
      { time: '04:00', price: 8000 },
    ],
  },
  XRP: {
    '15m': [
      { time: '00:00', price: 1800 },
      { time: '00:15', price: 2500 },
      { time: '00:30', price: 2200 },
      { time: '00:45', price: 2700 },
    ],
    '1h': [
      { time: '01:00', price: 2300 },
      { time: '02:00', price: 2800 },
      { time: '03:00', price: 2600 },
      { time: '04:00', price: 2900 },
    ],
    '4h': [
      { time: '01:00', price: 5000 },
      { time: '02:00', price: 2000 },
      { time: '03:00', price: 3000 },
      { time: '04:00', price: 9000 },
    ],
    '1D': [
      { time: '01:00', price: 1000 },
      { time: '02:00', price: 9000 },
      { time: '03:00', price: 1000 },
      { time: '04:00', price: 8000 },
    ],
  },
  SOL: {
    '15m': [
      { time: '00:00', price: 1800 },
      { time: '00:15', price: 2500 },
      { time: '00:30', price: 2200 },
      { time: '00:45', price: 2700 },
    ],
    '1h': [
      { time: '01:00', price: 2300 },
      { time: '02:00', price: 2800 },
      { time: '03:00', price: 2600 },
      { time: '04:00', price: 2900 },
    ],
    '4h': [
      { time: '01:00', price: 3000 },
      { time: '02:00', price: 1000 },
      { time: '03:00', price: 4000 },
      { time: '04:00', price: 9000 },
    ],
    '1D': [
      { time: '01:00', price: 2000 },
      { time: '02:00', price: 4000 },
      { time: '03:00', price: 6000 },
      { time: '04:00', price: 8000 },
    ],
  },
  USDT: {
    '15m': [
      { time: '00:00', price: 1800 },
      { time: '00:15', price: 2500 },
      { time: '00:30', price: 2200 },
      { time: '00:45', price: 2700 },
    ],
    '1h': [
      { time: '01:00', price: 2300 },
      { time: '02:00', price: 2800 },
      { time: '03:00', price: 2600 },
      { time: '04:00', price: 2900 },
    ],
    '4h': [
      { time: '01:00', price: 1000 },
      { time: '02:00', price: 9000 },
      { time: '03:00', price: 3000 },
      { time: '04:00', price: 9000 },
    ],
    '1D': [
      { time: '01:00', price: 2000 },
      { time: '02:00', price: 9000 },
      { time: '03:00', price: 2000 },
      { time: '04:00', price: 8000 },
    ],
  },
};


const WalletTradeTrade: FC<ComponentTypeProps> = ({ block }) => {
  const [selectedCurrency, setSelectedCurrency] = useState('BTC');
  const [timeframe, setTimeframe] = useState('15m');

  const prices: any = {
    BTC: { value: '22,478.00', change: '+5.11%' },
    ETH: { value: '1,750.50', change: '+3.45%' },
    XRP: { value: '750.50', change: '+2.55%' },
    SOL: { value: '5,750.50', change: '+5.5%' },
    USDT: { value: '43.50', change: '+1.45%' },
  };

  const handleCurrencyChange = (currency: string) => {
    setSelectedCurrency(currency);
  };

  const handleTimeChange = (
    event: React.MouseEvent<HTMLElement>,
    newTimeframe: string,
  ) => {
    if (newTimeframe) setTimeframe(newTimeframe);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        borderRadius: '10px',
        // border: '1px solid',
        // borderColor: '#80808066',
      }}
    >
      {/* Информация о текущей валюте */}
      <Typography variant="h5" fontWeight="bold">
        {selectedCurrency}
      </Typography>
      <Typography variant="h4" fontWeight="bold">
        {prices[selectedCurrency].value}
      </Typography>
      <Typography color="green">{prices[selectedCurrency].change}</Typography>

      {/* Переключение временных интервалов */}
      <ToggleButtonGroup
        value={timeframe}
        exclusive
        onChange={handleTimeChange}
        aria-label="Timeframe"
        sx={{ mb: 2 }}
      >
        {['15m', '1h', '4h', '1D'].map((time) => (
          <ToggleButton key={time} value={time} sx={{ textTransform: 'none' }}>
            {time}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      {/* График */}
      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          // @ts-ignore
          data={dataMock[selectedCurrency][timeframe]}
        >
          <XAxis dataKey="time" stroke="#ccc" />
          <YAxis stroke="#ccc" />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="price"
            stroke="black"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default WalletTradeTrade;
