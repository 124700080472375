'use client';

import { FC, useState } from 'react';
import { useParams } from 'next/navigation';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Image from '../../components/image';
import { Ib2bProduct } from '../../types/b2b';
import { useRouter } from '../../routes/hooks';
import { BlockType } from '../../types/generator';
import { PriceTypeEnum } from '../../types/product';
import { BasicPageParams } from '../../types/common';
import { useResponsive } from '../../hooks/use-responsive';
import { uploadProductsImage } from '../../helpers/uploadProductsImage';

type Props = {
  block: BlockType | any;
  product: Ib2bProduct | any;
  params?: BasicPageParams | any;
};

export const AlternativeProductCarousel: FC<Props> = ({ block, product, params }) => {
  const localParams = useParams();
  const [matchingTags, setMatchingTags] = useState<any[]>([]);
  const router = useRouter();
  const isMobile = useResponsive('down', 'sm');

  // Получение изображения продукта
  // eslint-disable-next-line no-nested-ternary
  const imageLink = product?.mainImageURL
    ? uploadProductsImage(product.mainImageURL)
    : product?.merchants?.[0]?.mainImageURL
      ? uploadProductsImage(product.merchants[0].mainImageURL)
      : '';

  // Цена продукта
  const price = product?.prices?.find(
    (p: { type: PriceTypeEnum }) => p.type === PriceTypeEnum.PRICE
  )?.value;
  const random = Math.floor(Math.random() * (10 - 3 + 1)) + 3;

  // Размеры блока
  const heightVariant = isMobile ? '280px' : '95px';
  const height = block?.settings?.viewVariant ? heightVariant : '55px';
  const mobileHeight = isMobile ? '250px' : '75px';

  return (
    <>
      {block?.settings?.enableDouble ? (
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          sx={{
            position: 'relative',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          onClick={() => {
            router.push(`/${localParams.lng}/product/${product?.id}`);
          }}
        >
          <Stack
            sx={{
              background: '#0177EC',
              p: 1,
              borderRadius: '0 5px 5px 0',
            }}
          >
            <Typography sx={{ fontSize: '12px', textAlign: 'center', color: 'white' }}>
              {random}%
            </Typography>
            <Typography sx={{ color: 'white', fontSize: '10px' }}>הנחה</Typography>
          </Stack>
          <Image
            src={uploadProductsImage(imageLink)}
            sx={{
              width: '100px',
              height: '49px',
              objectFit: block?.settings?.viewVariant || 'cover',
              borderRadius: '5px 0 0 5px',
            }}
          />
        </Stack>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center', // Центрирование
            width: '100%',
            p: 0.5,
            cursor: 'pointer',
            textAlign: 'center', // Центрирование текста
          }}
          onClick={() => router.push(`/${localParams.lng}/product/${product?.id}`)}
        >
          <Stack
            width="100%"
            alignItems="center"
            justifyContent="center" // Центрирование изображения
            sx={{ position: 'relative' }}
          >
            <Box
              sx={{
                backgroundSize: block?.settings?.viewVariant || 'cover',
                backgroundImage: `url(${imageLink})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center', // Центрирование фона
                display: 'flex',
                alignItems: 'center', // Центрирование содержимого
                justifyContent: 'center',
                height: !block?.settings?.enablePriceOnTop ? height : mobileHeight,
                width: '100%',
                borderRadius: '4px',
                transform: block.settings.enableRotation ? 'rotate(-20deg)' : 'none',
                mt: block.settings.enableRotation ? '15px' : 0,
              }}
            />
            {block?.settings?.enablePriceOnTop && (
              <Box
                sx={{
                  direction: 'rtl',
                  position: 'absolute',
                  top: '-10px',
                  right: 5,
                  background: '#0155D4',
                  borderRadius: '10px',
                  px: 1,
                  py: 0.5,
                }}
              >
                <Typography sx={{ color: 'white', fontSize: '12px', fontWeight: 1000 }}>
                  שובר בשווי
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '12px', fontWeight: 1000 }}>
                  ₪{price}
                </Typography>
              </Box>
            )}
            <Stack
              alignItems="center"
              justifyContent="center" // Центрирование текста
              sx={{ mt: 0.25, textAlign: 'center', width: '100%' }}
            >
              {!block.settings.disableName && (
                <Typography sx={{ fontSize: isMobile ? '14px' : '0.625rem' }}>
                  {product?.title}
                </Typography>
              )}
              {block.settings.enablePrice && (
                <Typography sx={{ fontSize: isMobile ? '14px' : '0.625rem' }}>
                  שובר ₪{price}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  );
};
