'use client';

import React, { useState, SetStateAction } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import { Box, Button, Typography } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// eslint-disable-next-line import/no-cycle
import { LoanInfo } from './loan-info';
import Iconify from '../../../../components/iconify';
// eslint-disable-next-line import/no-cycle
import { LoanInfoPayment } from './loan-info-payment';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';
import Scrollbar from '../../../../components/scrollbar';
import { BlockType } from '../../../../types/page-generator';

export type Loan = {
  id: number;
  icon: string;
  name: string;
  period: string;
  percent: string;
  sum: string;
  date: string;
  status: string;
  balance: string;
};

type Props = {
  close?: () => void;
  block?: BlockType;
};

const loans = [
  {
    id: 1,
    icon: 'ph:student-light',
    name: 'Student Loan',
    period: '12 Months period',
    percent: '25%',
    sum: '170',
    date: 'Nov 12.2023',
    status: 'On Progress',
    balance: '1.000',
  },
  {
    id: 2,
    icon: 'ri:car-line',
    name: 'Credits Car',
    period: '60 Months period',
    percent: '75%',
    sum: '633',
    date: 'Nov 12.2023',
    status: 'Approved',
    balance: '30.000',
  },
  {
    id: 3,
    icon: 'meteor-icons:dollar',
    name: 'Apply for Credit Limit',
    period: '40 Months period',
    percent: '55%',
    sum: '333',
    date: 'Nov 12.2023',
    status: 'Approved',
    balance: '50.000',
  },
];

const ViewMore = ({ close, block }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const loanInfo = useBoolean();
  const loanInfoPatment = useBoolean();
  const [activeLoan, setActiveLoan] = useState<Loan>();
  const [type, setType] = useState('notification');

  const handleTypeChange = (event: any, newType: SetStateAction<string> | null) => {
    if (newType !== null) {
      setType(newType);
    }
  };

  const openLoanInfo = (loan: Loan) => {
    setActiveLoan(loan);
    if (type === 'notification') {
      loanInfo.onTrue();
    }
    if (type === 'payment') {
      loanInfoPatment.onTrue();
    }
  };

  const component = () => (
    <>
      {close && (
        <Stack direction="row" alignItems="center" gap={2}>
          <Box
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid',
              borderColor: '#7551DC',
            }}
          >
            <Iconify
              icon="solar:arrow-left-outline"
              sx={{ color: '#7551DC', cursor: 'pointer' }}
              onClick={() => {
                if (close) {
                  close();
                }
              }}
            />
          </Box>
          <Typography>All Loans</Typography>
        </Stack>
      )}
      {!close && (
        <Stack direction="row" justifyContent="center" my={1}>
          <Typography sx={{fontSize: '20px', fontWeight: 'bold'}}>My Loans</Typography>
        </Stack>
      )}
      <ToggleButtonGroup
        value={type}
        exclusive
        onChange={handleTypeChange}
        fullWidth
        sx={{
          '& .MuiToggleButton-root': {
            border: '1px solid #ccc',
            flex: 1,
            textTransform: 'none',
            borderRadius: '10px!important',
            color: '#9376E5',
          },
          '& .Mui-selected': {
            backgroundColor: 'lightgrey!important', // Цвет активной кнопки
            color: '#9376E5', // Цвет текста на активной кнопке
            border: '1px solid transparent!important', // Убираем границу
            '&:hover': {
              backgroundColor: 'lightgrey!important', // Цвет при наведении на активную кнопку
            },
          },
        }}
      >
        <ToggleButton value="notification">
          <Stack direction="row" gap={1}>
            <Typography>Notification</Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton value="payment">
          <Stack direction="row" gap={1}>
            <Typography>Payment</Typography>
          </Stack>
        </ToggleButton>
      </ToggleButtonGroup>
      {type === 'notification' && (
        <Stack gap={3} p={2}>
          {loans.map((loan, idx) => (
            <Grid key={idx} container spacing={1}>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={8} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <Box
                        sx={{
                          width: '50px',
                          height: '50px',
                          background: '#E8DDFD',
                          borderRadius: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Iconify
                          icon={loan.icon}
                          sx={{
                            width: '40px',
                            height: '40px',
                            color: '#7551DC',
                          }}
                        />
                      </Box>
                      <Stack>
                        <Typography sx={{ fontSize: '14px', fontWeight: 1000 }}>
                          {loan.name}
                        </Typography>
                        <Typography sx={{ fontSize: '12px', color: 'grey' }}>
                          {loan.date}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={4}
                    sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}
                  >
                    <Typography sx={{ color: loan.status === 'Approved' ? 'green' : '#7551DC' }}>
                      {loan.status}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ width: '100%', mt: 1 }} />
              <Stack
                mt={2}
                direction="row"
                alignItems="center"
                width={1}
                justifyContent="space-between"
              >
                <Button
                  sx={{
                    color: '#7551DC',
                    background: '#E8DDFD',
                    borderRadius: '10px',
                    height: '30px',
                  }}
                  onClick={() => openLoanInfo(loan)}
                >
                  Details
                </Button>
                <Typography sx={{ color: '#7551DC', fontSize: '20px' }}>${loan.sum}</Typography>
              </Stack>
            </Grid>
          ))}
        </Stack>
      )}
      {type === 'payment' && (
        <Scrollbar sx={{ height: '85%' }}>
          <Stack gap={3} p={2}>
            {loans.map((loan, idx) => (
              <LoanInfoPayment loan={loan} key={idx} />
            ))}
          </Stack>
        </Scrollbar>
      )}
      <Modal open={loanInfo.value} onClose={loanInfo.onFalse}>
        <LoanInfo close={loanInfo.onFalse} loan={activeLoan} />
      </Modal>
    </>
  );

  return (
    <>
      {close ? (
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '100%' : '50%',
            height: isMobile ? '100%' : '50%',
            bgcolor: 'background.paper',
            p: 2,
            borderRadius: isMobile ? '' : '10px',
          }}
        >
          {component()}
        </Box>
      ) : (
        component()
      )}
    </>
  );
};

export default ViewMore;
