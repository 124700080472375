'use client'

import dayjs from 'dayjs';
import { FC } from 'react';

import { Card, Stack, Typography, CardContent } from '@mui/material';

import history from '../../../../../constants/history';

interface CryptoWalletItemProps {
  type: 'Deposit' | 'Withdraw' | any;
  date: string | any;
  currencyLabel: string;
  amount: number;
  idx: number;
}

const convert = (value: number, currency: string): number => {
  switch (currency.toLowerCase()) {
    case 'eth':
      return value * 1579.61;
    case 'btc':
      return value * 21179.8;
    default:
      return value; // Например, для стейблкоинов
  }
};

const HistoryWalletItem: FC<CryptoWalletItemProps> = ({
  type,
  date,
  currencyLabel,
  amount,
  idx,
}) => {
  const usdValue = convert(amount, 'btc');
  return (
    <Card
      sx={{
        boxShadow: 'none',
        border: '1px solid #E0E0E0',
        borderTopRightRadius: idx === 0 ? '10px' : 0,
        borderTopLeftRadius: idx === 0 ? '10px' : 0,
        borderBottomLeftRadius: idx === history.length - 1 ? '10px' : 0,
        borderBottomRightRadius: idx === history.length - 1 ? '10px' : 0,
      }}
    >
      <CardContent sx={{ padding: '12px 16px' }}>
        {/* Дата */}
        <Typography variant="caption" color="gray">
          {dayjs(date).format('DD.MM.YYYY')}
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={1}
        >
          {/* Левая часть - Криптовалюта и статус */}
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack>
              <Typography variant="subtitle1" fontWeight={600}>
                {currencyLabel}
              </Typography>
              <Typography
                variant="body2"
                color={type === 'Deposit' ? 'green' : 'red'}
              >
                {type}
              </Typography>
            </Stack>
          </Stack>

          {/* Правая часть - Баланс и пересчитанная сумма */}
          <Stack alignItems="flex-end">
            <Typography variant="subtitle1" fontWeight={600}>
              {amount}
            </Typography>
            <Typography variant="body2" color="gray">
              {usdValue.toFixed(2)} USD
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default HistoryWalletItem;
