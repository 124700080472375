'use client'

import { FC } from 'react';

import { Card, Stack, Typography, CardContent } from '@mui/material';

import Image from '../../../../../components/image';
import { CRYPTO_CURRENCIES } from '../../../../../constants/currencies';

interface CryptoWalletItemProps {
  icon: string;
  name: string;
  symbol: string;
  balance: number;
  change: number;
  idx: number;
}

const CryptocurrencyWalletItem: FC<CryptoWalletItemProps> = ({
  icon,
  name,
  symbol,
  balance,
  change,
  idx,
}) => (
  <Card
    sx={{
      borderRadius: 0,
      borderTopRightRadius: idx === 0 ? '10px' : 0,
      borderTopLeftRadius: idx === 0 ? '10px' : 0,
      borderBottomLeftRadius: idx === CRYPTO_CURRENCIES.length - 1 ? '10px' : 0,
      borderBottomRightRadius:
        idx === CRYPTO_CURRENCIES.length - 1 ? '10px' : 0,
      boxShadow: 'none',
      border: '1px solid #E0E0E0',
      padding: '8px 16px',
    }}
  >
    <CardContent
      sx={{ padding: '8px', '&:last-child': { paddingBottom: '8px' } }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
      >
        {/* Иконка криптовалюты */}
        <Image
          src={icon}
          alt={name}
          sx={{
            width: 40,
            height: 40,
            backgroundColor: '#F5F5F5',
            '& img': { objectFit: 'contain' },
          }}
        />

        <Stack>
          <Typography variant="subtitle1" fontWeight={600}>
            {name}
          </Typography>
          <Typography variant="body2" color="gray">
            {symbol}
          </Typography>
        </Stack>
        <Stack flex={1} alignItems="center">
          <Image
            src={`/assets/crypto/graph/${name}.svg`}
            sx={{ width: '50px' }}
          />
        </Stack>
        <Stack alignItems="flex-end">
          <Typography variant="subtitle1" fontWeight={600}>
            {balance}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: change < 0 ? 'red' : 'green' }}
          >
            {change}
          </Typography>
        </Stack>
      </Stack>
    </CardContent>
  </Card>
);

export default CryptocurrencyWalletItem;
