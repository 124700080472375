'use client';

import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Grid, Avatar, Button, Typography, IconButton } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { GroupInfo } from './group-info.tsx';
import { useLocales } from '../../../../locales';
import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { BlockType } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';
import { CreateGroup } from './create-group';

type Props = {
  block?: BlockType;
};

const SavingsGroupCard = ({ block }: Props) => {
  // const groupInfo = block?.groupInfo;
  // const createGroup = block?.createGroup;
  const isMobile = useResponsive('down', 'sm');
  const groupInfo = useBoolean();
  const createGroup = useBoolean();
  const router = useRouter();
  const { currentLang } = useLocales();

  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: 2,
        boxShadow: 3,
        overflow: 'hidden',
        bgcolor: 'background.paper',
        fontFamily: 'Arial',
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 2,
          py: 1,
          background: 'linear-gradient(to right, #7F7FD5, #86A8E7, #91EAE4)', // Используем background вместо bgcolor
          color: 'white',
          borderRadius: '15px',
        }}
      >
        <Stack
          width={1}
          alignItems="center"
          sx={{ cursor: 'pointer', mx: 1 }}
          onClick={() => {
            createGroup.onTrue();
          }}
          //   if (createGroup) {
          //     router.push(`/${createGroup}`);
          //   }
          // }}
        >
          <Typography variant="button" sx={{ fontSize: '20px', fontWeight: 'bold' }}>
            Create
          </Typography>
          <Typography variant="button" sx={{ fontSize: '12px' }}>
            Group
          </Typography>
        </Stack>

        {/* Вертикальная черта */}
        <Box
          sx={{
            width: '1px',
            height: '44px', // Подбираем высоту
            backgroundColor: 'white',
            mx: 2, // Отступы слева и справа
          }}
        />

        <Stack width={1} alignItems="center" sx={{ cursor: 'pointer' }}>
          <Typography variant="button" sx={{ fontSize: '20px', fontWeight: 'bold' }}>
            Search
          </Typography>
          <Typography variant="button" sx={{ fontSize: '12px' }}>
            Group
          </Typography>
        </Stack>
      </Box>
      <Stack alignItems="center">
        <Divider sx={{ width: '95%', mt: 1 }} />
      </Stack>
      {/* Group Info */}
      <Box sx={{ px: 2, py: 1 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="subtitle1" fontWeight="bold" sx={{ color: '#003DE2' }}>
            Bali Savings Group
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
            <Typography variant="caption" sx={{ color: 'grey' }}>
              Active
            </Typography>
            <Box
              sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                bgcolor: 'green',
              }}
            />
          </Box>
        </Stack>
      </Box>

      {/* Avatars */}
      <Box
        sx={{
          px: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          mt: 1,
          justifyContent: 'space-between',
        }}
      >
        <Avatar src="/assets/images/managerAccount/profilePhoto.png" />
        <Avatar src="/assets/images/specialists/specialists2.jpg" />
        <Avatar src="/assets/images/specialists/specialists3.jpg" />
        <Avatar src="/assets/images/specialists/specialists1.jpg" />
        <Avatar src="/assets/images/specialists/specialists4.jpg" />
        <IconButton
          sx={{
            border: '1px solid lightgrey',
            borderRadius: '10px',
            background: 'lightgrey',
          }}
        >
          <Iconify icon="ic:sharp-plus-one" sx={{ color: '#7551DC' }} />
        </IconButton>
        <IconButton
          sx={{
            bgcolor: '#0881EB',
            color: 'white',
            borderRadius: '10px',
            '&:hover': {
              bgcolor: '#0881EB',
            },
          }}
          onClick={() => {
            groupInfo.onTrue();
          }}
          //   if (groupInfo) {
          //     router.push(`/${groupInfo}`);
          //   }
          // }}
        >
          <ArrowForwardIcon
            sx={{
              transform: currentLang.value === 'he' ? 'rotate(180deg)' : 'none',
            }}
          />
        </IconButton>
      </Box>

      <Stack alignItems="center">
        <Divider sx={{ width: '95%', mt: 1 }} />
      </Stack>

      {/* Payment Info */}
      <Grid container spacing={1} sx={{ px: 2, mt: 2 }}>
        <Grid item xs={4}>
          <Stack alignItems="center">
            <Typography variant="body2" fontWeight="bold">
              Nov 30
            </Typography>
            <Typography variant="caption" sx={{ color: '#007697' }}>
              Next Payment
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack alignItems="center">
            <Typography variant="body2" fontWeight="bold">
              $100
            </Typography>
            <Typography variant="caption" sx={{ color: '#007697' }}>
              Amount
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              fontSize: '12px',
              bgcolor: '#43A743',
              borderRadius: '10px',
              '&:hover': {
                bgcolor: '#43A743',
              },
            }}
          >
            Pay Now
          </Button>
        </Grid>
      </Grid>

      {/* Pay Now Button */}
      <Box sx={{ px: 2, mt: 2, mb: 2 }} />

      <Modal open={groupInfo.value} onClose={groupInfo.onFalse}>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '100%' : '50%',
            height: isMobile ? '100%' : '50%',
            bgcolor: 'background.paper',
            borderRadius: isMobile ? '' : '10px',
          }}
        >
          <GroupInfo block={block} close={groupInfo.onFalse} />
        </Box>
      </Modal>
      <Modal open={createGroup.value} onClose={createGroup.onFalse}>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '100%' : '50%',
            height: isMobile ? '100%' : '50%',
            bgcolor: 'background.paper',
            borderRadius: isMobile ? '' : '10px',
          }}
        >
        <CreateGroup close={createGroup.onFalse}/>
        </Box>
      </Modal>
    </Box>
  );
};

export default SavingsGroupCard;
