'use client';

import { FC, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';

import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Box, Avatar, IconButton } from '@mui/material';

import TransferModal from '../modal/transfer-modal';
import Image from '../../../../../components/image';
import { useLocales } from '../../../../../locales';
import Iconify from '../../../../../components/iconify';
import TransferSuccess from '../modal/transfer-success';
import { useBoolean } from '../../../../../hooks/use-boolean';
import TransactionHistory from '../modal/transaction-history';
import AddCardComponent from '../../saving-group/add-new-card';
import { useResponsive } from '../../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../../types/page-generator';
import {
  IVirtualCardsTableFilters,
} from '../../../../../types/club-cards';
import CardSliderWallet2 from '../../../../wallet/components/pages/cards-slider-wallet2';

const defaultFilters: IVirtualCardsTableFilters = {
  name: '',
  type: 'loan',
  role: [],
  start: null,
  end: null,
};

const STATUS_OPTIONS = [
  { value: 'loan', label: 'Loan request', icon: 'solar:wallet-linear' },
  { value: 'payment', label: 'Payment', icon: 'solar:card-linear' },
];

type Props = {
  id?: string;
};

export const Wallet2: FC<ComponentTypeProps> = ({ block }) => {
  const { currentLang } = useLocales();
  const isMobile = useResponsive('down', 'sm');
  const transferModal = useBoolean();
  const transferSuccess = useBoolean();
  const transactionHistory = useBoolean();
  const addNewCard = useBoolean();
  const [activeHuman, setHuman] = useState({ id: 0, avatar: '', name: '' });
  const [activeAmount, setActiveAmount] = useState(0);

  const people = [
    { id: 1, avatar: '/assets/images/managerAccount/profilePhoto.png', name: 'Bryan C.' },
    { id: 2, avatar: '/assets/images/specialists/specialists2.jpg', name: 'Jazmine C.' },
    { id: 3, avatar: '/assets/images/specialists/specialists3.jpg', name: 'Bernard H.' },
    { id: 4, avatar: '/assets/images/specialists/specialists1.jpg', name: 'Marcus B.' },
    { id: 5, avatar: '/assets/images/specialists/specialists4.jpg', name: 'Angel B.' },
  ];

  const transactions = [
    {
      id: 1,
      image: '/assets/crypto/lucian.png',
      name: 'Lucian Pennington',
      description: 'Money transfer',
      amount: '+ 136.00',
    },
    {
      id: 2,
      image: '/assets/crypto/electricity.png',
      name: 'Electricity',
      description: 'Utility bills',
      amount: '- 245.27',
    },
    {
      id: 3,
      image: '/assets/crypto/paypal.png',
      name: 'Paypal',
      description: 'Deposits',
      amount: '+ 500',
    },
  ];

  const transfer = (human: { id: number; avatar: string; name: string }) => {
    transferModal.onTrue();
    setHuman(human);
  };

  const transactionModal = (transaction: {
    id?: number;
    image?: string;
    name?: string;
    description?: string;
    amount: any;
  }) => {
    transactionHistory.onTrue();
    setActiveAmount(transaction.amount);
  };

  return (
    <Container maxWidth="sm" sx={{ px: 2 }}>
      <Box
        width={isMobile ? '100%' : '100%'}
        sx={{ borderRadius: '10px', display: 'flex', justifyContent: 'center' }}
      >
        <CardSliderWallet2 block={block} />
      </Box>
      {block?.settings?.enableQuickTransfer && <Typography>Quick money transfer to:</Typography>}
      {block?.settings?.enableQuickTransfer && (
        <Box
          sx={{
            px: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mt: 1,
            justifyContent: 'space-between',
          }}
        >
          {people.map((item, idx) => (
            <Stack key={idx} onClick={() => transfer(item)}>
              <Avatar src={item.avatar} />
              <Typography sx={{ fontSize: '10px' }}>{item.name}</Typography>
            </Stack>
          ))}
          <Stack>
            <IconButton
              sx={{
                border: '1px solid lightgrey',
                borderRadius: '10px',
                background: 'lightgrey',
              }}
            >
              <Iconify icon="ic:sharp-plus" />
            </IconButton>
            <Typography sx={{ fontSize: '10px' }}>Choose</Typography>
          </Stack>
        </Box>
      )}
      {block?.settings?.enableLatestTransaction && (
        <Stack direction="row" justifyContent="space-between" alignItems="center" my={2}>
          <Typography>Latest transactions</Typography>
          <IconButton>
            <Iconify icon="material-symbols-light:search-rounded" />
          </IconButton>
        </Stack>
      )}
      {block?.settings?.enableLatestTransaction && (
        <Stack gap={1}>
          {transactions.map((transaction) => (
            <Stack
              key={transaction.id}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ background: '#fff7f2' }}
              onClick={() => {
                transactionModal(transaction);
              }}
            >
              <Stack direction="row">
                <Image src={transaction.image} sx={{ width: '40px' }} />
                <Stack>
                  <Typography>{transaction.name}</Typography>
                  <Typography sx={{ fontSize: '10px', color: 'grey' }}>
                    {transaction.description}
                  </Typography>
                </Stack>
              </Stack>
              <Typography sx={{ fontSize: '12px' }}>{transaction.amount}</Typography>
            </Stack>
          ))}
        </Stack>
      )}
      <Modal open={addNewCard.value} onClose={addNewCard.onFalse}>
        <AddCardComponent close={addNewCard.onFalse} />
      </Modal>
      <Modal open={transferModal.value} onClose={transferModal.onFalse}>
        <TransferModal
          close={transferModal.onFalse}
          human={activeHuman}
          success={transferSuccess.onTrue}
        />
      </Modal>
      <Modal open={transferSuccess.value} onClose={transferSuccess.onFalse}>
        <TransferSuccess close={transferSuccess.onFalse} />
      </Modal>
      <Modal open={transactionHistory.value} onClose={transactionHistory.onFalse}>
        <TransactionHistory close={transactionHistory.onFalse} amount={activeAmount} />
      </Modal>
    </Container>
  );
};
