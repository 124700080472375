'use client';

import { FC, useState, useCallback } from 'react';
import 'react-circular-progressbar/dist/styles.css';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { useTranslate } from '../../../locales';
import Iconify from '../../../components/iconify';
import { LoanRequest } from './pages/loan-request';
import { useAuthContext } from '../../../auth/hooks';
import { useBoolean } from '../../../hooks/use-boolean';
import { useAppContext } from '../../../contexts/AppContext';
import { useResponsive } from '../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../types/page-generator';
import { Share } from '../../generate-page/components/saving-group/share';
import {
  IVirtualCardsTableFilters,
  IVirtualCardsTableFilterValue,
} from '../../../types/club-cards';
import AddCardComponent from '../../generate-page/components/saving-group/add-new-card';
import CardSlider from './pages/cards-slider';
import ViewMore from '../../generate-page/components/saving-group/view-more';

const defaultFilters: IVirtualCardsTableFilters = {
  name: '',
  type: 'loan',
  role: [],
  start: null,
  end: null,
};

const STATUS_OPTIONS = [
  { value: 'loan', label: 'Loan request', icon: 'solar:wallet-linear' },
  { value: 'payment', label: 'Payment', icon: 'solar:card-linear' },
];

type Props = {
  id?: string;
};

export const TabLoan: FC<ComponentTypeProps> = ({ block }) => {
  const isMobile = useResponsive('down', 'sm');
  const viewMore = useBoolean();
  const addNewCard = useBoolean();
  const loanModal = useBoolean();
  const paymentModal = useBoolean();
  const { user } = useAuthContext();
  const [filters, setFilters] = useState(defaultFilters);
  const { t } = useTranslate();

  const loans = [
    {
      id: 1,
      icon: 'ph:student-light',
      name: 'Student Loan',
      period: '12 Months period',
      percent: '25%',
      sum: '170',
      date: 'Nov 12.2023',
      status: 'On Progress',
      balance: '1.000'
    },
    {
      id: 2,
      icon: 'ri:car-line',
      name: 'Credits Car',
      period: '60 Months period',
      percent: '75%',
      sum: '633',
      date: 'Nov 12.2023',
      status: 'Approved',
      balance: '30.000'
    },
    {
      id: 3,
      icon: 'meteor-icons:dollar',
      name: 'Apply for Credit Limit',
      period: '40 Months period',
      percent: '55%',
      sum: '333',
      date: 'Nov 12.2023',
      status: 'Approved',
      balance: '50.000'
    },
  ];

  const {
    state: { virtualCards },
  } = useAppContext();

  const handleFilters = useCallback((name: string, value: IVirtualCardsTableFilterValue) => {
    if (value === 'loan') {
      loanModal.onTrue();
    }
    if (value === 'payment') {
      paymentModal.onTrue();
    }
  }, []);

  return (
    <Box>
      <Box
        width={isMobile ? '100%' : '100%'}
        sx={{ borderRadius: '10px', display: 'flex', justifyContent: 'center' }}
      >
        <CardSlider />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center', // Центрируем кнопки
          gap: 2, // Расстояние между кнопками
          px: 2.5,
          boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
        }}
      >
        {STATUS_OPTIONS.map((tab) => (
          <Button
            key={tab.value}
            onClick={() => handleFilters('type', tab.value)} // Меняем фильтр при клике
            startIcon={<Iconify icon={tab.icon} />} // Добавляем иконку перед текстом
            sx={{
              borderRadius: 0, // Убираем закругления
              px: 3, // Внутренние отступы
              py: 1.5,
              textTransform: 'none', // Убираем uppercase
            }}
          >
            {tab.label}
          </Button>
        ))}
      </Box>
      <Stack p={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography sx={{ fontWeight: 1000 }}>My loan</Typography>
          <Typography sx={{ fontSize: '10px', color: 'grey', cursor: 'pointer' }} onClick={viewMore.onTrue}>
            View more
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={3} p={2}>
        {loans.map((load, idx) => (
          <Grid key={idx} container spacing={1}>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={8} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Box
                      sx={{
                        width: '50px',
                        height: '50px',
                        background: '#E8DDFD',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Iconify
                        icon={load.icon}
                        sx={{
                          width: '40px',
                          height: '40px',
                          color: '#7551DC',
                        }}
                      />
                    </Box>
                    <Stack>
                      <Typography sx={{ fontSize: '14px', fontWeight: 1000 }}>
                        {load.name}
                      </Typography>
                      <Typography sx={{ fontSize: '12px', color: 'grey' }}>
                        {load.period}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={4}
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Box sx={{ position: 'relative', width: '60px', height: '60px' }}>
                    <svg width="60" height="60" viewBox="0 0 40 40">
                      {/* Фон круга */}
                      <circle cx="20" cy="20" r="15" fill="none" stroke="#d6d6d6" strokeWidth="3" />
                      {/* Заполненный круг */}
                      <circle
                        cx="20"
                        cy="20"
                        r="15"
                        fill="none"
                        stroke="#7551DC"
                        strokeWidth="3"
                        strokeDasharray="94.2" // Длина окружности
                        strokeDashoffset={`${94.2 - (parseFloat(load.percent) / 100) * 94.2}`}
                        strokeLinecap="round"
                        transform="rotate(90 20 20)" // Поворот на 90 градусов
                      />
                    </svg>
                    {/* Текст в центре */}
                    <Typography
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '10px',
                        // fontWeight: 'bold',
                      }}
                    >
                      {load.percent}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {isMobile && <Divider sx={{ width: '100%', mt: 1 }} />}
            <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid container>
                <Grid item xs={9} md={9} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Stack
                    gap={isMobile ? 0 : 1}
                    direction={isMobile ? 'column' : 'row'}
                    alignItems={isMobile ? '' : 'center'}
                  >
                    <Typography sx={{ fontWeight: 1000 }}>$ {load.sum}</Typography>
                    <Typography sx={{ fontSize: '12px', color: 'grey' }}>{load.date}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Button
                    sx={{
                      bgcolor: '#7551DC',
                      color: 'white',
                      borderRadius: '10px',
                      height: '25px',
                      width: '80px',
                      '&:hover': {
                        bgcolor: '#7551DC',
                        color: 'white',
                        borderRadius: '10px',
                      },
                    }}
                  >
                    Pay off
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Stack>
      <Modal open={loanModal.value} onClose={loanModal.onFalse}>
        <LoanRequest close={loanModal.onFalse} />
      </Modal>
      <Modal open={addNewCard.value} onClose={addNewCard.onFalse}>
        <AddCardComponent close={addNewCard.onFalse} />
      </Modal>
      <Modal open={viewMore.value} onClose={viewMore.onFalse}>
        <ViewMore close={viewMore.onFalse}/>
      </Modal>
    </Box>
  );
};
