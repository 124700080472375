'use client';

import { FC } from 'react';
import Box from '@mui/material/Box';
import { ComponentTypeProps } from '../../../../types/page-generator';

export const Iframe: FC<ComponentTypeProps> = ({ block }) => {
  const url: any = block?.url;

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',            // Ширина контейнера на 100% от родителя
        paddingTop: '56.25%',     // Соотношение сторон 16:9 (для видео или других пропорциональных элементов)
        margin: 'auto',
        overflow: 'hidden',
        borderRadius: '8px',      // Добавим скругление углов для красоты
      }}
    >
      <iframe
        src={url}
        title="Payment"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    </Box>
  );
};
