import React, { useState } from 'react';

import Stack from '@mui/material/Stack';
import { Box, Button, Avatar, TextField, Typography } from '@mui/material';

import Iconify from '../../../../../components/iconify';
import { useResponsive } from '../../../../../hooks/use-responsive';

type Props = {
  close: () => void;
  success: () => void;
  human: { id: number; avatar: string; name: string };
};

const TransferModal = ({ close, success, human }: Props) => {
  const isMobile = useResponsive('down', 'sm');

  const [selectedCard, setSelectedCard] = useState('7895');
  const [amount, setAmount] = useState('');
  const [comment, setComment] = useState('');

  const cards = [
    { id: '7895', type: 'Visa', balance: 4863.27 },
    { id: '5378', type: 'MasterCard', balance: 2435.12 },
  ];

  const handleSave = () => {
    success();
    close();
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: !isMobile ? 400 : '100%',
        bgcolor: 'background.paper',
        border: '2px solid gray.500',
        borderRadius: !isMobile ? '10px' : '0px',
        boxShadow: 24,
        p: 4,
        pt: 1,
        height: !isMobile ? 'auto' : '100%',
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // border: '1px solid',
            // borderColor: 'grey',
          }}
        >
          <Iconify icon="fluent:ios-arrow-24-regular" sx={{ cursor: 'pointer' }} onClick={close} />
        </Box>
        <Typography>Fund transfer</Typography>
      </Stack>

      <Typography sx={{ fontSize: '12px' }} gutterBottom mt={2}>
        Send money to
      </Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" gap={1}>
          <Avatar src={human.avatar} />
          <Typography sx={{ fontSize: '16px' }}>{human.name}</Typography>
        </Stack>
        <Typography sx={{ fontSize: '12px', color: 'grey' }}>**** 1258</Typography>
      </Stack>

      <Typography sx={{ fontSize: '12px' }} gutterBottom mt={2}>
        Use card
      </Typography>
      {cards.map((card) => (
        <Box
          key={card.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2,
            border: selectedCard === card.id ? '2px solid red' : '1px solid lightgray',
            borderRadius: '10px',
            cursor: 'pointer',
            mt: 1,
          }}
          onClick={() => setSelectedCard(card.id)}
        >
          <Typography>{card.type} **** {card.id}</Typography>
          <Typography>${card.balance.toFixed(2)}</Typography>
        </Box>
      ))}

      <TextField
        fullWidth
        placeholder="Amount"
        sx={{ mt: 2 }}
        InputProps={{ startAdornment: <Typography>$</Typography> }}
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <TextField
        fullWidth
        placeholder="Comment"
        multiline
        minRows={4}
        maxRows={4}
        sx={{ mt: 2 }}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <Typography sx={{ fontSize: '12px', color: 'gray', mt: 1 }}>Bank fee: 0.20 USD</Typography>

      <Button fullWidth sx={{ mt: 2, bgcolor: 'black', color: 'white', borderRadius: '10px' }} onClick={handleSave}>
        Send
      </Button>
    </Box>
  );
};

export default TransferModal;
