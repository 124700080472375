import { useState } from 'react';

import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  Box,
  MenuItem,
  Typography,
} from '@mui/material';

import { LoanSummary } from './loan-summary';
import Iconify from '../../../../components/iconify';
import { UploadBox } from '../../../../components/upload';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';

type Props = {
  close: () => void;
  loanRequestClose: () => void;
  loanCalculatorClose: () => void;
  recentCardsClose: () => void;
};

export const DocumentAttach = ({ close, loanRequestClose, loanCalculatorClose, recentCardsClose }: Props) => {
  const loanSummary = useBoolean();
  const isMobile = useResponsive('down', 'sm');
  const types = ['Identity Card'];
  const [value, setValue] = useState<string>('Identity Card');

  const formatNumber = (v: string) => {
    // Удаляем все символы, кроме чисел
    const numericValue = v.replace(/\D/g, '');
    // Форматируем число, добавляя разделители тысяч
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setValue(formattedValue);
  };

  const formatValue = (v: number) =>
    // Форматируем значение с разделителем тысяч
    v.toLocaleString();

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid',
            borderColor: '#7551DC',
          }}
        >
          <Iconify icon="solar:arrow-left-outline" sx={{ color: '#7551DC', cursor: 'pointer' }} onClick={close} />
        </Box>
        <Typography>Document Attach</Typography>
      </Stack>
      <Stack
        gap={3}
        p={2}
        mt={2}
        sx={
          isMobile
            ? {
                maxHeight: 'calc(100vh - 70px)', // Ограничение высоты (регулируйте по своему дизайну)
                overflowY: 'auto', // Включаем вертикальную прокрутку
              }
            : {}
        }
      >
        <Stack>
          <Typography sx={{ fontSize: '12px', color: 'grey', textAlign: 'justify' }}>
            Add the following documents so that we can easily verify yourself to avoid abuse
          </Typography>
        </Stack>
        <Stack>
          <Typography>Document Identity</Typography>
          <TextField
            select
            fullWidth
            value={value}
            onChange={handleChange}
            sx={{ border: '1px solid black', borderRadius: '10px' }}
          >
            {types.map((address, id) => (
              <MenuItem
                key={id}
                value={address}
                sx={{
                  mx: 1,
                  borderRadius: 0.75,
                }}
              >
                {address}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack>
          <Typography >Number Identity</Typography>
          <TextField fullWidth sx={{ border: '1px solid black', borderRadius: '10px' }} />
        </Stack>
        <Stack>
          <Typography>Upload</Typography>
          <UploadBox sx={{width: '100%', height: '100px'}}/>
        </Stack>
        <Button
          sx={{
            bgcolor: '#7551DC',
            color: 'white',
            borderRadius: '10px',
            '&:hover': {
              bgcolor: '#7551DC',
              color: 'white',
              borderRadius: '10px',
            },
          }}
          onClick={loanSummary.onTrue}
        >
          Next
        </Button>
      </Stack>
      <Modal open={loanSummary.value} onClose={loanSummary.onFalse}>
        <LoanSummary close={loanSummary.onFalse} loanRequestClose={loanRequestClose} loanCalculatorClose={loanCalculatorClose} recentCardsClose={recentCardsClose} documentAttachClose={close}/>
      </Modal>
    </Box>
  );
};
