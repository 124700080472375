import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { LoanCalculator } from './loan-calculator';
import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';
import Scrollbar from '../../../../components/scrollbar';

type Props = {
  close: () => void;
};

export const LoanRequest = ({ close }: Props) => {
  const loanCalculator = useBoolean();
  const isMobile = useResponsive('down', 'sm');
  const loans = [
    {
      icon: 'ph:student-light',
      name: 'Student Loan',
      period: 'Lowest interest as low as 0.5%',
      percent: '0.5%',
      sum: '$ 345 - $ 10.000',
    },
    {
      icon: 'ri:car-line',
      name: 'Credits Car',
      period: 'Lowest interest as low as 2.5%',
      percent: '2.5%',
      sum: '$ 1.000 - $ 25.000',
    },
    {
      icon: 'tabler:home-filled',
      name: 'Living House',
      period: 'Lowest interest as low as 4%',
      percent: '4%',
      sum: '$ 10.000 - $ 25.000',
    },
    {
      icon: 'tabler:home-filled',
      name: 'Business Loan',
      period: 'Lowest interest as low as 4%',
      percent: '4%',
      sum: '$ 10.000 - $ 25.000',
    },
    {
      icon: 'meteor-icons:dollar',
      name: 'Apply for Credit Limit',
      period: 'Lowest interest as low as 5%',
      percent: '5%',
      sum: '$ 25.000 - $ 55.000',
    },
    {
      icon: 'tabler:plus',
      name: 'Customize Loan',
      period: 'Lowest interest as low as 0.5%',
      percent: '0.5%',
      sum: '$ 345 - $ 1.000',
      date: 'Nov 12.2023',
    },
  ];
  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid',
            borderColor: '#7551DC',
          }}
        >
          <Iconify
            icon="solar:arrow-left-outline"
            sx={{ color: '#7551DC', cursor: 'pointer' }}
            onClick={close}
          />
        </Box>
        <Typography>Loan Request</Typography>
      </Stack>
      <Scrollbar sx={{height: '90%'}}>
        <Stack gap={3} p={2} mt={2}>
          {loans.map((load, idx) => (
            <Grid key={idx} container spacing={1}>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={10} md={10} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <Box
                        sx={{
                          width: '40px',
                          height: '40px',
                          background: '#E8DDFD',
                          borderRadius: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Iconify
                          icon={load.icon}
                          sx={{
                            width: '25px',
                            height: '25px',
                            color: '#7551DC',
                          }}
                        />
                      </Box>
                      <Stack>
                        <Typography sx={{ fontSize: '14px', fontWeight: 1000 }}>
                          {load.name}
                        </Typography>
                        <Typography sx={{ fontSize: '12px', color: 'grey' }}>
                          {load.period}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    md={2}
                    sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                  >
                    <Typography sx={{ fontSize: '12px', color: '#AE99EA' }}>
                      {load.percent}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {isMobile && <Divider sx={{ width: '100%', mt: 1 }} />}
              <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid container>
                  <Grid item xs={9} md={9} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Stack
                      gap={isMobile ? 0 : 1}
                      direction={isMobile ? 'column' : 'row'}
                      alignItems={isMobile ? '' : 'center'}
                    >
                      <Typography sx={{ fontWeight: 1000, color: '#7551DC' }}>
                        {load.sum}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Button
                      sx={{
                        bgcolor: '#7551DC',
                        color: 'white',
                        borderRadius: '10px',
                        height: '30px',
                        width: '80px',
                        '&:hover': {
                          bgcolor: '#7551DC',
                          color: 'white',
                          borderRadius: '10px',
                        },
                      }}
                      onClick={loanCalculator.onTrue}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Stack>
      </Scrollbar>
      <Modal open={loanCalculator.value} onClose={loanCalculator.onFalse}>
        <LoanCalculator close={loanCalculator.onFalse} loanRequestClose={close} />
      </Modal>
    </Box>
  );
};
