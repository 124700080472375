'use client'

import React, { FC, useState } from 'react';

import Stack from '@mui/material/Stack';
import {
  Box,
  Card,
  Grid,
  Button,
  Select,
  Divider,
  MenuItem,
  TextField,
  Container,
  Typography,
  CardContent,
} from '@mui/material';

import Image from '../../../../../components/image';
import { ComponentTypeProps } from '../../../../../types/page-generator';

const WalletExchange: FC<ComponentTypeProps> = ({ block }) => {
  const [fromCurrency, setFromCurrency] = useState('USD');
  const [toCurrency, setToCurrency] = useState('BTC');
  const [amount, setAmount] = useState<any>('');
  const [fee, setFee] = useState(8.16);
  const [received, setReceived] = useState<any>('');

  const handleExchange = () => {
    const feeAmount = (amount * fee) / 100;
    const receivedAmount = amount - feeAmount;
    setReceived(receivedAmount);
  };

  return (
    <Container maxWidth="sm">
      <Card sx={{ mx: 'auto' }}>
        <Stack width={1} direction="row" gap={1}>
          <Image
            sx={{ width: '20px' }}
            src="/assets/icons/wallet/icon-exchange-light.svg"
          />
          <Typography>Exchange</Typography>
        </Stack>
        <CardContent
          sx={{
            border: '1px solid',
            borderColor: '#80808066',
            borderRadius: '10px',
          }}
        >
          <Box sx={{ my: 2 }}>
            <Typography variant="body2" sx={{ color: 'grey' }}>
              FROM
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="I give"
                />
              </Grid>
              <Grid item xs={4}>
                <Select
                  fullWidth
                  value={fromCurrency}
                  onChange={(e) => setFromCurrency(e.target.value)}
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ my: 2 }}>
            <Typography variant="body2" sx={{ color: 'grey' }}>
              TO
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={received}
                  placeholder="I receive"
                />
              </Grid>
              <Grid item xs={4}>
                <Select
                  fullWidth
                  value={toCurrency}
                  onChange={(e) => setToCurrency(e.target.value)}
                >
                  <MenuItem value="BTC">BTC</MenuItem>
                  <MenuItem value="ETH">ETH</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>

          <Stack direction="row" justifyContent="space-between">
            <Typography sx={{ color: 'grey', fontSize: '12px' }}>
              Fee:
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>8.16%</Typography>
          </Stack>
          <Divider sx={{ width: '100%', color: 'grey' }} />
          <Stack direction="row" justifyContent="space-between">
            <Typography sx={{ color: 'grey', fontSize: '12px' }}>
              Amount received:
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography sx={{ fontSize: '12px' }}>0</Typography>
              <Typography sx={{ fontSize: '12px' }}>BTC</Typography>
            </Stack>
          </Stack>

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 2, bgcolor: 'black' }}
            onClick={handleExchange}
          >
            Exchange
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default WalletExchange;
