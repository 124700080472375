import { useState, useCallback } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import BackspaceIcon from '@mui/icons-material/Backspace';

import { SuccessPage } from './success-page';
import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';

type Props = {
  close: () => void;
  loanRequestClose: () => void;
  loanCalculatorClose: () => void;
  recentCardsClose: () => void;
  documentAttachClose: () => void;
  loanSummaryClose: () => void;
};

export const EnterPin = ({ close,loanRequestClose, loanCalculatorClose, recentCardsClose, documentAttachClose, loanSummaryClose }: Props) => {
  const successPage = useBoolean();
  const isMobile = useResponsive('down', 'sm');
  const [verificationCode, setCode] = useState<string>('');
  const [pin, setPin] = useState<string>(''); // Хранит введенный PIN

  const keyboardNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '', '0'];

  const handleNumberClick = (num: string) => {
    if (pin.length < 4) {
      setPin((prev) => prev + num);
    }
  };

  const handleBackspace = useCallback(() => {
    setPin((prev) => prev.slice(0, -1)); // Удаляем последний символ
  }, []);

  const handleComplete = () => {
    if (pin.length === 4) {
      setCode(pin); // Завершаем ввод
      successPage.onTrue();
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      <Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          <Box
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid',
              borderColor: '#7551DC',
            }}
          >
            <Iconify icon="solar:arrow-left-outline" sx={{ color: '#7551DC',cursor: 'pointer' }} onClick={close} />
          </Box>
        </Stack>
        <Stack
          gap={3}
          p={2}
          mt={2}
          sx={
            isMobile
              ? {
                maxHeight: 'calc(100vh - 70px)',
                overflowY: 'auto',
              }
              : {}
          }
        >
          <Stack>
            <Typography
              sx={{ fontSize: '32px', color: '#7551DC', textAlign: 'justify', fontWeight: 1000 }}
            >
              Enter your PIN
            </Typography>
          </Stack>

          {/* Отображение введенного PIN */}
          <Stack direction="row" gap={2} justifyContent="center">
            {[...Array(4)].map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: 50,
                  height: 50,
                  border: '1px solid #7551DC',
                  borderRadius: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '24px',
                  // color: '#7551DC',
                  backgroundColor: pin[index] ? '#7551DC' : 'transparent',
                  color: pin[index] ? 'white' : '#7551DC',
                }}
              >
                {pin[index] || ''}
              </Box>
            ))}
          </Stack>
        </Stack>


        {/* Кнопка для завершения ввода PIN */}
        <Stack gap={1} mt={3} alignItems="center">
          <Button
            sx={{
              width: 1,
              bgcolor: '#7551DC',
              color: 'white',
              borderRadius: '10px',
              '&:hover': {
                bgcolor: '#7551DC',
                color: 'white',
                borderRadius: '10px',
              },
            }}
            onClick={handleComplete}
            disabled={pin.length !== 4} // Активируем кнопку только при полном вводе
          >
            Verify
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: 1,
              color: '#7551DC',
              borderRadius: '10px',
              border: '1px solid',
              borderColor: '#7551DC',
              '&:hover': {
                color: '#7551DC',
                borderRadius: '10px',
                border: '1px solid',
                borderColor: '#7551DC',
              },
            }}
            onClick={close}
          >
            Forget Pin?
          </Button>
        </Stack>

        {/* Цифровая клавиатура */}
        <Grid container spacing={1} sx={{ justifyContent: 'center', mt: 2 }}>
          {keyboardNumbers.map((key, index) => (
            <Grid item xs={4} key={index}>
              {key ? (
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    height: 50,
                    fontSize: 20,
                    borderRadius: 2,
                    border: 'none',
                    fontWeight: 1000
                  }}
                  onClick={() => handleNumberClick(key)}
                >
                  {key}
                </Button>
              ) : (
                <Box sx={{ height: 50 }} />
              )}
            </Grid>
          ))}
          <Grid item xs={4}>
            <IconButton sx={{ width: '100%', height: 50 }} onClick={handleBackspace}>
              <BackspaceIcon />
            </IconButton>
          </Grid>
        </Grid>

      </Stack>
      <Modal open={successPage.value} onClose={successPage.onFalse}>
        <SuccessPage close={successPage.onFalse} loanRequestClose={loanRequestClose} loanCalculatorClose={loanCalculatorClose} recentCardsClose={recentCardsClose} documentAttachClose={documentAttachClose} loanSummaryClose={loanSummaryClose} enterPinClose={close}/>
      </Modal>
    </Box>
  );
};
