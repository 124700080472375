import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { Box, Stack, Typography } from '@mui/material';

import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';
import { AddCash } from '../../../generate-page/components/saving-group/add-cash';
import { CardInfo } from '../../../generate-page/components/saving-group/card-info';
import SavingsGroupCard from '../../../generate-page/components/saving-group/saving-group';
import { CardSettings } from '../../../generate-page/components/saving-group/card-settings';
import { ReceivePayment } from '../../../generate-page/components/wallet-crypto/modal/receive-payment';
import TransferModal from '../../../generate-page/components/wallet-crypto/modal/transfer-modal';
import TransferSuccess from '../../../generate-page/components/wallet-crypto/modal/transfer-success';
import Payments from '../../../generate-page/components/wallet-crypto/modal/payments';
import { BlockType } from '../../../../types/page-generator';

type Props = {
  card: any;
  edit: (card: any) => void;
  block: BlockType | undefined;
};

const ViewCardWallet2 = ({ card, edit, block }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const receivePayment = useBoolean();
  const transferModal = useBoolean();
  const transferSuccess = useBoolean();
  const payments = useBoolean();
  const openCardInfoModal = useBoolean();
  const isNewCard = !card?.amount && !card?.payOfDate; // Проверяем, является ли это карточка добавления

  const openCardInfo = (id: number) => {
    openCardInfoModal.onTrue();
  };

  return (
    <>
      <Box
        width={isMobile ? '95%' : '95%'}
        height="150px"
        onClick={(e) => {
          if (!isNewCard) {
            openCardInfo(card?.id);
          }
        }}
        sx={
          card?.images?.length
            ? {
                backgroundImage: `url(${card?.images[0]?.preview})` || '',
                borderRadius: '10px',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: isNewCard ? '20px' : '0', // Добавляем padding для новой карточки
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }
            : {
                bgcolor: '#7551DC',
                borderRadius: '10px',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: isNewCard ? '20px' : '0', // Добавляем padding для новой карточки
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }
        }
      >
        {isNewCard && (
          <Stack alignItems="center">
            <Iconify
              icon="simple-line-icons:plus"
              sx={{ color: 'white', width: '50px', height: '50px' }}
            />
          </Stack>
        )}
      </Box>
      {!isNewCard && (
        <Stack direction="row" p={2} pl={0} justifyContent="center" gap={1}>
          {block?.settings?.enableReceivePayment && (
            <Box
              sx={{ background: 'black', borderRadius: '5px', p: 1 }}
              onClick={receivePayment.onTrue}
            >
              <Stack direction="row" sx={{ color: 'white' }} gap={1}>
                <Iconify
                  icon="fluent:payment-16-regular"
                  sx={{ width: '25px', height: '25px' }}
                />
                <Typography sx={{ color: 'white', fontSize: '9px' }}>
                  Receive Payment
                </Typography>
              </Stack>
            </Box>
          )}
          {block?.settings?.enableMoneyTransfer && (
            <Box
              sx={{ background: 'black', borderRadius: '5px', p: 1 }}
              onClick={transferModal.onTrue}
            >
              <Stack direction="row" sx={{ color: 'white' }} gap={1}>
                <Iconify
                  icon="cil:transfer"
                  sx={{ width: '25px', height: '25px' }}
                />
                <Typography sx={{ color: 'white', fontSize: '9px' }}>
                  Money Transfer
                </Typography>
              </Stack>
            </Box>
          )}
          {block?.settings?.enableMakeAPayment && (
            <Box
              sx={{ background: 'black', borderRadius: '5px', p: 1 }}
              onClick={payments.onTrue}
            >
              <Stack direction="row" sx={{ color: 'white' }} gap={1}>
                <Iconify
                  icon="prime:dollar"
                  sx={{ width: '25px', height: '25px' }}
                />
                <Typography sx={{ color: 'white', fontSize: '9px' }}>
                  Make a Payment
                </Typography>
              </Stack>
            </Box>
          )}
        </Stack>
      )}
      <Modal open={openCardInfoModal.value} onClose={openCardInfoModal.onFalse}>
        <CardInfo close={openCardInfoModal.onFalse} card={card} />
      </Modal>
      <Modal open={receivePayment.value} onClose={receivePayment.onFalse}>
        <ReceivePayment close={receivePayment.onFalse} card={card} />
      </Modal>
      <Modal open={transferModal.value} onClose={transferModal.onFalse}>
        <TransferModal
          close={transferModal.onFalse}
          human={{
            id: 0,
            avatar: '/assets/images/managerAccount/profilePhoto.png',
            name: 'Bryan C.',
          }}
          success={transferSuccess.onTrue}
        />
      </Modal>
      <Modal open={transferSuccess.value} onClose={transferSuccess.onFalse}>
        <TransferSuccess close={transferSuccess.onFalse} />
      </Modal>
      <Modal open={payments.value} onClose={payments.onFalse}>
        <Payments close={payments.onFalse} block={block}/>
      </Modal>
    </>
  );
};

export default ViewCardWallet2;
