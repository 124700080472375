import { useState } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Box, Radio, RadioGroup, Typography, FormControl, FormControlLabel } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';

type Props = {
  close: () => void;
  loanRequestClose: () => void;
  loanCalculatorClose: () => void;
  recentCardsClose: () => void;
  documentAttachClose: () => void;
  loanSummaryClose: () => void;
  enterPinClose: () => void;
  successPageClose: () => void;
};

export const LoanProgress = ({
  close,
  loanRequestClose,
  loanCalculatorClose,
  recentCardsClose,
  documentAttachClose,
  loanSummaryClose,
  enterPinClose,
  successPageClose,
}: Props) => {
  const loanProgress = useBoolean();
  const isMobile = useResponsive('down', 'sm');
  const [selectedValue, setSelectedValue] = useState('submit');

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const backToHome = () => {
    close();
    loanRequestClose();
    loanCalculatorClose();
    recentCardsClose();
    documentAttachClose();
    loanSummaryClose();
    enterPinClose();
    successPageClose();
  };

  const steps = [
    {
      id: 'fieldset',
      icon: 'solar:dollar-outline',
      title: 'Submit loan order',
      subtitle: 'Your loan order has been submitted, please wait..',
    },
    {
      id: 'approved',
      icon: 'ant-design:file-done-outlined',
      title: 'Loan approved',
      subtitle: 'Your loan order has been approved and the loan will be distributed to you',
    },
    {
      id: 'transfering',
      icon: 'bx:transfer',
      title: 'Transfering',
      subtitle: 'Your funds are being transfered to your bank account, please check after sometime',
    },
  ];

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      {/* Заголовок с кнопкой закрытия */}
      <Stack gap={1}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Box
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid',
              borderColor: '#7551DC',
              bgcolor: 'background.paper',
            }}
            onClick={close}
          >
            <Iconify icon="solar:arrow-left-outline" sx={{ color: '#7551DC', cursor: 'pointer' }} />
          </Box>
          <Typography>Loan Progress</Typography>
        </Stack>

        {/* Радио-группы */}

        <FormControl component="fieldset" sx={{ p: 2 }}>
          <RadioGroup
            value={selectedValue}
            onChange={handleChangeRadio}
            name="loan-progress"
            sx={{ flexDirection: 'column' }}
          >
            <Stack gap={2}>
              {steps.map((step, idx) => (
                <Box key={idx}>
                  <FormControlLabel
                    value={step.id}
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: '#7551DC',
                          },
                        }}
                      />
                    }
                    label={
                      <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
                        <Grid container spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Grid item xs={2} md={2}>
                            <Box
                              sx={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid',
                                borderColor: 'lightgrey',
                                bgcolor: 'background.paper',
                              }}
                            >
                              <Iconify icon={step.icon} sx={{ color: '#7551DC' }} />
                            </Box>
                          </Grid>
                          <Grid item xs={10} md={10}>
                            <Stack>
                              <Typography sx={{ fontWeight: 1000 }}>{step.title}</Typography>
                              <Typography sx={{ fontSize: '12px', color: 'grey' }}>
                                {step.subtitle}
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Stack>
                    }
                    sx={{ margin: 0 }}
                  />
                  {idx + 1 !== steps.length && <Divider sx={{ width: '100%', color: '#7551DC' }} />}
                </Box>
              ))}

              {/* Кнопка возврата */}
              <Button
                sx={{
                  bgcolor: '#7551DC',
                  color: 'white',
                  borderRadius: '10px',
                  '&:hover': {
                    bgcolor: '#7551DC',
                  },
                }}
                onClick={backToHome}
              >
                Back Home
              </Button>
            </Stack>
          </RadioGroup>
        </FormControl>
      </Stack>
    </Box>
  );
};
