import React, { useState } from 'react';

import Stack from '@mui/material/Stack';
import { Box, Select, Button, MenuItem, Typography, FormControl } from '@mui/material';

import Iconify from '../../../../components/iconify';
import { useResponsive } from '../../../../hooks/use-responsive';

type Props = {
  close: () => void;
};

const AddCardComponent = ({ close }: Props) => {
  const isMobile = useResponsive('down', 'sm');

  const [cardType, setCardType] = useState('');
  const [merchant, setMerchant] = useState('');
  const [activity, setActivity] = useState('');

  const handleSave = () => {
    close();
  };

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid',
            borderColor: '#7551DC',
          }}
        >
          <Iconify
            icon="solar:arrow-left-outline"
            sx={{ color: '#7551DC', cursor: 'pointer' }}
            onClick={close}
          />
        </Box>
        <Typography>Add card</Typography>
      </Stack>

      {/* Заголовок */}
      <Typography variant="h6" gutterBottom mt={2}>
        Select the card you want to add
      </Typography>

      {/* Select Card Type */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Select
          displayEmpty
          value={cardType}
          onChange={(e) => setCardType(e.target.value)}
          inputProps={{ 'aria-label': 'Select Card Type' }}
        >
          <MenuItem value="" disabled>
            Select Card Type
          </MenuItem>
          <MenuItem value="Credit Card">Credit Card</MenuItem>
          <MenuItem value="Debit Card">Debit Card</MenuItem>
          <MenuItem value="Gift Card">Gift Card</MenuItem>
        </Select>
      </FormControl>

      {/* Select Merchant */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Select
          displayEmpty
          value={merchant}
          onChange={(e) => setMerchant(e.target.value)}
          inputProps={{ 'aria-label': 'Select Merchant' }}
          disabled={!cardType} // Заблокировано, если не выбран тип карты
        >
          <MenuItem value="" disabled>
            Select Merchant
          </MenuItem>
          <MenuItem value="Merchant 1">Merchant 1</MenuItem>
          <MenuItem value="Merchant 2">Merchant 2</MenuItem>
          <MenuItem value="Merchant 3">Merchant 3</MenuItem>
        </Select>
      </FormControl>

      {/* Select Activity */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Select
          displayEmpty
          value={activity}
          onChange={(e) => setActivity(e.target.value)}
          inputProps={{ 'aria-label': 'Select Activity' }}
          disabled={!merchant} // Заблокировано, если не выбран продавец
        >
          <MenuItem value="" disabled>
            Select Activity
          </MenuItem>
          <MenuItem value="Shopping">Shopping</MenuItem>
          <MenuItem value="Travel">Travel</MenuItem>
          <MenuItem value="Dining">Dining</MenuItem>
        </Select>
      </FormControl>

      {/* Кнопка Save */}
      <Button
        variant="contained"
        fullWidth
        onClick={handleSave}
        disabled={!activity} // Заблокировано, если не выбрано действие
        sx={{bgcolor: '#7551DC'}}
      >
        Save
      </Button>
    </Box>
  );
};

export default AddCardComponent;
