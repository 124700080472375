'use client';

import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';

import Box from '@mui/material/Box';

import { useAppContext } from 'src/contexts/AppContext';

import { ICardType, ITransaction, IVirtualCard } from 'src/types/club-cards';

import { RenderInfoPage } from './render-info-page';
import { BlockType } from '../../../types/page-generator';
import VirtualCardBlock from './wallet-virtual-card-block';
import { useResponsive } from '../../../hooks/use-responsive';
import { IGiftSelectionEvent } from '../../../types/coupons';
import { IOrder } from '../../../types/order';
import { useAuthContext } from '../../../auth/hooks';

type Props = {
  selected?: boolean;
  setOpenProduct: (card: IVirtualCard | null) => void;
  setOpenP2P: (card: IVirtualCard | null) => void;
  openProduct: IVirtualCard | null;
  block: BlockType | undefined;
  openHistory: Function;
  openModal: Function;
  onChangeClubCardInfo: Function;
};

enum ITransactionType {
  'CREDIT' = 'CREDIT',
  'DEBIT' = 'DEBIT',
  'REFUND' = 'REFUND',
  // TODO: удалить
}

const WalletCardsTab = ({
                          selected,
                          setOpenProduct,
                          setOpenP2P,
                          openProduct,
                          block,
                          openHistory,
                          openModal,
                          onChangeClubCardInfo,
                        }: Props) => {
  const { user } = useAuthContext();
  const isMobile = useResponsive('down', 'sm');
  const { state } = useAppContext();
  const swiperRef = useRef<SwiperCore | null>(null);
  const {
    state: { virtualCards },
  } = useAppContext();

  const changeOpenProduct = (card: IVirtualCard | null) => {
    setOpenProduct(card);
  };
  const changeOpenP2P = (card: IVirtualCard | null) => {
    setOpenP2P(card);
  };
  const temporaryCard: IVirtualCard[] = [
    {
      id: 'temporary',
      type: '',
      balance: '1000',
      cardTypeId: '',
      customerId: '',
      cardNumber: '1111-2222-3333-44444',
      cardPin: '1111',
      isGlobal: true,
      transactions: [
        {
          id: '1',
          amount: '100',
          virtualCardId: '1',
          orderId: '1',
          relatedActivityTransactionId: '',
          transactionType: ITransactionType.CREDIT,
          createdAt: '2024-08-17T12:17:24.596Z',
        },
        {
          id: '2',
          amount: '200',
          virtualCardId: '2',
          orderId: '2',
          relatedActivityTransactionId: '',
          transactionType: ITransactionType.CREDIT,
          createdAt: '2024-08-18T12:17:24.596Z',
        },
        {
          id: '3',
          amount: '400',
          virtualCardId: '3',
          orderId: '3',
          relatedActivityTransactionId: '',
          transactionType: ITransactionType.CREDIT,
          createdAt: '2024-08-19T12:17:24.596Z',
        },
        {
          id: '4',
          amount: '1000',
          virtualCardId: '4',
          orderId: '4',
          relatedActivityTransactionId: '',
          transactionType: ITransactionType.DEBIT,
          createdAt: '2024-08-20T12:17:24.596Z',
        },
      ],
      mainImageURL: '/assets/images/b24/banners/default.png',
      name: 'Happy',
      // @ts-ignore
      giftSelectionEvent: undefined,
    },
  ];

  const virtualCardsView = user ? virtualCards.concat(state.localVirtualCards) : virtualCards.concat(state.localVirtualCards).concat(temporaryCard);
  return !openProduct ? (
    <>
      {isMobile ? (
        <Box sx={{ '& .swiper-slide': { width: '85vw', height: 'auto', margin: 1 } }}>
          <Box
            sx={{
              width: isMobile ? '90vw' : '100vw',
            }}
          >
            <Swiper
              speed={500}
              slidesPerView="auto"
              loop
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              {virtualCardsView.concat(state.localVirtualCards).map((card: IVirtualCard) => (
                <SwiperSlide key={card.id}>
                  <VirtualCardBlock
                    key={card.id}
                    card={card}
                    changeOpenProduct={() => changeOpenProduct(card)}
                    changeOpenP2P={() => changeOpenP2P(card)}
                    block={block}
                    openHistory={openHistory}
                    openModal={openModal}
                    onChangeClubCardInfo={onChangeClubCardInfo}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Box>
      ) : (
        <>
          {!block?.settings?.enableSlider ? (
            <Box
              width={1}
              gap={1}
              display={selected ? 'block' : 'grid'}
              gridTemplateColumns={{
                sm: 'repeat(1, 1fr)',
                lg: 'repeat(2, 1fr)',
              }}
              sx={{
                pt: 2.5,
                justifyItems: 'center',
              }}
            >
              {virtualCardsView.map((card: IVirtualCard) => (
                <SwiperSlide key={card.id} style={selected ? { marginBottom: '8px' } : {}}>
                  <VirtualCardBlock
                    key={card.id}
                    card={card}
                    changeOpenProduct={() => changeOpenProduct(card)}
                    changeOpenP2P={() => changeOpenP2P(card)}
                    block={block}
                    openHistory={openHistory}
                    openModal={openModal}
                    onChangeClubCardInfo={onChangeClubCardInfo}
                  />
                </SwiperSlide>
              ))}
            </Box>
          ) : (
            <Box sx={{ '& .swiper-slide': { width: 'auto', height: 'auto', margin: 1 } }}>
              <Box
                sx={{
                  width: '70vw',
                }}
              >
                <Swiper
                  speed={500}
                  slidesPerView="auto"
                  loop
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                >
                  {virtualCardsView.concat(state.localVirtualCards).map((card: IVirtualCard) => (
                    <SwiperSlide key={card.id}>
                      <VirtualCardBlock
                        key={card.id}
                        card={card}
                        changeOpenProduct={() => changeOpenProduct(card)}
                        changeOpenP2P={() => changeOpenP2P(card)}
                        block={block}
                        openHistory={openHistory}
                        openModal={openModal}
                        onChangeClubCardInfo={onChangeClubCardInfo}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  ) : (
    <RenderInfoPage
      card={openProduct}
      returnToAllCards={() => changeOpenProduct(null)}
      block={block}
    />
  );
};

export default WalletCardsTab;
