'use client';

// mui
import { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { Modal } from '@mui/material';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { API } from 'src/helpers/api';
// constants
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';

// components
import Image from 'src/components/image';

// types
import { IVirtualCard, IVirtualCardProvider } from 'src/types/club-cards';

import { IGift } from '../../../types/gift';
import { BOT_ID } from '../../../config-global';
import { ICoupon } from '../../../types/coupons';
//
import ModalAddBalance from './modal-add-balance';
import { useAuthContext } from '../../../auth/hooks';
import { getVirtualCards } from '../../../api/clubCards';
import { BlockType } from '../../../types/page-generator';
import { useLocales, useTranslate } from '../../../locales';
import { getContentValueFromProps } from '../../generate-page/utils';
import SelectGift from '../../../components/select-gift/select-gift';
import { ActionTypes, useAppContext } from '../../../contexts/AppContext';

const styleReselectModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '80vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

type Props = {
  card: IVirtualCard;
  changeOpenProduct: () => void;
  changeOpenP2P?: () => void;
  isOpenProduct?: boolean;
  block: BlockType | undefined;
  openHistory: Function;
  openModal: Function;
  onChangeClubCardInfo: Function;
};

const VirtualCardBlock = ({
  card,
  changeOpenProduct,
  changeOpenP2P,
  isOpenProduct,
  block,
  openHistory,
  openModal,
  onChangeClubCardInfo,
}: Props) => {
  const { user } = useAuthContext();
  const { t } = useTranslate();
  const { state, dispatch } = useAppContext();
  const { trigger } = state;
  const activityId = state.smbActivityId;
  const getFieldValue = getContentValueFromProps(block);
  const isP2PActive = useMemo(() => getFieldValue('settings.isP2PActive') || false, [block]);

  const isMobile = useResponsive('down', 'sm');
  const { currentLang } = useLocales();
  const [balanceProvider, setBalanceProvider] = useState<number | string>('');
  const [startLoading, setStartLoading] = useState(false);
  const [gifts, setGifts] = useState<IGift | null>(null);
  const [coupons, setCoupons] = useState<ICoupon[]>([]);
  const primaryTitleTextColor = useMemo(
    () => getFieldValue('settings.primaryTitleTextColor'),
    [block]
  );
  const primaryTitleFontSize = useMemo(
    () => getFieldValue('settings.primaryTitleFontSize'),
    [block]
  );

  const primaryTitleFontWeight = useMemo(
    () => getFieldValue('settings.primaryTitleFontWeight'),
    [block]
  );

  const primaryTitleFontStyle = useMemo(
    () => getFieldValue('settings.primaryTitleFontStyle'),
    [block]
  );

  const primaryTitleTextDecoration = useMemo(
    () => getFieldValue('settings.primaryTitleTextDecoration'),
    [block]
  );

  const secondaryTitleTextColor = useMemo(
    () => getFieldValue('settings.secondaryTitleTextColor'),
    [block]
  );

  const secondaryTitleFontSize = useMemo(
    () => getFieldValue('settings.secondaryTitleFontSize'),
    [block]
  );

  const secondaryTitleFontWeight = useMemo(
    () => getFieldValue('settings.secondaryTitleFontWeight'),
    [block]
  );

  const secondaryTitleFontStyle = useMemo(
    () => getFieldValue('settings.secondaryTitleFontStyle'),
    [block]
  );

  const secondaryTitleTextDecoration = useMemo(
    () => getFieldValue('settings.secondaryTitleTextDecoration'),
    [block]
  );

  const enableName = useMemo(() => getFieldValue('settings.enableName') || true, [block]);

  const enableBalance = useMemo(() => getFieldValue('settings.enableBalance') || true, [block]);

  const enableValidityDate = useMemo(
    () => getFieldValue('settings.enableValidityDate') || true,
    [block]
  );
  // data for displaying or hiding the pin code
  const createdAt = new Date(card.giftSelectionEvent?.createdAt) || new Date().toISOString();
  const reselectionDayCount =
    (card && card.giftSelectionEvent && card.giftSelectionEvent.reselectionDayCount) || 0;
  const targetDate = new Date(createdAt);
  targetDate.setDate(targetDate.getDate() + reselectionDayCount);
  const today = new Date();
  const formattedDate = `${targetDate.getDate().toString().padStart(2, '0')}.${(
    targetDate.getMonth() + 1
  )
    .toString()
    .padStart(2, '0')}.${targetDate.getFullYear()}`;

  const handleCheckBalance = async () => {
    if (
      (card?.cardType?.provider === IVirtualCardProvider.MULTIPASS ||
        card?.cardType?.provider === IVirtualCardProvider.PRAXELL) &&
      card?.cardNumber
    ) {
      setStartLoading(true);
      const { data } = await API({
        url: `/provider/balance`,
        method: 'GET',
        params: {
          code:
            card?.cardType?.provider === IVirtualCardProvider.MULTIPASS
              ? `${card?.cardNumber}-${card?.cardPin ? card?.cardPin : ''}`
              : card?.cardNumber,
        },
      });

      if (data) {
        setBalanceProvider(data?.balance);
        setStartLoading(false);
      }
    }
  };

  const addBalance = useBoolean();
  const smUp = useResponsive('up', 'sm');
  const defaultImage =
    process.env.NEXT_PUBLIC_PROJECT_NAME === 'xtra'
      ? '/assets/images/xtra/default.png'
      : '/assets/images/happy-gift/banners/blue-card.png';

  const imageURL = card.cardType?.mainImageURL
    ? `${BASIC_URL_FOR_IMAGES}${card.cardType.mainImageURL}`
    : defaultImage;

  const isThirdPartyProvider =
    card?.cardType?.provider === IVirtualCardProvider.MULTIPASS ||
    card?.cardType?.provider === IVirtualCardProvider.PRAXELL;

  const enableRefillButton = block?.settings?.enableRefillButton
    ? block?.settings?.enableRefillButton
    : false;

  const init = async () => {
    if (activityId && user) {
      try {
        const { data } = await API({
          params: {
            botId: BOT_ID,
            smbActivityId: activityId,
          },
          url: `card`,
          method: 'GET',
        });
        if (data?.cards?.length) {
          setCoupons(data.cards);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onClickReselectCoupon = async (cardId: string, removeGiftProductId: string, i: any) => {
    try {
      const { data } = await API({
        url: `giftSelection/${cardId}`,
        method: 'GET',
      });
      if (data?.id) {
        const currentData: IGift = {
          ...data,
          items: data.items.filter((item: any) => item.productId !== removeGiftProductId),
        };
        setGifts(currentData);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onCloseReselectModal = () => {
    setGifts(null);
  };

  const onReselectedGift = async () => {
    init();
    if (activityId) {
      await getVirtualCards(activityId, dispatch);
    }
    dispatch({ type: ActionTypes.SET_TRIGGER, payload: !trigger });
    onCloseReselectModal();
  };

  return (
    <Paper elevation={3} sx={{ px: 2, py: 1, borderRadius: 1.5, width: '100%', height: '100%' }}>
      <Stack gap={3} sx={{ mt: 2, mb: 0, cursor: 'pointer' }}>
        <Stack direction={smUp ? 'row' : 'column'} gap={2}>
          <Box sx={{ position: 'relative', flex: 1, width: 300, height: 200 }}>
            {card?.id !== 'temporary' ? (
              <Image
                onClick={() => openHistory(card.id)}
                src={card.type === 'local' ? `${card?.cardType?.mainImageURL}` : imageURL}
                sx={{
                  borderRadius: '15px',
                  width: '300px',
                  height: '200px',
                  '& img': { objectFit: 'unset' },
                }}
              />
            ) : (
              <Image
                onClick={() => openHistory(card.id)}
                src="/assets/images/b24/banners/default.png"
                sx={{
                  borderRadius: '15px',
                  width: '300px',
                  height: '200px',
                  '& img': { objectFit: 'unset' },
                }}
              />
            )}
          </Box>

          <Stack gap={1} justifyContent="center">
            {card && card.giftSelectionEvent && card?.giftSelectionEvent?.reselection ? (
              <>
                {card &&
                card.giftSelectionEvent &&
                card?.giftSelectionEvent?.reselection &&
                today > targetDate ? (
                  <>
                    {enableName && (
                      <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        width={1}
                        sx={{ textWrap: 'balance' }}
                      >
                        <Typography
                          sx={{
                            color: secondaryTitleTextColor,
                            fontWeight: secondaryTitleFontWeight,
                            fontSize: secondaryTitleFontSize,
                            fontStyle: secondaryTitleFontStyle,
                            textDecoration: secondaryTitleTextDecoration,
                          }}
                        >
                          כרטיס מספר:
                        </Typography>
                        <Typography
                          sx={{
                            color: primaryTitleTextColor,
                            fontWeight: primaryTitleFontWeight,
                            fontSize: primaryTitleFontSize,
                            fontStyle: primaryTitleFontStyle,
                            textDecoration: primaryTitleTextDecoration,
                          }}
                        >
                          {' '}
                          {card.cardNumber}{' '}
                        </Typography>
                      </Stack>
                    )}
                  </>
                ) : (
                  <Typography sx={{ fontSize: secondaryTitleFontSize }}>
                    {`קוד הכרטיס יהיה זמין לאחר ${formattedDate}`}
                  </Typography>
                )}
              </>
            ) : (
              <>
                {enableName && (
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    width={1}
                    sx={{ textWrap: 'balance' }}
                  >
                    <Typography
                      sx={{
                        color: secondaryTitleTextColor,
                        fontWeight: secondaryTitleFontWeight,
                        fontSize: secondaryTitleFontSize,
                        fontStyle: secondaryTitleFontStyle,
                        textDecoration: secondaryTitleTextDecoration,
                      }}
                    >
                      כרטיס מספר:
                    </Typography>
                    <Typography
                      sx={{
                        color: primaryTitleTextColor,
                        fontWeight: primaryTitleFontWeight,
                        fontSize: primaryTitleFontSize,
                        fontStyle: primaryTitleFontStyle,
                        textDecoration: primaryTitleTextDecoration,
                      }}
                    >
                      {' '}
                      {card.cardNumber}{' '}
                    </Typography>
                  </Stack>
                )}
              </>
            )}
            {card && card.giftSelectionEvent && card?.giftSelectionEvent?.reselection ? (
              <>
                {card &&
                card.giftSelectionEvent &&
                card.giftSelectionEvent.reselection &&
                today > targetDate ? (
                  <>
                    {enableName &&
                      isThirdPartyProvider &&
                      card?.cardType?.provider === IVirtualCardProvider.MULTIPASS && (
                        <Stack
                          direction="row"
                          gap={1}
                          alignItems="center"
                          width={1}
                          sx={{ textWrap: 'balance' }}
                        >
                          <Typography
                            sx={{
                              color: secondaryTitleTextColor,
                              fontWeight: secondaryTitleFontWeight,
                              fontSize: secondaryTitleFontSize,
                              fontStyle: secondaryTitleFontStyle,
                              textDecoration: secondaryTitleTextDecoration,
                            }}
                          >
                            פין קוד:
                          </Typography>
                          <Typography
                            sx={{
                              color: primaryTitleTextColor,
                              fontWeight: primaryTitleFontWeight,
                              fontSize: primaryTitleFontSize,
                              fontStyle: primaryTitleFontStyle,
                              textDecoration: primaryTitleTextDecoration,
                            }}
                          >
                            {' '}
                            {card.cardPin}{' '}
                          </Typography>
                        </Stack>
                      )}
                  </>
                ) : (
                  <Typography
                    sx={{ fontSize: secondaryTitleFontSize }}
                  >{`קוד PIN יהיה זמין לאחר ${formattedDate}`}</Typography>
                )}
              </>
            ) : (
              <>
                {enableName &&
                  isThirdPartyProvider &&
                  card?.cardType?.provider === IVirtualCardProvider.MULTIPASS && (
                    <Stack
                      direction="row"
                      gap={1}
                      alignItems="center"
                      width={1}
                      sx={{ textWrap: 'balance' }}
                    >
                      <Typography
                        sx={{
                          color: secondaryTitleTextColor,
                          fontWeight: secondaryTitleFontWeight,
                          fontSize: secondaryTitleFontSize,
                          fontStyle: secondaryTitleFontStyle,
                          textDecoration: secondaryTitleTextDecoration,
                        }}
                      >
                        פין קוד:
                      </Typography>
                      <Typography
                        sx={{
                          color: primaryTitleTextColor,
                          fontWeight: primaryTitleFontWeight,
                          fontSize: primaryTitleFontSize,
                          fontStyle: primaryTitleFontStyle,
                          textDecoration: primaryTitleTextDecoration,
                        }}
                      >
                        {' '}
                        {card.cardPin}{' '}
                      </Typography>
                    </Stack>
                  )}
              </>
            )}
            {enableBalance && (
              <Stack direction="row" gap={1} alignItems="center" sx={{ textWrap: 'balance' }}>
                <Typography
                  sx={{
                    color: secondaryTitleTextColor,
                    fontWeight: secondaryTitleFontWeight,
                    fontSize: secondaryTitleFontSize,
                    fontStyle: secondaryTitleFontStyle,
                    textDecoration: secondaryTitleTextDecoration,
                  }}
                >
                  יתרת הכרטיס:
                </Typography>
                <Typography
                  sx={{
                    color: primaryTitleTextColor,
                    fontWeight: primaryTitleFontWeight,
                    fontSize: primaryTitleFontSize,
                    fontStyle: primaryTitleFontStyle,
                    textDecoration: primaryTitleTextDecoration,
                  }}
                >
                  {startLoading ? (
                    <Typography
                      sx={{
                        direction: currentLang.value === 'he' ? 'rtl' : 'ltr',
                        fontSize: primaryTitleFontSize,
                        animation: 'fade 1.5s infinite',
                        '@keyframes fade': {
                          '0%': { opacity: 1 },
                          '50%': { opacity: 0.3 },
                          '100%': { opacity: 1 },
                        },
                      }}
                    >
                      {currentLang.value === 'he' ? '...טוען' : 'Loading...'}
                    </Typography>
                  ) : (
                    <bdi>₪{isThirdPartyProvider ? balanceProvider : card.balance}</bdi>
                  )}
                </Typography>
              </Stack>
            )}
            {enableName && (
              <Stack direction="row" gap={1} alignItems="center" sx={{ textWrap: 'balance' }}>
                <Typography
                  sx={{
                    color: secondaryTitleTextColor,
                    fontWeight: secondaryTitleFontWeight,
                    fontSize: secondaryTitleFontSize,
                    fontStyle: secondaryTitleFontStyle,
                    textDecoration: secondaryTitleTextDecoration,
                  }}
                >
                  סוג כרטיס:
                </Typography>
                <Typography
                  sx={{
                    color: primaryTitleTextColor,
                    fontWeight: primaryTitleFontWeight,
                    fontSize: primaryTitleFontSize,
                    fontStyle: primaryTitleFontStyle,
                    textDecoration: primaryTitleTextDecoration,
                  }}
                >
                  {card.cardType?.name || '—'}
                </Typography>
              </Stack>
            )}
            {enableValidityDate && (
              <>
                {card?.cardType?.deletedDate && (
                  <Stack direction="row" gap={1} alignItems="center" sx={{ textWrap: 'balance' }}>
                    <Typography
                      sx={{
                        color: secondaryTitleTextColor,
                        fontWeight: secondaryTitleFontWeight,
                        fontSize: secondaryTitleFontSize,
                        fontStyle: secondaryTitleFontStyle,
                        textDecoration: secondaryTitleTextDecoration,
                      }}
                    >
                      תאריך תפוגה:
                    </Typography>
                    <Typography
                      sx={{
                        color: primaryTitleTextColor,
                        fontWeight: primaryTitleFontWeight,
                        fontSize: primaryTitleFontSize,
                        fontStyle: primaryTitleFontStyle,
                        textDecoration: primaryTitleTextDecoration,
                      }}
                    >
                      {card?.cardType?.deletedDate || '--'}
                    </Typography>
                  </Stack>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      {card.isGlobal ? (
        <Stack
          direction="row-reverse"
          justifyContent="flex-end"
          width={1}
          gap={isMobile ? 1 : 3}
          mt={isMobile ? 2 : 0}
          flexWrap={isMobile ? 'wrap' : 'nowrap'}
        >
          {card?.giftSelectionEvent?.reselection && (
            <Button
              variant="contained"
              color="secondary"
              sx={{ borderRadius: '20px' }}
              onClick={() =>
                onClickReselectCoupon(card?.giftSelectionEvent?.id, card.cardTypeId, card)
              }
            >
              {t('Reselect')}
            </Button>
          )}
          {isP2PActive && !isThirdPartyProvider && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                color: 'secondary.contrastText',
                padding: !smUp ? '6px 6px 6px 6px' : '',
                fontSize: !smUp ? '12px' : '',
              }}
              onClick={changeOpenP2P}
            >
              P2P
            </Button>
          )}
          {block?.settings?.enableMerchants && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                color: 'secondary.contrastText',
                // padding: !smUp ? '6px 6px 6px 6px' : '',
                // fontSize: !smUp ? '12px' : '',
              }}
              onClick={changeOpenProduct}
            >
              {isOpenProduct ? 'החזר' : 'איפה לממש'}
            </Button>
          )}
          {/* <Button */}
          {/*  variant="outlined" */}
          {/*  // color="secondary" */}
          {/*  sx={{ */}
          {/*    color: 'black', */}
          {/*    borderColor: 'secondary.main', */}
          {/*    padding: !smUp ? '6px 6px 6px 6px' : '', */}
          {/*    fontSize: !smUp ? '12px' : '', */}
          {/*  }} */}
          {/*  onClick={changeOpenProduct} */}
          {/* > */}
          {/*  ניהול */}
          {/* </Button> */}
          {/* <Button */}
          {/*  variant="outlined" */}
          {/*  // color="secondary" */}
          {/*  sx={{ */}
          {/*    color: 'black', */}
          {/*    borderColor: 'black', */}
          {/*    padding: !smUp ? '6px 6px 6px 6px' : '', */}
          {/*    fontSize: !smUp ? '12px' : '', */}
          {/*  }} */}
          {/*  onClick={() => openModal()} */}
          {/* > */}
          {/*  העבורות */}
          {/* </Button> */}
          {enableRefillButton && (
            <Button
              variant="contained"
              color="secondary"
              onClick={addBalance.onTrue}
              sx={{
                padding: !smUp ? '6px 6px 6px 6px' : '',
                fontSize: !smUp ? '12px' : '',
              }}
            >
              טעינת כרטיס
            </Button>
          )}
          {isThirdPartyProvider && block?.settings?.enableCheckBalance && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                color: 'white',
                bgcolor: 'black',
                // padding: !smUp ? '6px 6px 6px 6px' : '',
                // fontSize: !smUp ? '12px' : '',
                '&:hover': {
                  bgcolor: 'black',
                },
                '&:focus': {
                  bgcolor: 'black',
                },
                '&:active': {
                  bgcolor: 'black',
                },
              }}
              onClick={handleCheckBalance}
            >
              {/* {t('Check Balance')} */}
              בדיקת יתרה
            </Button>
          )}
        </Stack>
      ) : (
        <Stack
          direction="row-reverse"
          justifyContent="flex-end"
          width={1}
          gap={isMobile ? 1 : 3}
          mt={isMobile ? 2 : 0}
        >
          <Button
            variant="outlined"
            onClick={() => onChangeClubCardInfo()}
            sx={{
              color: 'black',
              borderColor: 'secondary.main',
              padding: !smUp ? '6px 6px 6px 6px' : '',
              fontSize: !smUp ? '12px' : '',
            }}
          >
            היסטוריה
          </Button>

          <Button
            variant="outlined"
            // color="secondary"
            sx={{
              color: 'black',
              borderColor: 'black',
              padding: !smUp ? '6px 6px 6px 6px' : '',
              fontSize: !smUp ? '12px' : '',
            }}
          >
            טעינת כרטיס
          </Button>

          <Button
            variant="contained"
            color="primary"
            sx={{
              color: 'white',
              bgcolor: 'black',
              padding: !smUp ? '6px 6px 6px 6px' : '',
              fontSize: !smUp ? '12px' : '',
            }}
          >
            יתרת כרטיס
          </Button>
        </Stack>
      )}
      <ModalAddBalance
        open={addBalance.value}
        handleClose={addBalance.onFalse}
        block={block}
        virtualCard={card}
      />
      <Modal open={Boolean(gifts?.id)} onClose={onCloseReselectModal}>
        <Box sx={styleReselectModal}>
          <Box
            sx={{
              maxHeight: '80vh',
              overflowY: 'auto',
            }}
          >
            <SelectGift
              gift={gifts as IGift}
              customerId={user?.id}
              refreshData={onReselectedGift}
              isReselect
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              sx={{ borderRadius: '20px' }}
              onClick={onCloseReselectModal}
            >
              {t('Close')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Paper>
  );
};
export default VirtualCardBlock;
