'use client';

import isEqual from 'lodash/isEqual';
import { useState, useEffect, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import { alpha } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';

import { API } from '../../../helpers/api';
import Label from '../../../components/label';
import { useTranslate } from '../../../locales';
import Iconify from '../../../components/iconify';
import { useAuthContext } from '../../../auth/hooks';
import { useBoolean } from '../../../hooks/use-boolean';
import { useAppContext } from '../../../contexts/AppContext';
import Scrollbar from '../../../components/scrollbar/scrollbar';
import TransactionHistoryTableRow from '../../generate-page/components/virtual-cards/transaction-history-table-row';
import {
  ITransaction,
  IVirtualCardsTableFilters,
  IVirtualCardsTableFilterValue,
} from '../../../types/club-cards';
import TransactionHistoryTableToolbar from '../../generate-page/components/virtual-cards/transaction-history-table-toolbar';
import {
  useTable,
  TableNoData,
  getComparator,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../../components/table';

const defaultFilters: IVirtualCardsTableFilters = {
  name: '',
  type: 'all',
  role: [],
  start: null,
  end: null,
};

const STATUS_OPTIONS = [
  { value: 'all', label: 'הכל' },
  { value: 'CREDIT', label: 'Credit' },
  { value: 'DEBIT', label: 'Debit' },
  // { value: 'banned', label: 'Banned' },
  // { value: 'rejected', label: 'Rejected' },
];

type Props = {
  id: string;
};

enum ITransactionType {
  'CREDIT' = 'CREDIT',
  'DEBIT' = 'DEBIT',
  'REFUND' = 'REFUND',
  // TODO: удалить
}

export const TabTransactionHistory = ({ id }: Props) => {
  const { user } = useAuthContext();
  const [filters, setFilters] = useState(defaultFilters);
  const table = useTable();
  const [tableData, setTableData] = useState<ITransaction[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [creditLength, setCreditLength] = useState<number>(0);
  const [debitLength, setDebitLength] = useState<number>(0);
  const confirm = useBoolean();
  const { t } = useTranslate();
  const TABLE_HEAD = [
    { id: 'amount', label: 'Amount' },
    { id: 'date', label: 'Date' },
    { id: 'type', label: 'Type' },
  ];
  const {
    state: { virtualCards },
  } = useAppContext();

  const onReset = () => {
    setFilters(defaultFilters);
  };

  // useEffect(() => {
  //   const currentCard = virtualCards.find((item: IVirtualCard) => item.id === id);
  //   if (currentCard) {
  //     setTableData(currentCard?.transactions);
  //   }
  // }, []);

  const init = async () => {
    if (user) {
      try {
        const { data } = await API({
          params: {
            skip: table.page * table.rowsPerPage,
            limit: table.rowsPerPage,
            dateFrom: filters.start,
            dateTo: filters.end,
            // sortBy: 'createdAt',
            // sortOrder: 'DESC',
            ...(filters.type !== 'all' && { transactionType: filters.type }),
          },
          url: `/virtualCard/transactions/${id}`,
          method: 'GET',
        });
        if (data) {
          setTableData(data.data);
          setTableCount(data.count);
        }
      } catch (e) {
        console.error(e);
      }
    }
    if (id === 'temporary') {
      setTableData([
        {
          id: '1',
          amount: '100',
          virtualCardId: '1',
          orderId: '1',
          relatedActivityTransactionId: '',
          transactionType: ITransactionType.CREDIT,
          createdAt: '2024-08-17T12:17:24.596Z',
        },
        {
          id: '2',
          amount: '200',
          virtualCardId: '2',
          orderId: '2',
          relatedActivityTransactionId: '',
          transactionType: ITransactionType.CREDIT,
          createdAt: '2024-08-18T12:17:24.596Z',
        },
        {
          id: '3',
          amount: '400',
          virtualCardId: '3',
          orderId: '3',
          relatedActivityTransactionId: '',
          transactionType: ITransactionType.CREDIT,
          createdAt: '2024-08-19T12:17:24.596Z',
        },
        {
          id: '4',
          amount: '1000',
          virtualCardId: '4',
          orderId: '4',
          relatedActivityTransactionId: '',
          transactionType: ITransactionType.DEBIT,
          createdAt: '2024-08-20T12:17:24.596Z',
        },
      ]);
      setTableCount(4)
    }
  };

  const initAllCredit = async () => {
    if (user) {
      try {
        const { data } = await API({
          url: `/virtualCard/transactions/${id}`,
          method: 'GET',
          params: {
            transactionType: 'CREDIT',
          },
        });
        setCreditLength(data.count);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const initAllDebit = async () => {
    if (user) {
      try {
        const { data } = await API({
          url: `/virtualCard/transactions/${id}`,
          method: 'GET',
          params: {
            transactionType: 'DEBIT',
          },
        });
        setDebitLength(data.count);
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    init();
  }, [filters, table.page, table.rowsPerPage]);

  useEffect(() => {
    initAllCredit();
    initAllDebit();
  }, []);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;
  const handleFilters = useCallback(
    (name: string, value: IVirtualCardsTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleFilterType = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('type', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);
  return (
    <Box>
      <Tabs
        value={filters.type}
        onChange={handleFilterType}
        sx={{
          px: 2.5,
          boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
        }}
      >
        {STATUS_OPTIONS.map((tab) => (
          <Tab
            key={tab.value}
            iconPosition="end"
            value={tab.value}
            label={tab.label}
            icon={
              <Label
                variant={
                  ((tab.value === 'all' || tab.value === filters.type) && 'filled') || 'soft'
                }
                color={
                  (tab.value === 'CREDIT' && 'success') ||
                  (tab.value === 'DEBIT' && 'error') ||
                  'default'
                }
              >
                {tab.value === 'all' && creditLength + debitLength}
                {tab.value === 'CREDIT' && creditLength}

                {tab.value === 'DEBIT' && debitLength}
              </Label>
            }
          />
        ))}
      </Tabs>

      <TransactionHistoryTableToolbar
        filters={filters}
        onFilters={handleFilters}
        canReset={canReset}
        onReset={onReset}
      />

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <TableSelectedAction
          dense={table.dense}
          numSelected={table.selected.length}
          rowCount={tableData.length}
          onSelectAllRows={(checked) =>
            table.onSelectAllRows(
              checked,
              tableData.map((row) => row.id)
            )
          }
          action={
            <Tooltip title={t('Delete')}>
              <IconButton color="primary" onClick={confirm.onTrue}>
                <Iconify icon="solar:trash-bin-trash-bold" />
              </IconButton>
            </Tooltip>
          }
        />

        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData.length}
              numSelected={table.selected.length}
              onSort={table.onSort}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
            />

            <TableBody>
              {dataFiltered
                // .slice(
                //   table.page * table.rowsPerPage,
                //   table.page * table.rowsPerPage + table.rowsPerPage
                // )
                .map((row) => (
                  <TransactionHistoryTableRow
                    key={row.id}
                    row={row}
                    selected={table.selected.includes(row.id)}
                    onSelectRow={() => table.onSelectRow(row.id)}
                    onDeleteRow={() => {}}
                    onEditRow={() => {}}
                  />
                ))}

              {/* <TableEmptyRows */}
              {/*  height={denseHeight} */}
              {/*  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)} */}
              {/* /> */}

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
      <TablePaginationCustom
        count={tableCount}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        //
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </Box>
  );
};

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: ITransaction[];
  comparator: (a: any, b: any) => number;
  filters: IVirtualCardsTableFilters;
}) {
  // const { type } = filters;

  // const stabilizedThis = inputData.map((el, index) => [el, index] as const);
  //
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  //
  // let filteredData = stabilizedThis.map((el) => el[0]);
  //
  // // Фильтрация по типу транзакции
  // if (type !== 'all') {
  //   filteredData = filteredData.filter((transaction) => transaction.transactionType === type);
  // }

  return inputData;
}
