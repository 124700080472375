import React, { useState } from 'react';

import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Box,
  Switch,
  Typography,
} from '@mui/material';

import Iconify from '../../../../../components/iconify';
import { useResponsive } from '../../../../../hooks/use-responsive';

type Props = {
  close: () => void;
};

const SettingsModal = ({ close }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const [state, setState] = useState({
    disabledZoom: false,
    disabledVideo: false,
    disabledTotal: false,
    disabledCaptions: false,
    disabledSlideshow: false,
    disabledThumbnails: false,
    disabledFullscreen: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: !isMobile ? 400 : '100%',
        bgcolor: 'background.paper',
        border: '2px solid gray.500',
        borderRadius: !isMobile ? '10px' : '0px',
        boxShadow: 24,
        p: 4,
        pt: 1,
        height: !isMobile ? 'auto' : '100%',
      }}
    >
      <Stack direction="row" alignItems="center" gap={2} mb={1}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // border: '1px solid',
            // borderColor: 'grey',
          }}
        >
          <Iconify icon="fluent:ios-arrow-24-regular" sx={{ cursor: 'pointer' }} onClick={close} />
        </Box>
        <Typography>Card settings</Typography>
      </Stack>
      <Stack gap={2}>
        <FormControlLabel
          control={
            <Switch
              size="small"
              name="disabledZoom"
              checked={state.disabledZoom}
              onChange={handleChange}
            />
          }
          label="View card number"
        />
        <FormControlLabel
          control={
            <Switch
              size="small"
              name="disabledVideo"
              checked={state.disabledVideo}
              onChange={handleChange}
            />
          }
          label="View CVV"
        />
      </Stack>
    </Box>
  );
};

export default SettingsModal;
