'use client'

// components
import { FC } from 'react';

import Stack from '@mui/material/Stack';
import { Container, Typography } from '@mui/material';

import Image from '../../../../../components/image';
import Scrollbar from '../../../../../components/scrollbar';
import CryptocurrencyWalletItem from './cryptocurrency-wallet-item';
import { CRYPTO_CURRENCIES } from '../../../../../constants/currencies';
import { ComponentTypeProps } from '../../../../../types/page-generator';

const WalletCryptocurrency: FC<ComponentTypeProps> = ({ block }) => (
    <Container maxWidth="sm">
      <Stack width={1} direction="row" gap={1}>
        <Image
          sx={{ width: '20px' }}
          src="/assets/icons/wallet/icon-hot-light.svg"
        />
        <Typography>Top Cryptocurrency</Typography>
      </Stack>
      <Scrollbar sx={{ height: '550px' }}>
        <Stack gap={0}>
          {CRYPTO_CURRENCIES.map((item, idx) => (
            <CryptocurrencyWalletItem
              key={item.value}
              icon={item.icon}
              name={item.name}
              symbol={item.label}
              balance={item.usd}
              change={item.change}
              idx={idx}
            />
          ))}
        </Stack>
      </Scrollbar>
    </Container>
  );
export default WalletCryptocurrency;
