
import React from 'react';

import Stack from '@mui/material/Stack';
import { Box, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Iconify from '../../../../components/iconify';
import { useResponsive } from '../../../../hooks/use-responsive';

type Props = {
  close: () => void;
};

export const AddCash = ({ close }: Props) => {
  const isMobile = useResponsive('down', 'sm');

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '100%' : '50%',
        height: isMobile ? '100%' : '50%',
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: isMobile ? '' : '10px',
      }}
    >
      {/* Заголовок */}
      <Stack direction="row" alignItems="center" gap={2}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid',
            borderColor: '#7551DC',
          }}
        >
          <Iconify
            icon="solar:arrow-left-outline"
            sx={{ color: '#7551DC', cursor: 'pointer' }}
            onClick={close}
          />
        </Box>
        <Typography>Add cash</Typography>
      </Stack>
      <Stack>
        <TextField placeholder="Enter the amount" />
      </Stack>

      <Button variant="contained" fullWidth onClick={close} sx={{ bgcolor: '#7551DC' }}>
        Add
      </Button>
    </Box>
  );
};
